import React, { useState, useEffect, useContext } from "react";
import styles from "./LoanPayForm.module.scss";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import { Row, Col } from "react-bootstrap";
import Slider from "react-rangeslider";
import { validateInput } from "../../utils/validateInput";
import { ToastContainer, toast } from "react-toastify";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import {
  convertInput,
  stripCommasInNumber,
} from "../../utils/convertInputType";
import "react-rangeslider/lib/index.css";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FaMoneyCheckAlt } from "react-icons/fa";


const LoanPayForm = ({ delegateApply }) => {
  const {
    state: { loading },
    // eslint-disable-next-line no-unused-vars
    loanApply,
  } = useContext(LoanContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { loanFunds },
    retrieveLoanFunds,
  } = useContext(LoanContext);

  const [daysOfMonth, setDaysOfMonth] = useState([]);
  const [limitError, setLimitError] = useState(null);
  const [loanCalcData, setLoanCalcData] = useState({
    monthlySalary: "",
    payDay: null
  });

  const [loanCalcDataErrors, setLoanCalcDataErrors] = useState({
    monthlySalary: null,
    payDay: null,
    loanAmount: null,
    installmentPeriod: null,
    loanPurpose: null,
  });
  const history = useHistory();
  useEffect(() => {
    var loanPayFormDiv = document.getElementById('loanPayForm');
    if (loanPayFormDiv) {
      checkAndGetLoanState();
    }

    function checkAndGetLoanState() {
      var y = new XMLHttpRequest();
      y.onload = function () {
        try {
          var a = JSON.parse(y.responseText);
          if (a.error) {
            console.log('load Error > :3', y.responseText);
            toast.error(a.message ? a.message : "Error");
          }
          console.log('usr', a.user.user_id, a);
          if (a.user.user_id) {
            getCurrentLoanState(a.user.user_id);
          } else {
            // toast.error(a.message ? a.message : "User read error");
          }
        } catch (er) {
          console.log('load Error > :2', y.responseText, er);
        }
      };
      y.onerror = function () {
        console.log('load Error > :1', y.responseText);
      };
      y.open('GET', globalVars.baseUrl + globalVars.usrUnbox);
      y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      y.send();

      function getCurrentLoanState(usrId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            document.getElementById('pre-loader').style.display = 'none';
            if (a.error) {
              console.log('Error > :3', y.responseText);
              toast.error(`Error! ${a.error}`);
              document.getElementById('error-resp-msg').innerHTML = a.error;
              return 0;
            }
            console.log('usr', a);
            var aa = a.data.user_info;
            a = a.data.current[0];
            document.getElementById('mpesa_phone').value = aa.mpesaPhone;
            document.getElementById('loan-amount').value = Math.ceil(a.monthlyRepayment);
            loanPayHandle(a._id);
            loanPayFormDiv.style.display = 'block';
          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getCurrentLoans.replace(':user_id', usrId));
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }

      function loanPayHandle(loan_id) {
        var frm = document.getElementById('loanPayForm');
        var respElm = document.getElementById('error-resp-msg');
        var mpesa_phone = document.getElementById('mpesa_phone');
        var loan_amount = document.getElementById('loan-amount');
        var loanFundBtn = document.getElementById('loan-fund-btn');
        var loadingOff = document.getElementById('loading-off');
        var loadingOn = document.getElementById('loading-on');

        loanFundBtn.addEventListener('click', function (ev) {
          ev.stopImmediatePropagation();
          ev.stopPropagation();
          var payLoadData = {
            loanId: loan_id,
            amount: loan_amount.value,
            mpesa_phone: mpesa_phone.value
          };
          if (!payLoadData.loanId || !payLoadData.amount || !payLoadData.mpesa_phone) {
            toast.error("Enter Amount and Mpesa phone to proceed");
            return 0;
          }
          loadingOff.style.display = 'none';
          loadingOn.style.display = 'initial';

          var formData = new FormData();
          formData.append('loanId', payLoadData.loanId);
          formData.append('amount', payLoadData.amount);
          formData.append('mpesa_phone', payLoadData.mpesa_phone);

          respElm.innerHTML = '';
          var y = new XMLHttpRequest();
          y.onload = function () {
            try {
              loadingOff.style.display = 'initial';
              loadingOn.style.display = 'none';
              var a = JSON.parse(y.responseText);
              if (a.error) {
                console.log('load Error > :3', y.responseText);
                toast.error(a.message ? a.message : (a.msg ? a.msg : "Error"));
                return 0;
              }

              loadingOff.style.display = 'initial';
              loadingOn.style.display = 'none';

              toast.success(a.msg ? a.msg : "Check you phone to complete payment");
              var dt = a.data;
              var msg = `<div style="text-align:left;"><p><br/>If you didn't receive the prompt and you are in <b>Kenya</b>, <br/>you can iniate the payment manually with the following steps.</p>
              <ol>
                <li>Open your M-Pesa Menu / M-Pesa app.</li>
                <li>Select Lipa Na M-pesa.</li> 
                <li>Select Paybill</li>
                <li>Enter Business/PayBill No. : ${dt.paybill}</li>
                <li>Account Number : ${dt.account_number}</li>
                <li>Enter Amount : ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KSH' }).format(dt.amount)}</li>
                <li>Enter your PIN then send..</li>
                <li>Confirm paymnet to THE SOMO AFRICA TRUST HO</li>
              </ol><p><b><i>NOTE*:</i></b> Reach out to finance to have your record updated after doing a manual payment</p></div>`;
              respElm.innerHTML = msg;
              respElm.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
            } catch (er) {
              console.log('load Error > :2', y.responseText, er);
              toast.error((a && a.message) ? a.message : "Response error");
            }
          };
          y.onerror = function () {
            console.log('load Error > :1', y.responseText);
            var a = JSON.parse(y.responseText);
            toast.error(a.message ? a.message : "Failed to initiate Mpesa payment");
          };
          y.open('POST', globalVars.baseUrl2 + globalVars.initMpesaPayment);
          // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
          // y.setRequestHeader("Content-Type", `Application/json`);
          y.send(formData);
        });


      }
    };
  });

  useEffect(() => {
    setDaysOfMonth(fillUpDaysArray);
    // retrieveLoanFunds();
    var _loan_fund = false, _run_on = false;
    // window.addEventListener('DOMContentLoaded', function (e) {/*console.log('>>2>DOM', e);*/ loanFundHandle(); });
    function getLoanFunds() {
      if (_loan_fund) {
        console.log('Exit potential Dry run>>', _loan_fund);
        return 0;
      }
      var z = new XMLHttpRequest();
      z.onload = function () {
        // console.log('load>', z.responseText);
        try {
          var a = JSON.parse(z.responseText);
          if (a.error || !a.status) {
            // 
            console.log('load Error > retry :3', z.responseText);
            getLoanFunds();
          }
          _loan_fund = a.data;
          // loanFundHandle();
        } catch (er) {
          console.log('load Error > retry :2', z.responseText, er);
          getLoanFunds();
        }
      };
      z.onerror = function () {
        console.log('load Error > retry :1', z.responseText);
        getLoanFunds();
      };
      // z.onreadystatechange = ()=>{console.log('ldn>', z.getAllResponseHeaders());}
      z.open('GET', globalVars.baseUrl + '/loan_fund/list');
      z.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      z.send();
    } getLoanFunds();
  }, []);

  // console.log('frm>1', document.getElementById('loanPayForm'));
  // window.addEventListener('DOMContentLoaded', function(e){console.log('>>1', e);});

  const fillUpDaysArray = () => {
    let daysArray = [];
    for (let i = 0; i < 31; i++) {
      daysArray.push(i + 1);
    }
    return daysArray;
  };

  const {
    monthlySalary,
    loanAmount,
    installmentPeriod,
    payDay,
    loanPurpose,
  } = loanCalcData;

  const submitLoanCalcData = () => {
    const fieldsTovalidate = {
      monthlySalary,
      payDay,
      loanAmount,
      installmentPeriod,
      loanPurpose,
    };
    const validated = validateInput(fieldsTovalidate, setLoanCalcDataErrors);
    if (validated) {
      const applyData = {
        monthlySalary: stripCommasInNumber(monthlySalary),
        payDay,
        amount: stripCommasInNumber(loanAmount),
        paymentPeriod: installmentPeriod,
        loanPurpose,
        monthlyRepayment: stripCommasInNumber(
          loanCalcData.estimatedMonthlyPayment
        ),
        DTI: loanCalcData.dti,
      };
      if (stripCommasInNumber(loanAmount) < process.env.REACT_APP_MIN_LOAN) {
        toast.error(
          "Your requested amount is too low. We only offer loans starting from Ksh" + process.env.REACT_APP_MIN_LOAN
        );
      } else if (loanPurpose !== "capital expenses") {
        toast.error(
          "Loans are only available for business growth"
        );
      } else {
        limitError ? toast.error(limitError) : delegateApply(applyData);
      }
    }
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      submitLoanCalcData();
    }
  };

  if (daysOfMonth.length === 0) {
    return null;
  }

  const fundSelectStyle = {
    display: "block",
    width: "100%",
    padding: "10px 7px",
    margin: "5px 0px",
    border: "1px solid #909090",
    borderRadius: "4px",
    background: "#f0f0f0",
    color: "#212121",
    fontSize: "1em"
  }
  const loanFundBtn = {
    width: "100%",
    backgroundColor: "rgb(204, 99, 40)",
    padding: "12px 28px",
    color: "rgb(235, 235, 235)",
    border: "none",
    borderRadius: "4px",
    font: "700 1.2em quicksand, sans-serif"
  };

  return (
    <div>
      <div className={styles.payForm} id="loanPayForm" style={{ display: "none" }}>
        <ToastContainer position="top-center" />
        <Row className="mb-4">
          <Col className="mb-4 mb-md-0" sm={12} md={12}>
            <FaMoneyCheckAlt size="4em" color="#cc6328" />
            <h2>Loan Repayment (M-Pesa)</h2>
          </Col>
          <Col sm={12} md={6}>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className={styles.infoDiv}>
              <label className={styles.infoLbl}>Amount in (Ksh)</label>
              <input id="loan-amount" name="amount" className={styles.infoInpAmnt} type="number" placeholder="0.00" />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <div className={styles.infoDiv}>
              <label className={styles.infoLbl}>M-Pesa Phone number</label>
              <input id="mpesa_phone" name="mpesa_phone" className={styles.infoInp} type="tel" placeholder="2547XXXXXX" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col></Col>
          <Col sm={12} md={6}>
            <button class="mt-5" id="loan-fund-btn" style={loanFundBtn}>
              <span id="loading-off">Pay</span>
              <span id="loading-on" style={{ display: "none" }}><Loader /></span>
            </button>
          </Col>
          <Col></Col>
        </Row>

      </div>
      <div id="pre-loader" className={styles.pre_ld}> <Loader /> </div>
      <div id="error-resp-msg"></div>
    </div>
  );
};

export default LoanPayForm;
