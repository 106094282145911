import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ScoreCompBarGraph = ({ labels, barData1, barData2 }) => {
    const backgroundColors = barData2.map((value) =>
        value >= 0 ? "#00A5A2" : "#C4233C"
    );

    const borderColors = barData2.map((value) =>
        value >= 0 ? "#00A5A2" : "#C4233C"
    );

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Loan Amount',
                data: barData1,
                backgroundColor: '#212153',
                borderColor: '#212153',
                borderWidth: 1
            },
            {
                label: 'Profit',
                data: barData2,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Amount (KSH)'
                },
            },
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Month'
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        let customLabels = [];

                        datasets.forEach((dataset, i) => {
                            if (dataset.label === "Profit") {
                                customLabels.push({
                                    text: "Profit (Positive)",
                                    fillStyle: "#00A5A2",
                                    hidden: !chart.isDatasetVisible(i),
                                    strokeStyle: dataset.borderColor,
                                    datasetIndex: i,
                                });

                                customLabels.push({
                                    text: "Profit (Negative)",
                                    fillStyle: "#C4233C",
                                    hidden: !chart.isDatasetVisible(i),
                                    strokeStyle: dataset.borderColor,
                                    datasetIndex: i,
                                });
                            } else {
                                customLabels.push({
                                    text: dataset.label,
                                    fillStyle: dataset.backgroundColor,
                                    hidden: !chart.isDatasetVisible(i),
                                    strokeStyle: dataset.borderColor,
                                    datasetIndex: i,
                                });
                            }
                        });
                        return customLabels;
                    },
                },
            },
        },
    };

    return (
        <div style={{ height: "400px", minHeight: "300px" }}>
            <Bar height="100%" data={data} options={options} />
        </div>
    );
};

export default ScoreCompBarGraph;
