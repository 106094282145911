import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ScoreBarGraph = ({ metricData, labels, barData, label }) => {

    const backgroundColors = barData.map((value) =>
        value >= 0 ? "#00A5A2" : "#C4233C"
    );

    const borderColors = barData.map((value) =>
        value >= 0 ? "#00A5A2" : "#C4233C"
    );

    const data = {
        labels: labels,
        datasets: [
            {
                label: metricData.yLabel ? metricData.yLabel : "Score",
                data: barData,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: metricData.yLabel ? metricData.yLabel : ""
                },
            },
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: metricData.xLabel ? metricData.xLabel : ""
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        let customLabels = [];

                        datasets.forEach((dataset, i) => {
                            customLabels.push({
                                text: `${dataset.label} (Positive)`,
                                fillStyle: "#00A5A2",
                                hidden: !chart.isDatasetVisible(i),
                                strokeStyle: dataset.borderColor,
                                datasetIndex: i,
                            });

                            customLabels.push({
                                text: `${dataset.label} (Negative)`,
                                fillStyle: "#C4233C",
                                hidden: !chart.isDatasetVisible(i),
                                strokeStyle: dataset.borderColor,
                                datasetIndex: i,
                            });
                        });
                        return customLabels;
                    },
                },
            },
            datalabels: {
                display: false,
            },
        },
    };

    return (
        <div style={{ height: "400px", minHeight: "300px" }}>
            <Bar height="100%" data={data} options={options} />
        </div>
    );
};

export default ScoreBarGraph;
