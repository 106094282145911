import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ScoreHalfDoughnut = ({ metric, screenWidth }) => {
    let segmentValues = [];
    const segments = metric.segmentStops.length - 1;

    if (segments === 5) {
        segmentValues = [1, 1, 1, 1, 1];
    } else if (segments === 4) {
        segmentValues = [1, 1, 1, 1];
    } else if (segments === 3) {
        segmentValues = [1, 1, 1];
    } else if (segments === 2) {
        segmentValues = [1, 1];
    }


    const getGradient = (chart, segments) => {
        const { ctx, chartArea: { top, bottom, left, right } } = chart;
        const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
        if (segments === 5) {
            gradientSegment.addColorStop(0, '#C4233C');
            gradientSegment.addColorStop(0.25, '#dc7484');
            gradientSegment.addColorStop(0.5, '#CC6328');
            gradientSegment.addColorStop(0.75, '#FFC83B');
            gradientSegment.addColorStop(1, '#00A5A2');
        } else if (segments === 4) {
            gradientSegment.addColorStop(0, '#C4233C');
            gradientSegment.addColorStop(0.25, '#CC6328');
            gradientSegment.addColorStop(0.75, '#FFC83B');
            gradientSegment.addColorStop(1, '#00A5A2');
        } else if (segments === 3) {
            gradientSegment.addColorStop(0, '#C4233C');
            gradientSegment.addColorStop(0.33, '#FFC83B');
            gradientSegment.addColorStop(1, '#00A5A2');
        } else if (segments === 2) {
            gradientSegment.addColorStop(0, '#C4233C');
            gradientSegment.addColorStop(1, '#00A5A2');
        }

        return gradientSegment;
    };

    const data = {
        labels: ['Bad', 'Average', 'Good', 'Excellent'],
        datasets: [{
            label: '',
            data: segmentValues,
            backgroundColor: (context) => {
                const chart = context.chart;
                const { chartArea } = chart;
                if (!chartArea) return null;
                return getGradient(chart, segments);
            },
            circumference: 180,
            rotation: 270,
            hoverBorderColor: "#212153",
            needleValue: metric.value,
            borderWidth: 0,
        }]
    };


    const gaugeNeedle = {
        id: "gaugeNeedle",
        afterDatasetsDraw(chart) {
            const { ctx } = chart;
            ctx.save();

            const xCenter = chart.getDatasetMeta(0).data[0].x;
            const yCenter = chart.getDatasetMeta(0).data[0].y;
            const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
            const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
            const radius = 15;
            const needleValue = data.datasets[0].needleValue;
            const angle = Math.PI / 180;

            const circumference = ((chart.getDatasetMeta(0).data[0].circumference / Math.PI) / data.datasets[0].data[0]) * needleValue;

            ctx.translate(xCenter, yCenter);
            ctx.rotate(Math.PI * (circumference + 1.5));

            // Needle
            ctx.beginPath();
            ctx.strokeStyle = '#212153';
            ctx.fillStyle = '#FFC83B';
            ctx.lineWidth = 3;
            ctx.moveTo(0 - radius, 0);
            ctx.lineTo(0, 0 - innerRadius);
            ctx.lineTo(0 + radius, 0);
            ctx.stroke();
            ctx.fill();

            // Dot in the center
            ctx.beginPath();
            ctx.arc(0, 0, radius, 0, angle * 360, false);
            ctx.fillStyle = '#212153';
            ctx.fill();
            ctx.stroke();

            ctx.restore();
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 20,
                bottom: 20,
                left: 20,
                right: 20
            }
        },
        cutout: '70%',
        circumference: 180,
        rotation: -90,
        plugins: {
            datalabels: {
                color: '#212153',
                font: {
                    size: 16,
                },
                formatter: (value, ctx) => {
                    const index = ctx.dataIndex;
                    return `${metric.segmentStops[index]}`;
                },
                anchor: 'end',
                align: 'start',
                offset: -30,
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            }
        }
    };


    return (
        <div style={{ width: "100%" }}>
            <Doughnut data={data} options={options} plugins={[gaugeNeedle]} width={screenWidth < 768 ? "auto" : "400px"} height={screenWidth < 768 ? "auto" : "400px"} />
        </div>
    );
};

export default ScoreHalfDoughnut;

