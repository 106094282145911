import React from 'react';
import styles from './ScoresTable.module.scss';

const ScoresTable = ({ data }) => {

    const financialResults = data.financialResults;
    const impactData = data.impactData;
    const participation = data.participationInAcceleration;
    const loanRepayment = data.loanRepayment;

    return (
        <div className={styles.cardTable}>
            <div className={styles.headingBox}>
                <h4>Financial Results via DigiKua</h4>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.table}>
                    <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th>Measure</th>
                                <th>Score</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Month to month revenue growth</td>
                                <td>{financialResults.monthlyRevenueGrowth
                                    .score}</td>
                                <td>{financialResults.monthlyRevenueGrowth
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Average monthly cash flow</td>
                                <td>{financialResults.avgMonthlyCashflow
                                    .score}</td>
                                <td>{financialResults.avgMonthlyCashflow
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Cash flow validated</td>
                                <td>{financialResults.cashFlowValidated
                                    .score}</td>
                                <td>{financialResults.cashFlowValidated
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Monthly revenue turnover</td>
                                <td>{financialResults.monthlyRevenueTurnover.score}</td>
                                <td>{financialResults.monthlyRevenueTurnover.notes}</td>
                            </tr>

                            <tr>
                                <td>Months consistently cash flow positive</td>
                                <td>{financialResults.consistentlyCashFlowPositive
                                    .score}</td>
                                <td>{financialResults.consistentlyCashFlowPositive
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Timeliness of reporting</td>
                                <td>{financialResults.timelinessOfReporting
                                    .score}</td>
                                <td>{financialResults.timelinessOfReporting
                                    .notes}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>


            <div className={styles.headingBox}>
                <h4>Impact Data via DigiKua</h4>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.table}>
                    <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th>Measure</th>
                                <th>Score</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Number of direct jobs created</td>
                                <td>{impactData.noOfDirectJobsCreated
                                    .score}</td>
                                <td>{impactData.noOfDirectJobsCreated
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Number of indirect jobs created</td>
                                <td>{impactData.noOfIndirectJobsCreated
                                    .score}</td>
                                <td>{impactData.noOfIndirectJobsCreated
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Average full time employee salary</td>
                                <td>{impactData.avgFullTimeEmployeeSalary
                                    .score}</td>
                                <td>{impactData.avgFullTimeEmployeeSalary
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Number of employees</td>
                                <td>{impactData.noOfEmployees
                                    .score}</td>
                                <td>{impactData.noOfEmployees
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Insurances provided</td>
                                <td>{impactData.insurancesProvided.score}</td>
                                <td>{impactData.insurancesProvided.notes}</td>
                            </tr>

                            <tr>
                                <td>Contracts & HR systems</td>
                                <td>{impactData.contractsHRSystems
                                    .score}</td>
                                <td>{impactData.contractsHRSystems
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Timeliness of reporting</td>
                                <td>{impactData.timelinessOfReporting.score}</td>
                                <td>{impactData.timelinessOfReporting.notes}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div className={styles.headingBox} >
                <h4>Participation in Somo's Advisory/ Acceleration Program</h4>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.table}>
                    <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th>Measure</th>
                                <th>Score</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Advisory playbook score</td>
                                <td>{participation.advisoryPlaybook.score}</td>
                                <td>{participation.advisoryPlaybook.notes}</td>
                            </tr>

                            <tr>
                                <td>Business coach score</td>
                                <td>{participation.businessCoach.score}</td>
                                <td>{participation.businessCoach.notes}</td>
                            </tr>

                            <tr>
                                <td>Attendance in Chanuka Check-in</td>
                                <td>{participation.chanukaCheckinAttendance
                                    .score}</td>
                                <td>{participation.chanukaCheckinAttendance
                                    .notes}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>


            <div className={styles.headingBox}>
                <h4>Loan Repayment Timeliness</h4>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.table}>
                    <table className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th>Measure</th>
                                <th>Score</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Late repayments</td>
                                <td>{loanRepayment.lateRepayments.score}</td>
                                <td>{loanRepayment.lateRepayments.notes}</td>
                            </tr>

                            <tr>
                                <td>Percentage of due loans repaid</td>
                                <td>{loanRepayment.percentageOfDueLoansRepaid
                                    .score}</td>
                                <td>{loanRepayment.percentageOfDueLoansRepaid
                                    .notes}</td>
                            </tr>

                            <tr>
                                <td>Number of completed loans repaid</td>
                                <td>{loanRepayment.noOfCompletedLoansRepaid
                                    .score}</td>
                                <td>{loanRepayment.noOfCompletedLoansRepaid
                                    .notes}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ScoresTable;
