import React, { useState, useEffect } from "react";
import styles from "./DigiKuaAnalytics.module.scss";
import DigikuaBarGraph from "../DigikuaBarGraph/DigikuaBarGraph";
import noLoan from "../../assets/no-loan.png";
import { TiCancelOutline } from "react-icons/ti";

const DigiKuaAnalytics = ({
  bizId,
  userEmail,
  biName,
  loadingAnalytics,
  setLoadingAnalytics,
}) => {
  const [years, setYears] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [analyticsError, setAnalyticsError] = useState("");

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [mode, setMode] = useState("monthly");

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yrs = [];

    for (let i = 0; i < 5; i++) {
      yrs.push(currentYear - i);
    }
    setYears(yrs);
  }, []);

  useEffect(() => {
    setSelectedYear(new Date().getFullYear());
    setMode("monthly");
  }, []);

  useEffect(() => {
    if (!selectedYear || !mode) return;
    const fetchAnalytics = async () => {
      try {
        setAnalytics([]);
        setLoadingAnalytics(true);
        setAnalyticsError("");

        const url = `https://invest.somoafrica.org/api/data/stats/cashflow/${bizId}/${biName}/${selectedYear}?dataMode=${mode}&usr_mail=${userEmail}`;

        const res = await fetch(url);

        if (!res.ok)
          throw new Error("Something went wrong with fetching analytics");

        const data = await res.json();
        if (data.Response === "False") throw new Error("Analytics not found");

        const filteredData = [
          {
            title: "Revenue",
            data: data.data.revenue,
            label: "Revenue",
            mode: mode,
            year: selectedYear,
          },
          {
            title: "Operating Profit",
            data: data.data.operatingProfit,
            label: "Profit",
            mode: mode,
            year: selectedYear,
          },
        ];

        setAnalytics(filteredData);
        setLoadingAnalytics(false);
        setAnalyticsError("");
      } catch (error) {
        setAnalyticsError(error.message);
      } finally {
        setLoadingAnalytics(false);
      }
    };
    fetchAnalytics();
  }, [selectedYear, mode]);
  return (
    <div className={styles.analyticsCont}>
      <div className={styles.selectCont}>
        <div>
          <label htmlFor="year">Select Year:</label>
          <select
            id="year"
            className={`${styles.formControl} ${styles.select}`}
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
            }}
          >
            <option selected disabled value="0">
              --Select--
            </option>
            {years.map((year) => (
              <option value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="mode">Group Mode:</label>
          <select
            id="mode"
            className={`${styles.formControl} ${styles.select}`}
            value={mode}
            onChange={(e) => {
              setMode(e.target.value);
            }}
          >
            <option selected disabled value="0">
              --Select--
            </option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
          </select>
        </div>
      </div>
      <div className={styles.analytics}>
        {loadingAnalytics && (
          <>
            <div className={styles.loading}></div>
            <div className={styles.loading}></div>
          </>
        )}
        {!loadingAnalytics && analyticsError && (
          <div className={styles.noQuestions}>
            <p>Something went wrong. Try again later</p>
            <img src={noLoan} alt="No questions" height="250" />
          </div>
        )}
        {!loadingAnalytics &&
          !analyticsError &&
          analytics.map((analytic, index) =>
            analytic.data ? (
              <div key={index} className={styles.analytic}>
                <DigikuaBarGraph analytics={analytic} />
              </div>
            ) : (
              <div className={styles.errorQuestions}>
                <p>{analytic.label}</p>
                <TiCancelOutline
                  className={styles.mobileNoScores}
                  size="5em"
                  color="rgba(116, 23, 99, 0.6)"
                />
                <p>Data not Found</p>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default DigiKuaAnalytics;
