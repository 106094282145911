import React, { useEffect, useState } from "react";
import styles from "./ContentNotes.module.scss";
import Note from "../Note/Note";
import Button from "../Button/Button";
import { FaSearch, FaPlusCircle } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import LoaderIntro from "react-spinners/SyncLoader";

const ContentNotes = ({
  traineeId,
  cohortId,
  baseUrl,
  closePanel,
  setClosePanel,
  screenWidth,
}) => {
  const [clickedNote, setClickedNote] = useState("");
  const [note, setNote] = useState("");
  const [title, setTitle] = useState("");

  const [addNoteButton, setAddNoteButton] = useState("Save");
  const [editNoteButton, setEditNoteButton] = useState("Edit");

  const [notes, setNotes] = useState([]);
  const [notesError, setNotesError] = useState("");
  const [isLoadingNotes, setIsLoadingNotes] = useState();

  // const [editNoteId, setEditNoteId] = useState();
  // const [editNote, setEditNote] = useState([]);
  const [editTitle, setEditTitle] = useState("");

  const [currentNote, setCurrentNote] = useState([]);
  const [currentEditNote, setCurrentEditNote] = useState([]);
  // const [title, setTitle] = useState("");

  const [typedNote, setTypedNote] = useState("");
  const [isTypingNewNote, setIsTypingNewNote] = useState(false);

  const [typedEditNote, setTypedEditNote] = useState("");

  const [editIndex, setEditIndex] = useState(null);
  const [editNoteIndex, setEditNoteIndex] = useState(null);

  const [editNote, setEditNote] = useState("");
  const [newEditNote, setNewEditNote] = useState("");

  const [successAddNote, setSuccessAddNote] = useState(false);
  const [successUpdateNote, setSuccessUpdateNote] = useState(false);

  const [showNote, setShowNote] = useState(false);
  const [noteAdded, setNoteAdded] = useState(false);

  const [actualEditNote, setActualEditNote] = useState([]);
  const [actualEditNoteIndex, setActualEditNoteIndex] = useState(null);
  const [actualNewEditNote, setActualNewEditNote] = useState("");

  const [isEditNote, setIsEditNote] = useState(false);
  const [editId, setEditId] = useState();
  const [editNoteId, setEditNoteId] = useState();

  const enterNewNote = (e) => {
    if (e.key === "Enter" && typedNote.trim() !== "") {
      setCurrentNote([...currentNote, typedNote]);
      setTypedNote("");
    }
  };

  const enterNewEditNote = (e) => {
    if (e.key === "Enter" && typedEditNote.trim() !== "") {
      setCurrentEditNote([...currentEditNote, typedEditNote]);
      setTypedEditNote("");
    }
  };

  const handleAddEditChange = (e) => {
    setEditNote(e.target.value);
  };

  // const handleEditChange = (e) => {
  //   setNewEditNote(e.target.value);
  // };

  const handleEditChange = (e) => {
    const newContent = e.target.innerText; // Get the content from the contentEditable element
    setNewEditNote(newContent); // Update the state with the new content
    e.target.style.height = "auto"; // Reset height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height based on content
  };

  const saveEditNote = (index, type) => {
    if (type === "add") {
      const updatedNotes = [...currentNote];
      updatedNotes[index] = editNote;
      setCurrentNote(updatedNotes);
      setEditIndex(null);
      setEditNote("");
    } else if (type === "edit") {
      const updatedNotes = [...currentEditNote];
      updatedNotes[index] = newEditNote;
      setCurrentEditNote(updatedNotes);
      setEditNoteIndex(null);
      setNewEditNote("");
    }
  };

  const deleteNote = (index, type) => {
    if (type === "add") {
      const updatedNotes = currentNote.filter((_, i) => i !== index);
      setCurrentNote(updatedNotes);
      setEditIndex(null);
      setEditNote("");
    } else if (type === "edit") {
      const updatedNotes = currentEditNote.filter((_, i) => i !== index);
      setCurrentEditNote(updatedNotes);
      setEditNoteIndex(null);
      setNewEditNote("");
    }
  };

  const fetchNotes = async () => {
    try {
      setIsLoadingNotes(true);
      setNotesError("");

      const res = await fetch(`${baseUrl}trainees/${traineeId}/notes`);

      if (!res.ok) throw new Error("Something went wrong with fetching notes");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Notes not found");

      setNotes(data.data);
      setNotesError("");
    } catch (err) {
      setNotesError(err.message);
    } finally {
      setIsLoadingNotes(false);
    }
  };

  useEffect(() => {
    if (!traineeId && baseUrl) return;

    fetchNotes();
  }, [traineeId, baseUrl]);

  useEffect(() => {
    if (clickedNote !== "") {
      const selectedNote = notes.find((note) => note.id === clickedNote);
      setTitle(selectedNote.title);
      setNote(selectedNote.paragraph);
    } else {
      return;
    }
  }, [clickedNote]);

  const handleEditNote = () => {
    if (!editTitle) {
      toast.error("Title cannot be empty");
    } else if (!editNoteId) {
      toast.error("Note ID cannot be empty");
    } else if (!currentEditNote || currentEditNote.length === 0) {
      toast.error("Notes cannot be empty");
    } else if (!traineeId) {
      toast.error("User ID cannot be empty");
    } else {
      setEditNoteButton(<LoaderIntro color="#fff" />);

      const data = {
        cohortId: cohortId,
        type: "list",
        title: editTitle,
        notes: currentEditNote.reduce((acc, note, index) => {
          acc[index + 1] = note;
          return acc;
        }, {}),
      };

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const url = `${baseUrl}trainees/${traineeId}/notes/${editNoteId}`;

      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok: ${response.statusText}`
            );
          }
          return response.json();
        })
        .then((data) => {
          if (data.error === false) {
            toast.success(data.message);
            fetchNotes();
            setEditTitle("");
            setCurrentEditNote([]);
            setEditNoteButton("Edit");
            setNoteAdded(true);
          } else {
            toast.error(data.message);
            setEditNoteButton("Edit");
          }
        })
        .catch((error) => {
          toast.error(`Error: ${error.message}`);
          setEditNoteButton("Edit");
        });
    }
  };

  return (
    //listing notes
    <div className={styles.contentNotes}>
      {isLoadingNotes && (
        <div>
          <p>
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#00A5A2"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </p>
        </div>
      )}

      {!isLoadingNotes && (
        <div className={styles.searchNotes}>
          <div className={styles.searchContainer}>
            <input type="text" placeholder="Search" />
            <FaSearch color="#878787" title="Search note" />
          </div>

          <FaPlusCircle
            title="Open new form"
            style={{ cursor: "pointer" }}
            className={styles.addIcon}
            color="#00A5A2"
            size="2rem"
            onClick={() => {
              // handleShowAddNoteModal();
            }}
          />
        </div>
      )}

      <div className={styles.notesSection}>
        <div className={styles.listNotes}>
          {!isLoadingNotes && !notesError && notes.length > 0 && (
            <div className={styles.notes}>
              {notes.map((note) => (
                <div
                  className={styles.note}
                  onClick={() => {
                    setEditTitle(note.title);
                    setEditNoteId(note.id);
                    let filteredNotes = [];
                    Object.entries(note.notes).map(([key, value]) => {
                      filteredNotes.push(value);
                    });
                    setCurrentEditNote(filteredNotes);
                  }}
                >
                  <h5>
                    {note.title.length > 20
                      ? `${note.title.substring(0, 20)}...`
                      : note.title}
                  </h5>
                  <div className={styles.noteCont}>
                    <ul>
                      {Object.entries(note.notes).map(([key, value]) => (
                        <li key={key}>{value}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {!isLoadingNotes && (
          <div className={styles.notesForm}>
            <div className={styles.formBody}>
              <input
                value={editTitle}
                onChange={(e) => {
                  setEditTitle(e.target.value);
                }}
                type="text"
                placeholder="Title"
              />
              <input
                type="text"
                value={editNoteId}
                onChange={(e) => setEditNoteId(e.target.value)}
                hidden
              />
              <ul>
                {currentEditNote.map((note, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setEditNoteIndex(index);
                      setNewEditNote(note);
                    }}
                  >
                    {editNoteIndex === index ? (
                      <>
                        <div
                          contentEditable
                          className="editableDiv"
                          onInput={handleEditChange} // Use onInput for contentEditable elements
                          onBlur={() => saveEditNote(editNoteIndex, "edit")}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent default Enter behavior
                              saveEditNote(editNoteIndex, "edit");
                            }
                            if (e.key === "Backspace" && newEditNote === "") {
                              deleteNote(editNoteIndex, "edit");
                            }
                            if (e.key === "Delete" && newEditNote === "") {
                              deleteNote(editNoteIndex, "edit");
                            }
                          }}
                        >
                          {newEditNote}
                        </div>
                        {/* <input
                          className={styles.newNote}
                          type="text"
                          value={newEditNote}
                          onChange={handleEditChange}
                          onBlur={() => saveEditNote(index, "edit")}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              saveEditNote(index, "edit");
                            }

                            if (e.key === "Backspace" && newEditNote === "") {
                              deleteNote(index, "edit");
                            }

                            if (e.key === "Delete" && newEditNote === "") {
                              deleteNote(index, "edit");
                            }
                          }}
                        /> */}
                      </>
                    ) : (
                      note
                    )}
                  </li>
                ))}
              </ul>
              <ul>
                <li>
                  <p
                    contentEditable
                    className={styles.newNote}
                    placeholder="Start typing..."
                    onInput={(e) => setTypedEditNote(e.target.value)}
                    onKeyDown={enterNewEditNote}
                    style={{ height: "auto" }}
                  >
                    {typedEditNote}
                  </p>
                  {/* <input
                    className={styles.newNote}
                    type="text"
                    placeholder="Start typing..."
                    value={typedEditNote}
                    onChange={(e) => setTypedEditNote(e.target.value)}
                    onKeyDown={enterNewEditNote}
                    style={{ height: "auto" }}
                  /> */}
                </li>
              </ul>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                bgColor="#00A5A2"
                size="sm"
                color="#fff"
                className={`${styles.button} mt-4`}
                clicked={handleEditNote}
              >
                <span className={styles.btnTxt}> {editNoteButton}</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentNotes;
