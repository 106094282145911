import React, { useState, useContext, useEffect, useMemo } from "react";
import InputField from "../InputField/InputField";
import { Row, Col } from "react-bootstrap";
import styles from "./BvnForm.module.scss";
import { Context as UserContext } from "../../context/UserContext";
import { Context as BankContext } from "../../context/BankCotext";
import Button from "../Button/Button";
import { validateInput } from "../../utils/validateInput";
import { ToastContainer, toast } from "react-toastify";

const BvnForm = ({ submit }) => {
  const [verificationData, setVerificationData] = useState({
    userPhone: "",
    // bankName: null,
    // accountNumber: "",
  });
  const [verificationError, setVerificationError] = useState({
    userPhone: null,
    // bankName: null,
    // accountNumber: null,
  });
  const {
    state: { loading },
  } = useContext(UserContext);

  // const {
  //   state: { paystackBanks },
  //   getPaystackBankList,
  // } = useContext(BankContext);

  // const bankNames = useMemo(() => {
  //   return paystackBanks.map((bank) => bank.name);
  // }, [paystackBanks]);

  // useEffect(() => {
  //   getPaystackBankList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const verifyCustomer = () => {
    const validated = validateInput(verificationData, setVerificationError);
    // console.log(validated, verificationData);

    if (!verificationData.userPhone.match(/^[0]\d{9}$/) && !verificationData.userPhone.match(/^[2]\d{11}$/)) {
      toast.error(
        "Enter a valid M-Pesa phone"
      );
    } else if (!verificationData.userCountry) {
      toast.error(
        "Select your country"
      );
    } else if (validated) {
      const data = {
        phone: verificationData.userPhone,
        country: verificationData.userCountry,
        // account_number: verificationData.accountNumber
      };
      submit(data);
    } else {
      console.log('End! Else');
      toast.error(
        "Uncaught Exception"
      );
    }
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      verifyCustomer();
    }
  };

  return (
    <div className={styles.bvnFormBox}>
      <ToastContainer position="top-center" />
      <p>
        Verify your phone to access additional features on this portal such as:
        <li>Loans</li>
        <li>DigiKua</li>
        <li>Surveys, Scorecard and more</li>
      </p>
      <Row className="mb-4">
        <Col>
          <InputField
            label="What's your country?"
            nameAttr="country"
            type="select"
            options={['Kenya', 'Tanzania']}
            value={verificationData.userCountry}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({ ...verificationError, userCountry: null });
              setVerificationData({ ...verificationData, userCountry: val });
            }}
            error={verificationError?.userCountry}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <InputField
            label="What's your M-Pesa Phone Number?"
            nameAttr="bvn"
            type="text"
            placeholder="07xxxxxxxx or 01xxxxxxxx or 06xxxxxxxx"
            value={verificationData.userPhone}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({ ...verificationError, userPhone: null });
              setVerificationData({ ...verificationData, userPhone: val });
            }}
            error={verificationError?.userPhone}
          />
        </Col>
      </Row>
      {/* <Row className="mb-4">
        <Col>
          <InputField
            label="Account Number"
            nameAttr="accountNumber"
            type="numbet"
            value={verificationData.accountNumber}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
            changed={(val) => {
              setVerificationError({
                ...verificationError,
                accountNumber: null,
              });
              setVerificationData({ ...verificationData, accountNumber: val });
            }}
            error={verificationError?.accountNumber}
          />
        </Col>
      </Row> */}
      <Button
        className="mt-4"
        fullWidth
        clicked={verifyCustomer}
        bgColor="#cc6328"
        size="lg"
        color="#EBEBEB"
        loading={loading}
        disabled={loading}
      >
        Verify
      </Button>
      <p className={styles.extraTip}>
        You will be charged <b>Ksh1.00</b> or <b>Tsh100.00</b> to verify your M-Pesa phone
      </p>
    </div>
  );
};

export default BvnForm;
