import React, { useState, useEffect } from "react";
import styles from "./LMSNotes.module.scss";
import { ReactComponent as Icon } from "../../assets/icons/notes.svg";
import Note from "../Note/Note";
import Button from "../Button/Button";
import { ToastContainer, toast } from "react-toastify";
import LoaderIntro from "react-spinners/SyncLoader";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import noLoan from "../../assets/no-loan.png";
import { TiCancelOutline } from "react-icons/ti";

import {
  useParams,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import {
  FaPlusCircle,
  FaSearch,
  FaArrowCircleLeft,
  FaCloudDownloadAlt,
  FaChevronRight,
} from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import DigikuaButton from "../DigikuaButton/DigikuaButton";
import { getDefaultNormalizer } from "@testing-library/react";

const LMSNotes = ({
  traineeId,
  userId,
  cohortId,
  baseUrl,
  closePanel,
  setClosePanel,
  screenWidth,
}) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const { noteId } = useParams();
  const [clickedNote, setClickedNote] = useState(null);

  const [note, setNote] = useState([]);
  const [noteError, setNoteError] = useState("");
  const [isLoadingNote, setIsLoadingNote] = useState();

  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [addNoteButton, setAddNoteButton] = useState("Add");
  const [editNoteButton, setEditNoteButton] = useState("Edit");
  const [editTitle, setEditTitle] = useState("");
  const [editParagraph, setEditParagraph] = useState([]);

  const [notes, setNotes] = useState([]);
  const [displayNotes, setDisplayNotes] = useState([]);
  const [notesError, setNotesError] = useState("");
  const [isLoadingNotes, setIsLoadingNotes] = useState();

  const [typedNote, setTypedNote] = useState("");
  const [isTypingNewNote, setIsTypingNewNote] = useState(false);

  const [typedEditNote, setTypedEditNote] = useState("");

  const [currentNote, setCurrentNote] = useState([]);
  const [currentEditNote, setCurrentEditNote] = useState([]);
  const [title, setTitle] = useState("");

  const [editIndex, setEditIndex] = useState(null);
  const [editNoteIndex, setEditNoteIndex] = useState(null);

  const [editNote, setEditNote] = useState("");
  const [newEditNote, setNewEditNote] = useState("");

  const [successAddNote, setSuccessAddNote] = useState(false);
  const [successUpdateNote, setSuccessUpdateNote] = useState(false);

  const [showNote, setShowNote] = useState(false);
  const [noteAdded, setNoteAdded] = useState(false);

  const [actualEditNote, setActualEditNote] = useState([]);
  const [actualEditNoteIndex, setActualEditNoteIndex] = useState(null);
  const [actualNewEditNote, setActualNewEditNote] = useState("");

  const [isEditNote, setIsEditNote] = useState(false);
  const [editId, setEditId] = useState();
  const [editNoteId, setEditNoteId] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  //Handling modals
  const handleShowAddNoteModal = () => setShowAddNoteModal(true);
  const handleCloseAddNoteModal = () => setShowAddNoteModal(false);
  const handleShowEditNoteModal = () => setShowEditNoteModal(true);
  const handleCloseEditNoteModal = () => {
    setShowEditNoteModal(false);
    setIsEditNote(false);
    setEditId();
  };

  const fetchNotes = async (baseUrl, traineeId) => {
    try {
      setIsLoadingNotes(true);
      setNotesError("");

      const res = await fetch(`${baseUrl}trainees/${traineeId}/notes`);

      if (!res.ok) throw new Error("Something went wrong with fetching notes");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Notes not found");

      setNotes(data.data);
      setDisplayNotes(data.data);
      setNotesError("");
    } catch (err) {
      setNotesError(err.message);
    } finally {
      setIsLoadingNotes(false);
    }
  };

  useEffect(() => {
    const pathAfterLms = location.pathname.split("/lms/")[1];
    const pathSegments = pathAfterLms.split("/");

    const lastSegment = pathSegments[pathSegments.length - 1];
    const lastNumber = parseInt(lastSegment, 10);

    if (!isNaN(lastNumber)) {
      setClickedNote(lastNumber);
    }
  }, []);

  useEffect(() => {
    fetchNotes(baseUrl, traineeId);
  }, []);

  useEffect(() => {
    if (!noteAdded) return;
    fetchNotes(baseUrl, traineeId);
    setNoteAdded(false);
  }, [noteAdded]);

  const enterNewNote = (e) => {
    if (e.key === "Enter" && typedNote.trim() !== "") {
      setCurrentNote([...currentNote, typedNote]);
      setTypedNote("");
    }
  };

  const enterNewEditNote = (e) => {
    if (e.key === "Enter" && typedEditNote.trim() !== "") {
      setCurrentEditNote([...currentEditNote, typedEditNote]);
      setTypedEditNote("");
    }
  };

  const handleAddEditChange = (e) => {
    setEditNote(e.target.value);
  };

  const handleEditChange = (e) => {
    setNewEditNote(e.target.value);
  };

  const saveEditNote = (index, type) => {
    if (type === "add") {
      const updatedNotes = [...currentNote];
      updatedNotes[index] = editNote;
      setCurrentNote(updatedNotes);
      setEditIndex(null);
      setEditNote("");
    } else if (type === "edit") {
      const updatedNotes = [...currentEditNote];
      updatedNotes[index] = newEditNote;
      setCurrentEditNote(updatedNotes);
      setEditNoteIndex(null);
      setNewEditNote("");
    }
  };

  const deleteNote = (index, type) => {
    if (type === "add") {
      const updatedNotes = currentNote.filter((_, i) => i !== index);
      setCurrentNote(updatedNotes);
      setEditIndex(null);
      setEditNote("");
    } else if (type === "edit") {
      const updatedNotes = currentEditNote.filter((_, i) => i !== index);
      setCurrentEditNote(updatedNotes);
      setEditNoteIndex(null);
      setNewEditNote("");
    }
  };

  //Was adding or updating note successful?
  useEffect(() => {
    if (successAddNote) {
      console.log("Note added successfully");
      toast.success("Note added successfully");
      setSuccessAddNote(false);
    }

    if (successUpdateNote) {
      console.log("Note updated successfully");
      toast.success("Note updated successfully");
      setSuccessUpdateNote(false);
    }
  }, [
    successAddNote,
    successUpdateNote,
    setSuccessAddNote,
    setSuccessUpdateNote,
  ]);

  const addNote = () => {
    if (!title) {
      toast.error("Title cannot be empty");
    } else if (!cohortId) {
      toast.error("Missing required data");
    } else if (!currentNote || currentNote.length === 0) {
      toast.error("Notes cannot be empty");
    } else if (!traineeId) {
      toast.error("User ID cannot be empty");
    } else {
      setAddNoteButton(<LoaderIntro color="#fff" />);

      const data = {
        cohortId: cohortId,
        type: "list",
        title: title,
        notes: currentNote.reduce((acc, note, index) => {
          acc[index + 1] = note;
          return acc;
        }, {}),
        createdBy: userId,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      fetch(`${baseUrl}trainees/${traineeId}/notes`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            handleCloseAddNoteModal();
            setSuccessAddNote(true);
            setTitle("");
            setCurrentNote([]);
            setAddNoteButton("Add");
            setNoteAdded(true);
          } else {
            toast.error(data.message);
            setSuccessAddNote(false);
            setAddNoteButton("Add");
          }
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          setSuccessAddNote(false);
          setAddNoteButton("Add");
        });
    }
  };

  const handleEditNote = () => {
    if (!editTitle) {
      toast.error("Title cannot be empty");
    } else if (!editNoteId) {
      toast.error("Note ID cannot be empty");
    } else if (!currentEditNote || currentEditNote.length === 0) {
      toast.error("Notes cannot be empty");
    } else if (!traineeId) {
      toast.error("User ID cannot be empty");
    } else {
      setEditNoteButton(<LoaderIntro color="#fff" />);

      const data = {
        cohortId: cohortId,
        type: "list",
        title: editTitle,
        notes: currentEditNote.reduce((acc, note, index) => {
          acc[index + 1] = note;
          return acc;
        }, {}),
      };

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const url = `${baseUrl}trainees/${traineeId}/notes/${editNoteId}`;
      console.log(url);

      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok: ${response.statusText}`
            );
          }
          return response.json();
        })
        .then((data) => {
          console.log("Request URL:", url);
          console.log("Request Options:", requestOptions);
          console.log("Response Data:", data);

          if (data.error === false) {
            handleCloseEditNoteModal();
            setSuccessUpdateNote(true);
            setEditTitle("");
            setCurrentEditNote([]);
            setEditNoteButton("Edit");
            setNoteAdded(true);
          } else {
            toast.error(data.message);
            setSuccessUpdateNote(false);
            setEditNoteButton("Edit");
          }
        })
        .catch((error) => {
          toast.error(`Error: ${error.message}`);
          setSuccessUpdateNote(false);
          setEditNoteButton("Edit");
        });
    }
  };

  const getNote = async (id, isEditNote) => {
    try {
      setNotesError("");
      setIsLoadingNote(true);
      const res = await fetch(`${baseUrl}trainees/${traineeId}/notes/${id}`);

      if (!res.ok) throw new Error("Something went wrong with fetching note");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Note not found");

      if (isEditNote) {
        const notesArray = Object.values(data.data[0].notes);
        setCurrentEditNote([...notesArray]);
        setEditTitle(data.data[0].title);
        setEditNoteId(data.data[0].id);
      } else {
        setNote(data.data);
      }

      setNotesError("");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingNote(false);
    }
  };

  useEffect(() => {
    if (!clickedNote) return;
    history.push(`${path}/${clickedNote}`);
    getNote(clickedNote);
  }, [clickedNote]);

  useEffect(() => {
    if (!isEditNote && !editId) return;
    getNote(editId, isEditNote);
  }, [isEditNote, editId]);

  const getOccurrence = (closePanel, screenWidth) => {
    if (closePanel && screenWidth < 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth < 1200) {
      return ["0%", "none"];
    } else if (closePanel && screenWidth > 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth > 1200) {
      return ["70%", ""];
    }
  };

  const handlePDFDownload = () => {
    const noteDiv = document.getElementById("note");
    const download = document.getElementById("download");
    const edit = document.getElementById("edit");
    download.style.display = "none";
    edit.style.display = "none";
    html2canvas(noteDiv).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("notes.pdf");
    });
    download.style.display = "flex";
    edit.style.display = "inline-block";
  };

  useEffect(() => {
    setDisplayNotes(
      notes.filter((note) =>
        note.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    );
  }, [searchTerm]);

  return (
    <div
      className={styles.lmsNotes}
      style={{
        width: getOccurrence(closePanel, screenWidth)[0],
        display: getOccurrence(closePanel, screenWidth)[1],
      }}
    >
      <Switch>
        <Route exact path={`${path}`}>
          <div className={styles.topBar}>
            <div className={styles.header}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {closePanel ? (
                  <div
                    className={styles.openMenu}
                    onClick={() => setClosePanel(false)}
                  >
                    <FaChevronRight size="1rem" color="#cc6328" />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <Icon
                className={styles.icon}
                style={{ marginLeft: closePanel ? "" : "20px" }}
              />
              <h2>My Notes</h2>
            </div>
          </div>
        </Route>
        <Route path={`${path}/:noteId`}>
          <div className={styles.topBar}>
            <div className={styles.header} style={{ paddingLeft: "20px" }}>
              <Button
                bgColor="#cc6328"
                size="sm"
                color="#fff"
                clicked={() => {
                  history.push(`${path}`);
                  // setNote([]);
                  setClickedNote(null);
                  setCurrentEditNote([]);
                  setEditTitle("");
                  setEditNoteId();
                }}
              >
                <FaArrowCircleLeft />
              </Button>
            </div>
          </div>
        </Route>
      </Switch>

      <div className={styles.contentBar}>
        <Switch>
          <Route exact path={`${path}`}>
            <div className={styles.searchNotes}>
              <FaPlusCircle
                className={styles.addIcon}
                color="#00A5A2"
                size="2rem"
                onClick={() => {
                  handleShowAddNoteModal();
                }}
              />
              <div className={styles.searchContainer}>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FaSearch style={{ cursor: "pointer" }} color="#878787" />
              </div>
            </div>
            {isLoadingNotes && (
              <div className={styles.loader}>
                <p>
                  <ThreeDots
                    visible={true}
                    height="80"
                    width="80"
                    color="#00A5A2"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </p>
              </div>
            )}

            {!isLoadingNotes && !notesError && notes.length > 0 && (
              <div className={styles.notes}>
                {displayNotes.map((note, index) => (
                  <Note
                    key={index}
                    id={note.id}
                    title={note.title}
                    notes={Object.values(note.notes)}
                    clicked={setClickedNote}
                  />
                ))}
              </div>
            )}

            {!isLoadingNotes && !notesError && notes.length <= 0 && (
              <div className={styles.noNotes}>
                <Icon className={styles.icon} />
                <h2>You have not added any notes yet.</h2>
              </div>
            )}

            {!isLoadingNotes && notesError && (
              <>
                <div className={styles.errorQuestions}>
                  <TiCancelOutline
                    className={styles.mobileNoScores}
                    size="5em"
                    color="rgba(116, 23, 99, 0.6)"
                  />
                  <p>{notesError}</p>
                </div>
              </>
            )}
          </Route>
          <Route path={`${path}/:noteId`}>
            <div className={styles.noteBody}>
              {isLoadingNote && (
                <div className={styles.loader}>
                  <p>
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#00A5A2"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </p>
                </div>
              )}
              {!isLoadingNote && noteError && <p>Error</p>}
              {!isLoadingNote && !noteError && note.length > 0 && (
                <>
                  <div className={styles.noteCont} id="note">
                    <div className={styles.notesHeader}>
                      <div className={styles.header}>
                        <Icon className={`${styles.icon} ${styles.noteIcon}`} />
                        <h2 className={styles.title}>{note[0].title}</h2>
                      </div>
                      <div
                        className={styles.download}
                        onClick={handlePDFDownload}
                        id="download"
                      >
                        <FaCloudDownloadAlt size="2rem" color="#ffffff" />
                      </div>
                    </div>
                    <div className={styles.notesBody}>
                      <ul>
                        {Object.values(note[0].notes).map((note, index) => (
                          <li key={index}>{note}</li>
                        ))}
                      </ul>
                      <button
                        className={styles.editButton}
                        id="edit"
                        style={{ width: getDefaultNormalizer }}
                        onClick={() => {
                          handleShowEditNoteModal();
                          setIsEditNote(true);
                          setEditId(note[0].id);
                        }}
                      >
                        <span className={styles.btnTxt}>Edit</span>
                      </button>
                    </div>
                  </div>
                </>
              )}

              {!isLoadingNote && !noteError && note.length < 1 && (
                <div className={styles.noQuestions}>
                  <p>Note not found.</p>
                  <img src={noLoan} alt="No questions" height="250" />
                </div>
              )}
            </div>
          </Route>
        </Switch>
      </div>
      {/* Adding a Note */}
      <Modal show={showAddNoteModal} onHide={handleCloseAddNoteModal}>
        <div className={`${styles.AddModal}`}>
          <ToastContainer position="top-center" />
          <div className={styles.modalHeader}>
            <h4 className={styles.addHeader}>
              <Icon className={styles.addHeaderIcon} /> Add Note
            </h4>
          </div>
          <div className={styles.modalBody}>
            <input
              style={{ fontWeight: "bold", marginBottom: "10px" }}
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <ul>
              {currentNote.map((note, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setEditIndex(index);
                    setEditNote(note);
                  }}
                >
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={editNote}
                      onChange={handleAddEditChange}
                      onBlur={() => saveEditNote(index, "add")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          saveEditNote(index, "add");
                        }

                        if (e.key === "Backspace" && editNote === "") {
                          deleteNote(index, "add");
                        }

                        if (e.key === "Delete" && editNote === "") {
                          deleteNote(index, "add");
                        }
                      }}
                    />
                  ) : (
                    note
                  )}
                </li>
              ))}
            </ul>

            <ul>
              <li>
                <input
                  className={styles.newNote}
                  type="text"
                  placeholder="Start typing..."
                  value={typedNote}
                  onChange={(e) => setTypedNote(e.target.value)}
                  onKeyDown={enterNewNote}
                  style={{ height: "auto" }}
                />
              </li>
            </ul>
          </div>
          <div className={styles.modalFooter}>
            <DigikuaButton
              fontSize="1rem"
              size="lg"
              margin=".3rem"
              bgColor="#C4233C"
              color="#fff"
              clicked={handleCloseAddNoteModal}
            >
              Cancel
            </DigikuaButton>
            <DigikuaButton
              fontSize="1rem"
              size="lg"
              margin=".3rem"
              bgColor="#00A5A2"
              color="#fff"
              clicked={addNote}
            >
              {addNoteButton}
            </DigikuaButton>
          </div>
        </div>
      </Modal>
      {/* Editing a Note */}
      {!isLoadingNote && !noteError && note.length > 0 && (
        <Modal show={showEditNoteModal} onHide={handleCloseEditNoteModal}>
          <div className={`${styles.AddModal}`}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4 className={styles.addHeader}>
                <Icon className={styles.addHeaderIcon} /> Edit Note
              </h4>
            </div>
            <div className={styles.modalBody}>
              <input
                type="text"
                placeholder="Title"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                style={{ fontWeight: "bold", marginBottom: "10px" }}
              />
              <input
                type="text"
                value={editNoteId}
                onChange={(e) => setEditNoteId(e.target.value)}
                hidden
              />
              <ul>
                {currentEditNote.map((note, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setEditNoteIndex(index);
                      setNewEditNote(note);
                    }}
                  >
                    {editNoteIndex === index ? (
                      <input
                        className={styles.newNote}
                        type="text"
                        value={newEditNote}
                        onChange={handleEditChange}
                        onBlur={() => saveEditNote(index, "edit")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            saveEditNote(index, "edit");
                          }

                          if (e.key === "Backspace" && newEditNote === "") {
                            deleteNote(index, "edit");
                          }

                          if (e.key === "Delete" && newEditNote === "") {
                            deleteNote(index, "edit");
                          }
                        }}
                      />
                    ) : (
                      note
                    )}
                  </li>
                ))}
              </ul>

              <ul>
                <li>
                  <input
                    className={styles.newNote}
                    type="text"
                    placeholder="Start typing..."
                    value={typedEditNote}
                    onChange={(e) => setTypedEditNote(e.target.value)}
                    onKeyDown={enterNewEditNote}
                    style={{ height: "auto" }}
                  />
                </li>
              </ul>

              {/* <textarea name="" id="" cols="30" rows="10" placeholder="Notes">
                {editParagraph}
              </textarea> */}
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditNoteModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={handleEditNote}
              >
                {editNoteButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LMSNotes;
