import React from "react";
import styles from "./ContentResources.module.scss";

const ContentResources = () => {
  const resources = [
    {
      id: 0,
      name: "Tutorial on DigiKua",
      link: "https://www.youtube.com/watch?v=YLSxO36EJYc",
    },
    {
      id: 1,
      name: "Entrepreneur's cashflow",
      link: "https://www.youtube.com/watch?v=YLSxO36EJYc",
    },
    {
      id: 2,
      name: "Review & analyze cash flow",
      link: "https://www.youtube.com/watch?v=YLSxO36EJYc",
    },
    {
      id: 3,
      name: "How to submit a sale",
      link: "https://www.youtube.com/watch?v=YLSxO36EJYc",
    },
  ];
  return (
    <div className={styles.resources}>
      {resources.map((resource) => (
        <div className={styles.resource}>
          <a style={{ cursor: "pointer" }} href={resource.link}>
            {resource.name}
          </a>
        </div>
      ))}
    </div>
  );
};

export default ContentResources;
