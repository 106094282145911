import React, { useEffect, useState } from "react";
import styles from "./ContentReviews.module.scss";
import StarIcon from "../StarIcon/StarIcon";
import Button from "../Button/Button";
import { ThreeDots } from "react-loader-spinner";
import { TiCancelOutline } from "react-icons/ti";
import { ToastContainer, toast } from "react-toastify";
import LoaderIntro from "react-spinners/SyncLoader";

const ContentReviews = ({ traineeId, topicId, baseUrl }) => {
  const [review, setReview] = useState([]);
  const [isLoadingReview, setIsLoadingReview] = useState();
  const [reviewError, setReviewError] = useState("");
  const [starId, setStarId] = useState(-1);
  const [comment, setComment] = useState("");
  const [starIdValue, setStarIdValue] = useState();
  const [commentValue, setCommentValue] = useState("");
  const [reviewButton, setReviewButton] = useState("Submit");
  const [reloadReview, setReloadReview] = useState();
  const stars = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

  useEffect(() => {
    if (!traineeId || !topicId) return;
    getReview();
  }, [traineeId, topicId]);

  useEffect(() => {
    if (!traineeId || !topicId) return;
    getReview();
    setReloadReview(false);
  }, [reloadReview]);

  const getReview = async () => {
    try {
      setReview([]);
      setIsLoadingReview(true);
      setReviewError("");
      const res = await fetch(
        `${baseUrl}topics/${topicId}/reviews?trainee_id=${traineeId}`
      );

      if (!res.ok) throw new Error("Something went wrong with fetching review");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Review not found");
      setReview(data.data ? data.data : []);
      // setStarIdValue(data.data > 0 ? data.data[0].rating : -1);
      // setCommentValue(data.data > 0 ? data.data[0].comment : "");
      setReviewError("");
      setIsLoadingReview(false);
    } catch (error) {
      setReviewError(error.message);
    } finally {
      setIsLoadingReview(false);
    }
  };

  const addReview = async () => {
    if (!traineeId || !topicId) {
      toast.error("Missing required data");
    } else if (!topicId || !comment) {
      toast.error("Fill all fields");
    } else {
      setReviewButton(<LoaderIntro color="#fff" />);

      const formData = new FormData();

      formData.append("traineeId", traineeId);
      formData.append("rating", starId);
      formData.append("comment", comment);

      const requestOptions = {
        method: "POST",
        headers: {},
        body: formData,
      };

      fetch(`${baseUrl}topics/${topicId}/reviews`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            toast.success(data.message);
            setReviewButton("Submit");
            setReloadReview(true);
          } else {
            toast.error(data.message);
            setReviewButton("Submit");
          }
        })
        .catch((error) => {
          toast.error(error);
          setReviewButton("Submit");
        });
    }
  };
  return (
    <div>
      {!isLoadingReview && !reviewError && review.length < 1 && (
        <>
          <ToastContainer position="top-center" />
          <div className={styles.heading}>
            <h3>Rate this topic</h3>
          </div>
          <div className={styles.reviewContainer}>
            <div className={styles.stars}>
              {stars.map((star, index) => (
                <div
                  key={index}
                  className={styles.star}
                  onClick={() => setStarId(star.id)}
                >
                  <StarIcon
                    width="100%"
                    height="100%"
                    fill={star.id <= starId ? "#FFC83B" : "#E4E4E4"}
                  />
                </div>
              ))}
            </div>
            <textarea
              placeholder="Add a comment..."
              name=""
              id=""
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div>
            <Button
              bgColor="#00A5A2"
              size="sm"
              color="#fff"
              className={`${styles.button}`}
              clicked={addReview}
            >
              <span className={styles.btnTxt}>{reviewButton}</span>
            </Button>
          </div>
        </>
      )}

      {!isLoadingReview && !reviewError && review.length > 0 && (
        <>
          <div className={styles.heading}>
            <h3>Your Rating</h3>
          </div>
          <div className={styles.reviewContainer}>
            <div className={styles.stars}>
              {stars.map((star, index) => (
                <div className={styles.star} key={index}>
                  <StarIcon
                    width="100%"
                    height="100%"
                    fill={star.id <= review[0].rating ? "#FFC83B" : "#E4E4E4"}
                  />
                </div>
              ))}
            </div>
            <textarea
              placeholder="Add a comment..."
              name=""
              id=""
              value={review[0].comment}
            ></textarea>
          </div>
        </>
      )}

      {isLoadingReview && (
        <div>
          <p>
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#00A5A2"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </p>
        </div>
      )}

      {!isLoadingReview && reviewError && (
        <div className={styles.errorQuestions}>
          <TiCancelOutline
            className={styles.mobileNoQuestions}
            size="5em"
            color="rgba(116, 23, 99, 0.6)"
          />
          <p>{reviewError}</p>
        </div>
      )}
    </div>
  );
};

export default ContentReviews;
