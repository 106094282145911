import React from "react";

const StarIcon = ({ width = "512px", height = "512px", fill = "#E4E4E4" }) => {
  return (
    <svg
      height={height}
      id="Layer_1"
      style={{ enableBackground: "new 0 0 512 512", fill }}
      version="1.1"
      viewBox="0 0 512 512"
      width={width}
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <polygon points="448,208 301,208 256,64 211,208 64,208 183.1,297.3 136,448 256,352 376,448 328.9,297.3" />
    </svg>
  );
};

export default StarIcon;
