import React from "react";
import styles from "./Footer.module.scss";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaYoutubeSquare,
  FaFacebook,
  FaFacebookMessenger,
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import PlainLogo from "../../assets/logo-white.png";
import { Link } from "react-router-dom";
import pageUrl from "../../routes/pageUrl";

const Footer = () => {
  return (
    <div className={styles.footerSection}>
      <div className={styles.container}>
        <img src={PlainLogo} alt="Somo Logo" />
        <div className={styles.socialLinks}>
          <a href="https://www.facebook.com/somo.org/" target="_blank">
            <FaFacebookSquare size="1.4em" />
          </a>
          <a
            href="https://www.instagram.com/somo_org/" target="_blank">
            <FaInstagramSquare size="1.4em" />
          </a>
          <a href="https://twitter.com/somo_org" target="_blank">
            <FaTwitterSquare size="1.4em" />
          </a>
          <a href="https://www.linkedin.com/company/the-somo-project/?viewAsMember=true" target="_blank">
            <FaLinkedin size="1.4em" />
          </a>
          <a href="https://www.youtube.com/channel/UCwl-vQYeqHbJXIiZZadvG6g" target="_blank">
            <FaYoutubeSquare size="1.4em" />
          </a>
        </div>
        <div className={styles.footerLinks}>
          <ul>
            <li>
              {/* <Link to={pageUrl.PRIVACY_POLICY_PAGE}>Privacy Policy</Link> */}
              <a href={pageUrl.PRIVACY_POLICY_PAGE}>Privacy Policy</a>
            </li>
            {/* <li>
              <Link to={pageUrl.TERMS_CONDITIONS_PAGE}>Terms & Conditions</Link>
            </li> */}
            <li>
              <a href="https://www.somoafrica.org/faqs">FAQs</a>
            </li>
          </ul>
        </div>
        <p className={styles.copyright}>
          &copy; {new Date().getFullYear()} {process.env.REACT_APP_SYSTEM_NAME}. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
