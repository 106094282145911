import React, { useContext, useEffect, useState } from "react";
import styles from "./Invoices.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import { Row, Col, Modal } from "react-bootstrap";
import { Fa500Px, FaAccusoft, FaAmilia, FaArrowCircleLeft, FaArrowRight, FaBatteryEmpty, FaBatteryQuarter, FaBell, FaBellSlash, FaBomb, FaBook, FaBookOpen, FaBookReader, FaBusinessTime, FaCalendarCheck, FaCarBattery, FaChalkboardTeacher, FaCheck, FaCheckCircle, FaCheckDouble, FaCheckSquare, FaCircle, FaCircleNotch, FaClock, FaCloudUploadAlt, FaFileInvoice, FaFileUpload, FaFirstOrder, FaFlagCheckered, FaHome, FaMailBulk, FaMoneyCheckAlt, FaRegArrowAltCircleDown, FaRegBell, FaRocket, FaSms, FaSquare, FaSquareFull, FaSquareRootAlt, FaStickerMule, FaStickyNote, FaStopwatch, FaTicketAlt, FaTimes, FaTimesCircle, FaUserShield, FaWalking, FaWineGlassAlt } from "react-icons/fa";
import { TiCancelOutline } from "react-icons/ti";
import { Context as AuthContext } from "../../context/AuthContext";
import LoaderIntro from "react-spinners/SyncLoader";
import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { clientRoutes } from "../../routes/sidebarRoutes";
import { FaBoxesStacked, FaBoxArchive, FaClockRotateLeft, FaDiagramNext, FaListCheck, FaMobileScreen, FaMobileScreenButton, FaReceipt, FaScreenpal, FaTachographDigital, FaTemperatureQuarter, FaTicket, FaTicketSimple, FaTimeline, FaUpload } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";

import DigikuaButton from "../../components/DigikuaButton/DigikuaButton"

const Invoices = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const [baseUrl, setBaseUrl] = useState("https://space.somoafrica.org/api.php")
  const [baseUrl1, setBaseUrl1] = useState("https://digikua.com/api.php")

  const [phone, setPhone] = useState("")
  const [businessId, setBusinessId] = useState("")
  const [email, setEmail] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [clientName, setClientName] = useState("")
  const [items, setItems] = useState([{ variantId: '', quantity: '', totalAmount: '' }]);

  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [variants, setVariants] = useState([]);
  const [serviceVariants, setServiceVariants] = useState([]);

  const [successCreateInvoice, setSuccessCreateInvoice] = useState(false);

  const [createInvoiceButton, setCreateInvoiceButton] = useState("Send")

  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);

  const handleShowCreateInvoiceModal = () => setShowCreateInvoiceModal(true);
  const handleCloseCreateInvoiceModal = () => setShowCreateInvoiceModal(false);

  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    if (!user) return;

    setPhone(user.phoneNumber);
    setEmail(user.phoneNumber);
  }, [user]);

  useEffect(() => {
    if (email) {
      const formData = new FormData();

      formData.append("readByEmailOrId", email);

      const requestOptions = {
        method: "POST",
        headers: {},
        body: formData,
      };

      fetch(
        `${baseUrl}?resource=business&action=readByEmailOrId`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setBusinessId(data.business[0].id);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("I am not boarding");
    }
  }, [email]);

  useEffect(() => {
    if (!businessId) return;
    const controller = new AbortController();

    const fetchData = async () => {
      setIsLoading(true);
      setError("");

      try {
        const endpoints = [
          `${baseUrl}?resource=entrepreneurProductVariant&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurServiceVariant&action=read&businessId=${businessId}`,
        ];

        const fetchPromises = endpoints.map((url) =>
          fetch(url, { signal: controller.signal }).then((response) => {
            if (!response.ok) {
              throw new Error(`Something went wrong`);
            }
            return response.json();
          })
        );

        const responses = await Promise.all(fetchPromises);

        const allVariants = responses.flatMap(data => data.data);

        setVariants(allVariants);

      } catch (error) {
        if (error.name !== "AbortError") {
          setError(error.message);
          console.log("Error: " + error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [businessId]);


  const incrementItems = () => {
    setItems([...items, { variantId: '', quantity: '', totalAmount: '' }]);
  };

  const removeItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const updateItem = (index, field, value) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setItems(updatedItems);
  };

  const sendInvoice = () => {
    if (!phone) {
      toast.error("Missing important information");
    } else if (!items) {
      toast.error("Add an item");
    } else if (!clientEmail) {
      toast.error("Client email cannot be blank");
    } else if (!clientPhone) {
      toast.error("Client phone cannot be blank");
    } else if (!clientName) {
      toast.error("Client name cannot be blank");
    } else {
      setCreateInvoiceButton(<LoaderIntro color="#fff" />);

      const data = {
        phone: phone,
        items: items,
        clientName: clientName,
        clientPhone: clientPhone,
        clientEmail: clientEmail
      }

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      console.log(data);


      fetch(
        `${baseUrl1}?resource=documents&action=invoice/generate/onthefly`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error === false) {
            setSuccessCreateInvoice(true);
            handleCloseCreateInvoiceModal();
            setClientEmail("");
            setClientPhone("");
            setClientName("");
            setItems([{ variantId: '', quantity: '', totalAmount: '' }])
            setCreateInvoiceButton("Send");
          } else {
            toast.error(data.message);
            setSuccessCreateInvoice(false);
            setCreateInvoiceButton("Send");
          }
        })
        .catch((error) => {
          toast.error(error);
          setSuccessCreateInvoice(false);
          setCreateInvoiceButton("Send");
        });
    }
  };

  useEffect(() => {
    if (successCreateInvoice === true) {
      toast.success("Invoice generated and sent successfully");
      setSuccessCreateInvoice(false);
    }
  }, [successCreateInvoice])

  return (
    <>
      <Dashboard sidebarRoutes={clientRoutes} location={location}>
        <ToastContainer position="top-center" />
        <div className={styles.heading}>
          <nav>
            <Button bgColor={window.location.href.split("/").pop() == 'home' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/invoices/home")}>
              <FaHome /> <span className={styles.btnTxt}>Home</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'invoice' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/invoices/invoice")}>
              <FaFileInvoice /> <span className={styles.btnTxt}>Invoice</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'receipts' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/invoices/receipts")}>
              <FaReceipt /> <span className={styles.btnTxt}>Receipts</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'quotation' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/invoices/quotation")}>
              <FaFileInvoice /> <span className={styles.btnTxt}>Quotation</span>
            </Button>
          </nav>
        </div>
        <Switch>
          <Route path={`${path}/home`}>
            <div className={styles.creditTable} ids="mainWelcomePageHolder" id="myEntrepreneursContainer">
              <div className={styles.welcomeAcc}>
                <h2>DigiKua Business Documents</h2><hr />
                <p>Hi <g id="usr_name_salt">there</g>, <br />
                  Generate and send invoices to your clients in one click.<br />
                  <Button
                    bgColor="#cc6328"
                    size="sm"
                    color="#fff"
                    className={`mt-4 ${styles.btn}`}
                    clicked={handleShowCreateInvoiceModal}
                  >
                    Create Invoice <FaReceipt size="1.4em" />
                  </Button><br />
                </p>
              </div>
            </div>
          </Route>
          <Route path={`${path}/invoice`}>
            <div className={styles.creditTable} ids="mainWelcomePageHolder" id="myEntrepreneursContainer">
              <div className={styles.welcomeAcc}>
                <h2>Invoice</h2><hr />
                <p>
                  Generate and send invoices to your clients in one click.<br />
                  <Button
                    bgColor="#cc6328"
                    size="sm"
                    color="#fff"
                    className={`mt-4 ${styles.btn}`}
                    clicked={handleShowCreateInvoiceModal}
                  >
                    Create Invoice <FaReceipt size="1.4em" />
                  </Button><br />
                </p>
              </div>
            </div>
          </Route>
          <Route path={`${path}/receipts`}>
            <div className={styles.creditTable} ids="mainWelcomePageHolder" id="myEntrepreneursContainer">
              <div className={styles.welcomeAcc}>
                <h2>Receipts</h2><hr />
                <h1 style={{ marginTop: "100px" }}>Coming soon...</h1>
              </div>
            </div>
          </Route>
          <Route path={`${path}/quotation`}>
            <div className={styles.creditTable} ids="mainWelcomePageHolder" id="myEntrepreneursContainer">
              <div className={styles.welcomeAcc}>
                <h2>Quotation</h2><hr />
                <h1 style={{ marginTop: "100px" }}>Coming soon...</h1>
              </div>
            </div>
          </Route>
        </Switch >
      </Dashboard >

      {/* Create Invoice */}
      <Modal show={showCreateInvoiceModal} onHide={handleCloseCreateInvoiceModal}>
        <div className={styles.AddProductModal}>
          <ToastContainer position="top-center" />
          <div className={styles.modalHeader}>
            <h4>
              <FaFileInvoice /> Create Invoice
            </h4>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.addProductForm}>
              <div className="form-group">
                <label for="name">Client Name</label>
                <input
                  type="text"
                  value={clientName}
                  onChange={(e) => {
                    setClientName(e.target.value);
                  }}
                  name="name"
                  className="form-control"
                  id="name"
                />
              </div>

              <div className="form-group">
                <label for="name">Client Phone</label>
                <input
                  type="text"
                  value={clientPhone}
                  onChange={(e) => {
                    setClientPhone(e.target.value);
                  }}
                  name="name"
                  className="form-control"
                  id="name"
                />
              </div>
            </div>
            <div className="form-group">
              <label for="name">Client Email</label>
              <input
                type="email"
                value={clientEmail}
                onChange={(e) => {
                  setClientEmail(e.target.value);
                }}
                name="name"
                className="form-control"
                id="name"
              />
            </div>
            <div className={styles.items}>
              {items.map((item, index) => (
                <div key={index} className={styles.item}>
                  <div className={styles.header}>
                    <h4>Item {index + 1}</h4>
                    {items.length > 1 && (
                      <FaTrashAlt
                        color="#C4233C"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeItem(index)}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor={`variantId-${index}`}>Product/Service Variant</label>
                    <select
                      disabled={variants !== "--Select--" ? false : true}
                      name={`variantId-${index}`}
                      value={item.variantId || "--Select--"}
                      onChange={(e) => updateItem(index, 'variantId', e.target.value)}
                      className="form-control"
                      required=""
                    >
                      <option disabled selected value="--Select--">
                        --Select--
                      </option>

                      {isLoading && (
                        <option disabled value="--Select--">
                          Loading...
                        </option>
                      )}

                      {!isLoading && error && (
                        <option disabled value="--Select--">
                          Error
                        </option>
                      )}

                      {!isLoading && !error &&
                        variants.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor={`quantity-${index}`}>Quantity</label>
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => updateItem(index, 'quantity', e.target.value)}
                      name={`quantity-${index}`}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`totalAmount-${index}`}>Total Amount</label>
                    <input
                      type="number"
                      value={item.totalAmount}
                      onChange={(e) => updateItem(index, 'totalAmount', e.target.value)}
                      name={`totalAmount-${index}`}
                      className="form-control"
                    />
                  </div>
                </div>
              ))}
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#ABB0B8"
                color="#ffffff"
                clicked={incrementItems}
              >
                Add Item
              </DigikuaButton>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <DigikuaButton
              fontSize="1rem"
              size="lg"
              margin=".3rem"
              bgColor="#C4233C"
              color="#fff"
              clicked={handleCloseCreateInvoiceModal}
            >
              Cancel
            </DigikuaButton>
            <DigikuaButton
              fontSize="1rem"
              size="lg"
              margin=".3rem"
              bgColor="#00A5A2"
              color="#fff"
              clicked={sendInvoice}
            >
              {createInvoiceButton}
            </DigikuaButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Invoices;

