import React, { useState, useEffect } from "react";
import styles from "./Scores.module.scss";
import { ReactComponent as Icon } from "../../assets/icons/score.svg";
import { ReactComponent as Pending } from "../../assets/icons/pending.svg";
import { ReactComponent as Overdue } from "../../assets/icons/overdue.svg";
import Button from "../Button/Button";
import { FaChevronRight, FaRegCheckCircle } from "react-icons/fa";
import LoaderIntro from "react-spinners/SyncLoader";

const Scores = ({
  closePanel,
  setClosePanel,
  screenWidth,
  baseUrl,
  traineeId,
  cohortId,
}) => {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");

  const [avgScore, setAvgScore] = useState();

  const getScores = async () => {
    try {
      setLoading(true);
      setErrors("");
      const res = await fetch(
        `${baseUrl}scores/aggregates?cohort_id=${cohortId}&trainee_id=${traineeId}`
      );

      if (!res.ok) throw new Error("Something went wrong with fetching scores");

      const data = await res.json();
      if (data.Response === false) throw new Error("Scores not found.");

      const scoresData = data.data.length > 0 ? data.data[0] : [];
      setScores(scoresData);

      let sum = 0;

      // Check and sum each score if it exists, otherwise ignore it
      if (scoresData.pitch1) sum += parseFloat(scoresData.pitch1);
      if (scoresData.pitch2) sum += parseFloat(scoresData.pitch2);
      if (scoresData.pitchGrowth) sum += parseFloat(scoresData.pitchGrowth);
      if (scoresData.confidence1) sum += parseFloat(scoresData.confidence1);
      if (scoresData.confidence2) sum += parseFloat(scoresData.confidence2);
      if (scoresData.confidenceGrowth)
        sum += parseFloat(scoresData.confidenceGrowth);
      if (scoresData.computerLiteracyGrowth)
        sum += parseFloat(scoresData.computerLiteracyGrowth);
      if (scoresData.businessAcumenGrowth)
        sum += parseFloat(scoresData.businessAcumenGrowth);
      if (scoresData.businessPlan) sum += parseFloat(scoresData.businessPlan);
      if (scoresData.trainer) sum += parseFloat(scoresData.trainer);
      if (scoresData.mentor) sum += parseFloat(scoresData.mentor);
      if (scoresData.baselineSurvey)
        sum += parseFloat(scoresData.baselineSurvey);
      if (scoresData.postTrainingSurvey)
        sum += parseFloat(scoresData.postTrainingSurvey);
      if (scoresData.averageGrowth) sum += parseFloat(scoresData.averageGrowth);

      setAvgScore((sum / 70) * 100);

      setErrors("");
    } catch (error) {
      setErrors(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getScores();
  }, []);

  const getAction = (status) => {
    const statusMap = {
      Pending: { color: "#CC6328", action: "--" },
      Submitted: { color: "#00A5A2", action: "Redo" },
      Overdue: { color: "#C4233C", action: "Locked" },
      Autogenerated: { color: "#000000", action: "Locked" },
    };

    return statusMap[status] || { color: "#000000", action: "Unknown" };
  };

  const getColor = (status) => {
    const statusMap = {
      Locked: { color: "#C4233C", button: false },
      Redo: { color: "#00A5A2", button: true },
    };

    return statusMap[status] || { color: "#000000", action: "Unknown" };
  };

  const getOccurrence = (closePanel, screenWidth) => {
    if (closePanel && screenWidth < 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth < 1200) {
      return ["0%", "none"];
    } else if (closePanel && screenWidth > 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth > 1200) {
      return ["70%", ""];
    }
  };

  return (
    <div
      className={styles.scores}
      style={{
        width: getOccurrence(closePanel, screenWidth)[0],
        display: getOccurrence(closePanel, screenWidth)[1],
      }}
    >
      <div className={styles.topBar}>
        <div className={styles.header}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {closePanel ? (
              <div
                className={styles.openMenu}
                onClick={() => setClosePanel(false)}
              >
                <FaChevronRight size="1rem" color="#cc6328" />
              </div>
            ) : (
              ""
            )}
          </div>
          <Icon
            className={styles.icon}
            style={{ marginLeft: closePanel ? "" : "20px" }}
          />
          <h2>My Scores</h2>
        </div>
      </div>
      <div className={styles.contentBar}>
        <section className={styles.tableBody}>
          <table className="" id="tableId">
            <thead>
              <tr>
                <th>Item</th>
                {/* <th>Due Date</th> */}
                <th>Weight</th>
                <th>Score</th>
                <th>Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr
                  style={{
                    width: "100%",
                    textAlign: "center",
                    height: "30px",
                  }}
                >
                  <LoaderIntro color="#00A5A2" />
                </tr>
              )}
              {!loading && scores && (
                <>
                  <tr className={styles.tableRow}>
                    <td>Pitch 1</td>
                    <td>5</td>
                    <td>
                      {!scores.pitch1 || scores.pitch1 === null
                        ? "--"
                        : scores.pitch1}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.pitch1 || scores.pitch1 === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.pitch1 || scores.pitch1 === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Pitch 2</td>
                    <td>5</td>
                    <td>
                      {!scores.pitch2 || scores.pitch2 === null
                        ? "--"
                        : scores.pitch2}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.pitch2 || scores.pitch2 === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.pitch2 || scores.pitch2 === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Pitch Growth</td>
                    <td>5</td>
                    <td>
                      {!scores.pitchGrowth || scores.pitchGrowth === null
                        ? "--"
                        : scores.pitchGrowth}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.pitchGrowth || scores.pitchGrowth === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.pitchGrowth || scores.pitchGrowth === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Confidence 1</td>
                    <td>5</td>
                    <td>
                      {!scores.confidence1 || scores.confidence1 === null
                        ? "--"
                        : scores.confidence1}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.confidence1 || scores.confidence1 === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.confidence1 || scores.confidence1 === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Confidence 2</td>
                    <td>5</td>
                    <td>
                      {!scores.confidence2 || scores.confidence2 === null
                        ? "--"
                        : scores.confidence2}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.confidence2 || scores.confidence2 === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.confidence2 || scores.confidence2 === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Confidence Growth</td>
                    <td>5</td>
                    <td>
                      {!scores.confidenceGrowth ||
                        scores.confidenceGrowth === null
                        ? "--"
                        : scores.confidenceGrowth}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.confidenceGrowth ||
                            scores.confidenceGrowth === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.confidenceGrowth ||
                        scores.confidenceGrowth === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Computer Literacy Growth</td>
                    <td>5</td>
                    <td>
                      {!scores.computerLiteracyGrowth ||
                        scores.computerLiteracyGrowth === null
                        ? "--"
                        : scores.computerLiteracyGrowth}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.computerLiteracyGrowth ||
                            scores.computerLiteracyGrowth === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.computerLiteracyGrowth ||
                        scores.computerLiteracyGrowth === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Business Acumen Growth</td>
                    <td>5</td>
                    <td>
                      {!scores.businessAcumenGrowth ||
                        scores.businessAcumenGrowth === null
                        ? "--"
                        : scores.businessAcumenGrowth}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.businessAcumenGrowth ||
                            scores.businessAcumenGrowth === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.businessAcumenGrowth ||
                        scores.businessAcumenGrowth === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Business Plan</td>
                    <td>5</td>
                    <td>
                      {!scores.businessPlan || scores.businessPlan === null
                        ? "--"
                        : scores.businessPlan}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.businessPlan || scores.businessPlan === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.businessPlan || scores.businessPlan === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Trainer</td>
                    <td>5</td>
                    <td>
                      {!scores.trainer || scores.trainer === null
                        ? "--"
                        : scores.trainer}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.trainer || scores.trainer === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.trainer || scores.trainer === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Mentor</td>
                    <td>5</td>
                    <td>
                      {!scores.mentor || scores.mentor === null
                        ? "--"
                        : scores.mentor}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.mentor || scores.mentor === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.mentor || scores.mentor === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Baseline Survey</td>
                    <td>5</td>
                    <td>
                      {!scores.baselineSurvey || scores.baselineSurvey === null
                        ? "--"
                        : scores.baselineSurvey}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.baselineSurvey ||
                            scores.baselineSurvey === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.baselineSurvey ||
                        scores.baselineSurvey === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Post Training Survey</td>
                    <td>5</td>
                    <td>
                      {!scores.postTrainingSurvey ||
                        scores.postTrainingSurvey === null
                        ? "--"
                        : scores.postTrainingSurvey}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.postTrainingSurvey ||
                            scores.postTrainingSurvey === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.postTrainingSurvey ||
                        scores.postTrainingSurvey === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className={styles.tableRow}>
                    <td>Average Growth</td>
                    <td>5</td>
                    <td>
                      {!scores.averageGrowth || scores.averageGrowth === null
                        ? "--"
                        : scores.averageGrowth}
                    </td>
                    <td
                      style={{
                        color:
                          !scores.averageGrowth || scores.averageGrowth === null
                            ? "#CC6328"
                            : "#00A5A2",
                      }}
                    >
                      {!scores.averageGrowth ||
                        scores.averageGrowth === null ? (
                        <span className={styles.icons}>
                          <Pending
                            style={{
                              marginRight: "2px",
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                          Pending
                        </span>
                      ) : (
                        <span className={styles.icons}>
                          <FaRegCheckCircle style={{ marginRight: "2px" }} />
                          Submitted
                        </span>
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </section>
        <div className={styles.avgScore}>
          <h4>Score: </h4>
          <Button bgColor="#cc6328" size="sm" color="#fff">
            <span className={styles.btnTxt}>
              {!loading && `${Math.round(avgScore)}%`}

              {loading && <LoaderIntro color="#ffffff" />}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Scores;

// {scores.map((score) => (
//   <tr
// style={{
//   boxShadow:
//     "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);",
// }}
//   >
//     <td>{score.name}</td>
//     {/* <td>{score.dueDate}</td> */}
//     <td>{score.weight}</td>
//     <td>{score.score}</td>
//     <td
//       style={{
//         color: getAction(score.status).color,
//       }}
//     >
//       {score.status === "Submitted" && (
//         <span className={styles.icons}>
//           <FaRegCheckCircle style={{ marginRight: "2px" }} />
//           {score.status}
//         </span>
//       )}
//       {score.status === "Overdue" && (
//         <span className={styles.icons}>
//           <Overdue
//             style={{
//               marginRight: "2px",
//               width: "1rem",
//               height: "1rem",
//             }}
//           />
//           {score.status}
//         </span>
//       )}
//       {score.status === "Pending" && (
//         <span className={styles.icons}>
// <Pending
//   style={{
//     marginRight: "2px",
//     width: "1rem",
//     height: "1rem",
//   }}
// />
//           {score.status}
//         </span>
//       )}
//       {score.status === "Autogenerated" && score.status}
//     </td>
//     {/* <td
//       style={{
//         color: getColor(getAction(score.status).action).color,
//       }}
//     >
//       {getAction(score.status).action === "Redo" && (
//         <span className={styles.button}>
//           {getAction(score.status).action}
//         </span>
//       )}
//       {getAction(score.status).action === "--" &&
//         getAction(score.status).action}
//       {getAction(score.status).action === "Locked" && (
//         <span className={styles.locked}>
//           {getAction(score.status).action}
//         </span>
//       )}
//     </td> */}
//   </tr>
// ))}
