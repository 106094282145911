import React from 'react'

import styles from "./Download.module.scss";

const DownloadCashflow = ({ clicked, icon, format, color, customKey, setHoveredDiv, setIsHovered }) => {
    return (
        <div onClick={clicked} className={styles.downloadButtons} onMouseEnter={() => {
            setHoveredDiv(customKey)
            setIsHovered(true)
        }} onMouseLeave={() => {
            setHoveredDiv()
            setIsHovered(false)
        }}>
            <div>{icon}</div>
            <p style={{ color: color }}>{format}</p>
        </div>
    )
}

export default DownloadCashflow