import React, { useState, useEffect } from "react";
import styles from "./LMSDashboard.module.scss";
import {
  FaRegFile,
  FaBookReader,
  FaRegArrowAltCircleUp,
  FaCalendarAlt,
  FaClock,
  FaArrowRight,
} from "react-icons/fa";
import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import LMSProgress from "../LMSProgress/LMSProgress";
import Button from "../Button/Button";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ReactComponent as Man } from "../../assets/icons/man.svg";
import { ReactComponent as Woman } from "../../assets/icons/woman.svg";
import ProgramDetails from "../ProgramDetails/ProgramDetails";
import LMSAssignments from "../LMSAssignments/LMSAssignments";
import { ThreeDots } from "react-loader-spinner";
import { TiCancelOutline } from "react-icons/ti";

const LMSDashboard = ({
  traineeId,
  cohortId,
  classId,
  lastChapter,
  lastTopic,
  lastTopicName,
  loadingInsights,
  insightsError,
  upscomingAssignments,
  baseUrl,
  setCurrentPath,

  clickedAssignment,
  setClickedAssignment,
  assignmentIndex,
  setAssignmentIndex,
  fromMenu,
  setFromMenu,
  assignmentTitle,
  setAssignmentTitle,
}) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openLastTopic, setOpenLastTopic] = useState(false);
  const [fromDashboard, setFromDashboard] = useState(true);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [closePanel, setClosePanel] = useState(
    screenWidth < 1200 ? true : false
  );

  const [chapterAssignments, setChapterAssignments] = useState([]);
  const [loadingChapterAssignments, setLoadingChapterAssignments] =
    useState(false);
  const [assignmentsError, setAssignmentsError] = useState("");

  const [progress, setProgress] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [progressError, setProgressError] = useState("");

  // const [clickedAssignment, setClickedAssignment] = useState();
  // const [assignmentIndex, setAssignmentIndex] = useState();
  // const [fromMenu, setFromMenu] = useState();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getChapterAssignments = async (chapterId) => {
    try {
      setChapterAssignments([]);
      setLoadingChapterAssignments(true);
      setAssignmentsError("");

      // Fetch assignments
      const resAssignments = await fetch(
        `${baseUrl}assignments?chapter_id=${chapterId}`
      );
      if (!resAssignments.ok)
        throw new Error("Something went wrong with fetching assignments");

      const dataAssignments = await resAssignments.json();
      if (dataAssignments.Response === "False")
        throw new Error("Assignments not found");

      // Fetch scores for each assignment
      const allAssignmentsInfo = await Promise.all(
        dataAssignments.data.map(async (assign) => {
          const resScores = await fetch(
            `${baseUrl}assignments/${assign.id}/submits?trainee_id=${traineeId}`
          );
          if (!resScores.ok)
            throw new Error("Something went wrong with fetching score");

          const dataScores = await resScores.json();

          // Check if there are no submissions
          if (dataScores.count === 0 || dataScores.data.length === 0) {
            return { ...assign, score: "notAttempted" };
          }

          // Extract scores from the submissions
          const scores = dataScores.data
            .map((item) => item.score)
            .filter((score) => score !== null);

          // If there are scores, return the highest one
          const highestScore = scores.length > 0 ? Math.max(...scores) : null;
          return { ...assign, score: highestScore };
        })
      );

      setChapterAssignments(allAssignmentsInfo);
      setAssignmentsError("");
    } catch (error) {
      console.error("Error:", error);
      setAssignmentsError(error.message);
    } finally {
      setLoadingChapterAssignments(false);
    }
  };

  const convertUnixTimestamp = (unixTimestamp) => {
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);
    return dateObject.toLocaleDateString("en-GB");
  };

  const addDaysToUnixTimestamp = (unixTimestamp, days) => {
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);
    dateObject.setDate(dateObject.getDate() + days);
    return dateObject.toLocaleDateString("en-GB");
  };

  const getProgress = async () => {
    try {
      setProgress([]);
      setLoadingProgress(true);
      setProgressError("");
      const res = await fetch(
        `${baseUrl}assignments/progress?trainee_id=${traineeId}`
      );

      if (!res.ok)
        throw new Error("Something went wrong with fetching progress");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Progress not found");

      const progressData = data.data;
      setProgress([
        {
          name: "Completed",
          value: progressData.submitted,
          percentage: progressData.percent.toFixed(2),
        },
        {
          name: "Pending",
          value: progressData.total - progressData.submitted,
          percentage: (100 - progressData.percent).toFixed(2),
        },
      ]);
      setLoadingProgress(false);
      setProgressError("");
    } catch (error) {
      setProgressError(error.message);
    } finally {
      setLoadingProgress(false);
    }
  };

  useEffect(() => {
    getProgress();
  }, []);

  useEffect(() => {
    if (!lastChapter) return;
    getChapterAssignments(lastChapter);
    getChapterAssignments(4);
  }, [lastChapter]);

  return (
    <Switch>
      <Route path={path}>
        <div className={styles.dashboard}>
          <div className={styles.welcomeAcc}>
            <h2>Welcome to Somo Learning Management System</h2>
            <hr />
          </div>
          <div className={styles.content}>
            <div className={styles.leftPanel}>
              <div className={styles.progressCont}>
                <div className={styles.progressTitle}>
                  <div></div>
                  <h5>Progress</h5>
                </div>
                <div className={styles.progressButton}>
                  <div className={styles.progress}>
                    {loadingProgress && (
                      <div className={styles.loader}>
                        <p>
                          <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="#00A5A2"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </p>
                      </div>
                    )}
                    {!loadingProgress && progressError && (
                      <>
                        <div className={styles.errorQuestions}>
                          <TiCancelOutline
                            className={styles.mobileNoScores}
                            size="5em"
                            color="rgba(116, 23, 99, 0.6)"
                          />
                          <p>{progressError}</p>
                        </div>
                      </>
                    )}
                    {!loadingProgress && !progressError && (
                      <LMSProgress progress={progress} />
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.continueLearning}>
                <h5 className={styles.title}>Resume your learning journey</h5>
                <div
                  className={styles.whereStopped}
                  onClick={() => setCurrentPath("learning")}
                >
                  <div className={styles.type}>
                    <FaRegFile size="3rem" />
                  </div>
                  <div className={styles.learningTitle}>
                    {loadingInsights && <h5>Loading...</h5>}
                    {!loadingInsights && !insightsError && (
                      <>
                        <h5>{lastTopicName}</h5>
                      </>
                    )}
                    {!loadingInsights && insightsError && (
                      <>
                        <h5>{insightsError}</h5>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.assignmentsCont}>
                <div className={styles.assignmentsTitle}>
                  <h5 className={styles.title}>Assignments</h5>
                  <div className={styles.seeAllAssignments}>
                    <p
                      onClick={() => {
                        setCurrentPath("learning/assignments");
                      }}
                    >
                      See All
                    </p>
                    <FaArrowRight />
                  </div>
                </div>
                <div className={styles.assignments}>
                  {loadingChapterAssignments && (
                    <div className={styles.loader}>
                      <p>
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#00A5A2"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </p>
                    </div>
                  )}
                  {!loadingChapterAssignments && assignmentsError && (
                    <>
                      <div className={styles.errorQuestions}>
                        <TiCancelOutline
                          className={styles.mobileNoScores}
                          size="5em"
                          color="rgba(116, 23, 99, 0.6)"
                        />
                        <p>{assignmentsError}</p>
                      </div>
                    </>
                  )}
                  {!loadingChapterAssignments &&
                    !assignmentsError &&
                    chapterAssignments.length < 1 && (
                      <>
                        <div
                          className={styles.errorQuestions}
                          style={{ marginTop: "40px" }}
                        >
                          <TiCancelOutline
                            className={styles.mobileNoScores}
                            size="5em"
                            color="rgba(116, 23, 99, 0.6)"
                          />
                          <p>No assignments found, check back later.</p>
                        </div>
                      </>
                    )}
                  {!loadingChapterAssignments &&
                    !assignmentsError &&
                    chapterAssignments.length > 0 &&
                    chapterAssignments.map((assignment, index) => (
                      <div
                        className={styles.assignment}
                        style={{
                          display:
                            assignment.score !== "notAttempted" && "none",
                        }}
                        onClick={() => {
                          setCurrentPath(
                            `learning/assignments/${assignment.id}`
                          );
                          setClickedAssignment(assignment.id);
                          setAssignmentIndex(index + 1);
                          setFromMenu(true);
                          setAssignmentTitle(assignment.title);
                        }}
                      >
                        <FaBookReader
                          size="3em"
                          color="#cc6328"
                          className={styles.icon}
                        />
                        <div className={styles.advisorListInfo}>
                          <span>{assignment.title}</span>
                          <span>
                            Added: {convertUnixTimestamp(assignment.createdAt)}
                          </span>
                          <span>
                            Due:{" "}
                            {addDaysToUnixTimestamp(
                              assignment.createdAt,
                              assignment.periodInDays
                            )}
                          </span>
                          <span>
                            Score: {assignment.score === "notAttempted" && "--"}
                          </span>
                          <button
                            className={styles.button}
                            style={{ backgroundColor: "#C4233C3a" }}
                          >
                            {assignment.score === "notAttempted" &&
                              "Not Attempted"}
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={styles.rightPanel}>
              <div className={styles.calendarCont}>
                {/* <h5>Schedule</h5> */}
                <div className={styles.calendar}>
                  <Calendar
                    className={styles.myCalendar}
                    onChange={() => setSelectedDate}
                  />
                </div>
              </div>
              <div className={styles.UpcomingCont}>
                <div className={styles.class}>
                  <div className={styles.topic}>
                    <FaRegArrowAltCircleUp
                      className={styles.openMeeting}
                      size="1.2rem"
                    />
                    <p style={{ fontWeight: "bold" }}>Supply and Demand</p>
                  </div>
                  <div className={styles.timeInfo}>
                    <h5>Starting</h5>
                    <div className={styles.time}>
                      <div className={styles.detailedTime}>
                        <FaCalendarAlt />
                        <p>Friday, 31 May 2024</p>
                      </div>
                      <div className={styles.detailedTime}>
                        <FaClock />
                        <p>2pm - 4pm EAT</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.class}>
                  <div className={styles.topic}>
                    <FaRegArrowAltCircleUp
                      className={styles.openMeeting}
                      size="1.2rem"
                    />
                    <p style={{ fontWeight: "bold" }}>Supply and Demand</p>
                  </div>
                  <div className={styles.timeInfo}>
                    <h5>Starting</h5>
                    <div className={styles.time}>
                      <div className={styles.detailedTime}>
                        <FaCalendarAlt />
                        <p>Friday, 31 May 2024</p>
                      </div>
                      <div className={styles.detailedTime}>
                        <FaClock />
                        <p>2pm - 4pm EAT</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.class}>
                  <div className={styles.topic}>
                    <FaRegArrowAltCircleUp
                      className={styles.openMeeting}
                      size="1.2rem"
                    />
                    <p style={{ fontWeight: "bold" }}>Supply and Demand</p>
                  </div>
                  <div className={styles.timeInfo}>
                    <h5>Starting</h5>
                    <div className={styles.time}>
                      <div className={styles.detailedTime}>
                        <FaCalendarAlt />
                        <p>Friday, 31 May 2024</p>
                      </div>
                      <div className={styles.detailedTime}>
                        <FaClock />
                        <p>2pm - 4pm EAT</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Route>
      <Route path={`${path}/learning`}>
        <ProgramDetails
          traineeId={traineeId}
          cohortId={cohortId}
          lastChapter={lastChapter}
          lastTopic={lastTopic}
          baseUrl={baseUrl}
          fromDashboard={fromDashboard}
          setFromDashboard={setFromDashboard}
          clickedAssignment={clickedAssignment}
          setClickedAssignment={setClickedAssignment}
          assignmentIndex={assignmentIndex}
          setAssignmentIndex={setAssignmentIndex}
          fromMenu={fromMenu}
          setFromMenu={setFromMenu}
          assignmentTitle={assignmentTitle}
          setAssignmentTitle={setAssignmentTitle}
        />
      </Route>
      {/* <Route path={`${path}learning/assignments`}>
        <LMSAssignments
          closePanel={closePanel}
          setClosePanel={setClosePanel}
          screenWidth={screenWidth}
          baseUrl={baseUrl}
          fromMenu={fromMenu}
          menuAssignmentId={clickedAssignment}
          menuIndex={assignmentIndex}
        />
      </Route> */}
    </Switch>
  );
};

export default LMSDashboard;
