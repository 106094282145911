import React, { useRef, useEffect, useContext } from "react";
import styles from "./OnboardInvestOverviewForm.module.scss";
import Button from "../Button/Button";
import FileUploadButton from "../FileUploadButton/FileUploadButton";
import { FaCloudUploadAlt } from "react-icons/fa";
import InputField from "../../components/InputField/InputField";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
import { toast, ToastContainer } from 'react-toastify';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Context as AuthContext } from "../../context/AuthContext";

const OnboardInvestOverviewForm = ({ submit }) => {
  const passportFileRef = useRef();
  var loading = false;
  const history = useHistory();
  const {
    state: { user },
  } = useContext(AuthContext);
  useEffect(()=>{
    
  });

  const handleSubmit = () => {
    try{ var onboardInfo = JSON.parse(localStorage.getItem('AccSelfOnboard')); } catch(er){ var onboardInfo = {}; }
    // console.log('>>file', passportFile, onboardInfo);
    var loadingOff = document.getElementById('loading-off');
    var loadingOn = document.getElementById('loading-on');
    var data = document.getElementById('currentFormInput').value;
    if(!data){
      toast.error("Select your business overview to continue");
      return 0;
    }
    loadingOff.style.display = 'none';
    loadingOn.style.display = 'initial';

    submitUserInput(user.user_id, data);

    function submitUserInput(usrId, data){
      var directionInfo = {here:'investOverview', next:"/dashboard/onboarding/apply/invest_video"};
      var y = new XMLHttpRequest();
      y.onload = function(){
        try{
          var a = JSON.parse(y.responseText);
          if(a.error){
            console.log('load Error > :3', y.responseText);
            handelPropResp(false, y.responseText);
          } else {
            handelPropResp(true, a.data);
          }
        } catch(er){
          console.log('load Error > :2', y.responseText, er);
          handelPropResp(false, y.responseText);
        }
      };
      y.onerror = function(){
        console.log('load Error > :1', y.responseText);
        handelPropResp(false, y.responseText);
      };
      y.open('POST', globalVars.baseUrl + globalVars.addOnboardUrl.replace(':user_id', usrId));
      y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      y.setRequestHeader("Content-Type", "application/json");
      y.send(JSON.stringify({step:directionInfo.here, value:data}));

      function handelPropResp(status, resp){
        if(!status){
          var err = (typeof resp == 'string' ? JSON.parse(resp) : false);
          resp = err ? err : resp;
          console.log('Error>>');
          loadingOff.style.display = 'initial';
          loadingOn.style.display = 'none';
          toast.error(resp.error ? resp.error : "Failed to save progress, Try again");
          return 0;
        } else {
          console.log(resp);
          history.push(directionInfo.next);
        }
        
      }
    }
    // submit(idFile, passportFile, idType);
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.onboardPassportForm}>
      <div className={styles.passportVerify}>
        <h3>Somo Invest Overview</h3>
        <p className={styles.info}>
          Write a description of what your business does. The description will be used on the Somo Investment Dashboard to help you get more investors.
          Check out <a href="https://invest.somoafrica.org" target="_">Somo Invest (somoafrica.org/invest)</a> for more insights.<br/>
        </p>
        <form id="proposalUploadForm">
          <textarea id="currentFormInput" className={styles.formInput} placeholder="Enter your Somo invest overview" rows="9"></textarea>
          {/* <InputField
            type="textarea"
            nameAttr="lastName"
            label="Last Name"
            // value={signUpValues.lastName}
            handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
          /> */}
        </form>
      </div>
      <Button
        className="mt-4"
        fullWidth
        bgColor="#cc6328"
        size="lg"
        color="#EBEBEB"
        clicked={handleSubmit}
        disabled={loading}
      loading={loading}
      >
        <span id="loading-off">Save & Continue</span>
        <span id="loading-on" style={{display:"none"}}><Loader/></span>
      </Button>
      <i><hr/>*If your submission does not meet acceptabled standards, it will be rejected and you'll have to re-submit.</i>
    </div>
  );
};

export default OnboardInvestOverviewForm;
