import React from "react";
import styles from "./ScoresAverage.module.scss";
import LoaderIntro from "react-spinners/SyncLoader";

const ScoresAverage = ({ averageScore, rank }) => {
  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.buttoncontainer}>
        <div>
          <label for="quarter">Average Score:</label>
        </div>
        <button className={styles.button}>
          {averageScore ? averageScore : "Loading..."}
        </button>
      </div>

      <div className={styles.buttoncontainer}>
        <div>
          <label for="quarter">Rank:</label>
        </div>
        <button className={styles.button}>
          {rank ? rank : <LoaderIntro color="#FFFFFF" />}
        </button>
      </div>
    </div>
  );
};

export default ScoresAverage;
