import React, { useState, useEffect, useContext } from 'react'
import styles from "./Scorecard.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import { clientRoutes } from "../../routes/sidebarRoutes";
import { Context as AuthContext } from "../../context/AuthContext";
import {
    useHistory,
    useLocation,
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import {
    FaHandshake,
    FaRegArrowAltCircleUp,
    FaHourglassStart,
    FaSitemap,
    FaCogs,
    FaRegLightbulb,
    FaChartArea,
    FaExclamationCircle
} from "react-icons/fa";

import Legend from "../../components/ScorecardLegend/ScoreCardLegend";
import ScorePie from '../../components/ScorePie/ScorePie';
import ScoreMetric from '../../components/ScoreMetric/ScoreMetric';

import { ThreeDots } from "react-loader-spinner";
import { forEach } from 'lodash';


const Scorecard = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [businessId, setBusinessId] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [businessStatus, setBusinessStatus] = useState();
    const [averageScore, setAverageScore] = useState("");
    const [hovered, setHovered] = useState("");
    const [baseUrl, setBaseUrl] = useState("https://space.somoafrica.org/api.php");
    const [baseUrl1, setBaseUrl2] = useState("https://space.somoafrica.org/api.php");

    const [scoresError, setScoresError] = useState("");
    const [isLoadingScores, setIsLoadingScores] = useState(true);
    const [scoresData, setScoresData] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [link, setLink] = useState("");

    const {
        state: { user },
    } = useContext(AuthContext);

    useEffect(() => {
        if (!user) return;

        setEmail(user.email);
    }, [user]);

    useEffect(() => {
        if (email) {
            const formData = new FormData();

            formData.append("readByEmailOrId", email);

            const requestOptions = {
                method: "POST",
                headers: {},
                body: formData,
            };

            fetch(
                `${baseUrl1}?resource=business&action=readByEmailOrId`,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    setBusinessId(data.business[0].id);
                    setBusinessName(data.business[0].displayName);
                    setBusinessStatus(data.business[0].enabled);
                    setUserId(data.data[0].userId);
                    setName(data.data[0].user_name.split(" ")[0]);
                })
                .catch((error) => {
                    setScoresError(error)
                    setIsLoadingScores(false)
                });
        } else {
            console.log("I am not boarding");
        }
    }, [email]);

    function getMonthNameFromDate(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);
        return date.toLocaleString('default', { month: 'long' });
    }

    const getScores = async () => {
        try {
            setScoresData([]);
            setIsLoadingScores(true);
            setScoresError("");

            const requestOptions = {
                method: "POST",
                headers: {},
                body: JSON.stringify(),
            };

            const url = `${baseUrl}?resource=scorecard_v2&action=read&businessId=${businessId}`;
            const res = await fetch(url, requestOptions);

            if (!res.ok) {
                throw new Error("Something went wrong with fetching scores");
            }

            const data = await res.json();
            if (data.Response === "False") {
                throw new Error("Scores not found");
            }
            if (data.data) {
                setScoresData(data.data);
                setAverageScore(data.data[0].data.percent < 1 ? 0 : data.data[0].data.percent)
                setScoresError("");
                setIsLoadingScores(false);
            }
        } catch (error) {
            setScoresError(error);
            setIsLoadingScores(false);
        } finally {
            setIsLoadingScores(false);
        }
    }

    useEffect(() => {
        if (!businessId) return;
        getScores();
    }, [businessId])

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!link) return;
        viewMetric(link)
    }, [link])

    const viewMetric = (link) => {
        const data = metrics.find(metric => metric.link === link)
        setSelectedData(data);
        history.push(`${path}/${link}`);
    }

    const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const getFormattedDates = (dateArray) => {
        const formattedArray = dateArray.map(date => {
            const [year, month] = date.split(':');
            return `${year} - ${monthAbbreviations[parseInt(month, 10) - 1]}`;
        });

        return formattedArray
    }

    const getFormattedData = (data, type) => {
        const finalData = [];
        data.forEach((dt) => finalData.push(
            type === "operating" ? dt.operatingProfit : type === "debt" ? dt.netProfit : dt
        ))
        return finalData;
    }


    const loading = (height, width, radius, color) => {
        return <ThreeDots
            visible={true}
            height={height}
            width={width}
            color={color}
            radius={radius}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    }

    const error = () => {
        return <span style={{ fontSize: "1rem", display: "flex", alignItems: "center", color: "#C4233C" }}><FaExclamationCircle size="1rem" style={{ marginRight: "5px" }} />An error occurred</span>
    }

    const metrics = [
        {
            index: 0,
            link: "business-risk",
            key: "businessRisk",
            name: "Business Risk",
            score: scoresData[0]?.businessRisk?.data.percent,
            icon: <FaChartArea size="2em" />,
            iconColor: "#851122",
            color: "#C4233C",
            desc: "The likelihood that a company's operations or business model will fail to generate sufficient cash flow to meet its financial obligations.",
            metrics: [
                {
                    index: 0,
                    name: "Operating margins",
                    desc: "Measure of how much money a business makes on its products/services after subtracting all direct and indirect costs.",
                    chart: "bar",
                    yLabel: "Profit",
                    labels: scoresData[0]?.businessRisk?.operatingProfitMargin?.report
                        ? getFormattedDates(Object.keys(scoresData[0]?.businessRisk?.operatingProfitMargin?.report))
                        : [],
                    data: scoresData[0]?.businessRisk?.operatingProfitMargin?.report
                        ? getFormattedData(Object.values(scoresData[0]?.businessRisk?.operatingProfitMargin?.report), "operating")
                        : [],
                    seeMore: "/dashboard/digikua/client/cashflow-summary"
                },
                {

                    index: 1,
                    name: "Validation of cash flows",
                    desc: "Measure of the percentage discrepancy between the cash flows reported by the entrepreneur and the mobile and bank statements.",
                    chart: "halfdoughnut",
                    segments: 4,
                    legend: [
                        {
                            color: '#00A5A2',
                            title: "No discrepancy"
                        },
                        {
                            color: '#FFC83B',
                            title: "Greater than 2% discrepancy"
                        },
                        {
                            color: '#CC6328',
                            title: "Greater than 5% discrepancy"
                        },
                        {
                            color: '#C4233C',
                            title: "Greater than 9% discrepancy"
                        }
                    ], customSegments:
                        [
                            {
                                text: '-1',
                                position: 'OUTSIDE'
                            },
                            {
                                text: '0', position: 'OUTSIDE'
                            },
                            {
                                text: '1', position: 'OUTSIDE'
                            },
                            {
                                text: '2', position: 'OUTSIDE'
                            }
                        ],
                    segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'],
                    segmentStops: [-2, -1, 0, 1, 2, 3],
                    value: isLoadingScores ? 0 : scoresData[0]?.businessRisk?.cashFlowValidation.score + 2.5
                },
                {
                    index: 2,
                    name: "Timeliness of reporting cash flows",
                    desc: "Reliability of client data submissions.",
                    chart: "halfdoughnut",
                    segments: 3,
                    legend: [
                        {
                            color: '#00A5A2',
                            title: "Never missed a submission in the last 3 months"
                        },
                        {
                            color: '#FFC83B',
                            title: "Turned in some cash flow in the last 3 months"
                        }, {
                            color: '#C4233C',
                            title: "Did not turn in cash flow at all in the last 3 months"
                        }
                    ], customSegments:
                        [
                            {
                                text: '0',
                                position: 'OUTSIDE'
                            },
                            {
                                text: '1', position: 'OUTSIDE'
                            },
                            {
                                text: '2', position: 'OUTSIDE'
                            }
                        ],
                    segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                    segmentStops: [-1, 0, 1, 2, 3],
                    value: isLoadingScores ? 0 : scoresData[0]?.businessRisk?.timelinessReportingCashFlow.score + 1.5
                },
                {
                    index: 3,
                    name: "Debt service cover ratio",
                    chart: "bar",
                    yLabel: "Profit",
                    labels: scoresData[0]?.businessRisk?.debtServiceCoverRatio?.report
                        ? getFormattedDates(Object.keys(scoresData[0].businessRisk.debtServiceCoverRatio.report))
                        : [],
                    data: scoresData[0]?.businessRisk?.debtServiceCoverRatio?.report
                        ? getFormattedData(Object.values(scoresData[0]?.businessRisk?.debtServiceCoverRatio?.report), "debt")
                        : [],
                    desc: "Measure of available cash flow to pay current debt obligations = Net income ÷ monthly installment.",
                    seeMore: "/dashboard/digikua/client/cashflow-summary"
                },
                {
                    index: 4,
                    name: "Licenses/permits",
                    type: "permits",
                    chart: "licenses",
                    segments: 2,
                    desc: "Availability of county licenses and industry permits.",
                    data: scoresData[0]?.businessRisk?.licensesOrPermits.report,
                    altData: [
                        { name: "Single Business Permit", status: 0, val: "--" },
                        { name: "Health Trade License", status: 0, val: "--" },
                        { name: "Food and Beverage License", status: 0, val: "--" },
                        { name: "Fire Safety Certificate", status: 0, val: "--" }
                    ],
                    legend:
                        [
                            {
                                color: '#00A5A2', title: "Has licenses"
                            },
                            {
                                color: '#C4233C', title: "Does not have licenses"
                            }
                        ],
                    customSegments:
                        [
                            {
                                text: '-1', position: 'OUTSIDE'
                            },
                            {
                                text: '1', position: 'OUTSIDE'
                            },
                        ],
                    segmentColors: ['#C4233C', '#00A5A2'],
                    segmentStops: [-1, 0, 1], value: 0.5
                },
                {
                    index: 5,
                    name: "Inventory to installment ratio",
                    chart: "bar",
                    yLabel: "Inventory",
                    labels: scoresData[0]?.businessRisk?.collateralAbility?.report
                        ? getFormattedDates(Object.keys(scoresData[0].businessRisk.collateralAbility.report))
                        : [],
                    data: scoresData[0]?.businessRisk?.collateralAbility?.report
                        ? getFormattedData(Object.values(scoresData[0]?.businessRisk?.collateralAbility?.report), "collateral")
                        : [],
                    desc: "Measure the ability to repay the installment upon sale of the available inventory."
                },
                {

                    index: 6,
                    name: "Monthly average cash flow-to-loan amount ratio",
                    desc: "Profit over the last 3 months divided by the loan amount applied for.",
                    chart: "halfdoughnut",
                    segments: 3,
                    legend: [
                        {
                            color: '#00A5A2',
                            title: "Greater than 2 times the loan amount"
                        },
                        {
                            color: '#FFC83B',
                            title: "Exactly 2 times the loan amount"
                        }, {
                            color: '#C4233C',
                            title: "Less than 2 times the loan amount"
                        }
                    ],
                    segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                    segmentStops: [-2, -1, 0, 1, 2],
                    value: isLoadingScores ? 0 : scoresData[0]?.businessRisk?.collateralAbility.score + 2.5
                },
            ]
        },
        {
            index: 1,
            link: "investment-risk",
            name: "Investment Risk",
            score: scoresData[0]?.investmentRisk?.data.percent,
            icon: <FaRegLightbulb size="2em" />,
            iconColor: "#c99a22",
            color: "#FFC83B",
            desc: "The potential for an investor or funder to experience financial losses or receive lower returns than expected due to various factors.",
            metrics: [{
                index: 0, name: "Loan term", chart: "halfdoughnut", segments: 3, desc: "The number of months to repayment for a specific loan or credit facility.",
                legend: [{ color: '#00A5A2', title: "Less than 18 months" },
                { color: '#FFC83B', title: "18 to 35 months" },
                { color: '#C4233C', title: "Greater than 35 months" }],
                customSegments: [{ text: '3', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }],
                segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                segmentStops: [0, 1, 2, 3, 4],
                value: isLoadingScores ? 0 : scoresData[0]?.investmentRisk?.loanTenure.score + 0.5
            }, {
                index: 1, name: "Owner’s contribution toward a project", chart: "halfdoughnut", segments: 4, desc: "Entrepreneur's contribution to support a specific project or funding.",
                legend: [{ color: '#00A5A2', title: "Deposit less than or equal to 50%" }, { color: '#FFC83B', title: "Deposit less than 30%" }, { color: '#CC6328', title: "Deposit less than 20%" }, { color: '#C4233C', title: "Deposit 10%" }],
                customSegments: [{ text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }, { text: '3', position: 'OUTSIDE' }, { text: '4', position: 'OUTSIDE' }],
                segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'],
                segmentStops: [0, 1, 2, 3, 4, 5],
                value: isLoadingScores ? 0 : scoresData[0]?.investmentRisk?.ownContribution.score + 0.5
            }]
        },
        {
            index: 2,
            link: "credit-risk",
            name: "Credit Risk",
            score: scoresData[0]?.creditRisk?.data.percent,
            icon: <FaSitemap size="2em" />,
            iconColor: "#b55a25",
            color: "#CC6328",
            desc: "The likelihood that the borrower will fail to fulfill their payment obligations, whether partially or entirely.",
            metrics: [{
                index: 0, name: "Loan repayment", chart: "halfdoughnut", segments: 3, desc: "The pattern of payment by the entrepreneur during the loan tenure.",
                legend: [{ color: '#00A5A2', title: "Spent between 0 to 30 days or paid in advance" }, { color: '#FFC83B', title: "Not a previous Somo entrepreneur" }, { color: '#C4233C', title: "Spent over 90 days at risk" }],
                customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }],
                segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                segmentStops: [-1, 0, 1, 2],
                value: isLoadingScores ? 0 : scoresData[0]?.creditRisk?.loanRepayment.score + 1.5
            }, {
                index: 1, name: "Credit reference bureau", chart: "halfdoughnut", segments: 3, desc: "A score based on rating provided by a regulated Credit Risk Bureau (CRB) for defaulters.",
                legend: [{ color: '#00A5A2', title: "Clean CRB record and evidence of several loans repaid successfully" }, { color: '#FFC83B', title: "No CRB record/If the amount is less than Kes.300,000/=" }, { color: '#C4233C', title: "Negative CRB rating" }],
                customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }],
                segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                segmentStops: [-1, 0, 1, 2],
                value: isLoadingScores ? 0 : scoresData[0]?.creditRisk?.creditReferenceBureau.score + 1.5
            }, {
                index: 2, name: "Suppliers reference", chart: "halfdoughnut", segments: 4, desc: "An evaluation of the number of suppliers an entrepreneur has defaulted on.",
                legend: [{ color: '#00A5A2', title: "2 or more suppliers say no default" }, { color: '#FFC83B', title: "1 supplier says no default" }, { color: '#CC6328', title: "1 supplier says yes to default" }, { color: '#C4233C', title: "2 suppliers say yes to default" }],
                customSegments: [{ text: '-2', position: 'OUTSIDE' }, { text: '-1', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }],
                segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'],
                segmentStops: [-2, -1, 0, 1, 2, 3],
                value: isLoadingScores ? 0 : scoresData[0]?.creditRisk?.suppliersReference.score + 2.5
            }, {
                index: 3, name: "Overtaker/irrevocable undertaking", chart: "halfdoughnut", segments: 2, desc: "Current contracts from contractors or suppliers, along with the payment schedule for amounts paid directly to Somo.",
                legend: [{ color: '#00A5A2', title: "Has business contracts from a contractor" }, { color: '#C4233C', title: "Does not have business contracts from a contractor" }],
                customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#00A5A2'],
                segmentStops: [0, 1, 2],
                value: isLoadingScores ? 0 : scoresData[0]?.creditRisk?.offtakerUndertaking.score + 0.5
            }]
        },
        {
            index: 3,
            link: "performance-risk",
            name: "Performance Risk",
            score: scoresData[0]?.performanceRisk?.data.percent,
            icon: <FaCogs size="2em" />,
            iconColor: "#017674",
            color: "#00A5A2",
            desc: "Likelihood that the project will fail to achieve its intended goals or deliverables as a result of the entrepreneur’s performance.",
            metrics: [{
                index: 0, name: "Succession plan", chart: "halfdoughnut", segments: 3, desc: "A designated individual who is trained to manage the business when the owner is unavailable.",
                legend: [{ color: '#00A5A2', title: "Strong succession plan in place" }, { color: '#FFC83B', title: "No succession plan but key person dependency is not significant" }, { color: '#C4233C', title: "No succession plan and high key person dependency is significant " }],
                customSegments: [{ text: '-2', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                segmentStops: [-2, -1, 0, 1, 2, 3],
                value: isLoadingScores ? 0 : scoresData[0]?.performanceRisk?.successionPlanning.score + 2.5
            }, {
                index: 1, name: "Experience skills", chart: "halfdoughnut", segments: 3, desc: "Number of years operating the business.",
                legend: [{ color: '#00A5A2', title: "Business operated for more than 5 years" }, { color: '#FFC83B', title: "Business operated between 1-5 years" }, { color: '#C4233C', title: "Business operated for less than 1 year" }],
                customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }],
                segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                segmentStops: [0, 1, 2, 3, 4],
                value: isLoadingScores ? 0 : scoresData[0]?.performanceRisk?.experienceSkills.score + 0.5
            }, {
                index: 2,
                name: "Period in Somo",
                chart: "halfdoughnut",
                segments: 3,
                desc: "Tenure in the Somo program.",
                legend: [
                    { color: '#00A5A2', title: "Less than 6 months" },
                    { color: '#FFC83B', title: "6 - 12 months" },
                    { color: '#C4233C', title: "Been in Somo for more than 12 months" }],
                customSegments: [
                    { text: '0', position: 'OUTSIDE' },
                    { text: '1', position: 'OUTSIDE' },
                    { text: '2', position: 'OUTSIDE' }],
                segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                segmentStops: [-1, 0, 1, 2, 3],
                value: isLoadingScores ? 0 : scoresData[0]?.performanceRisk?.periodWithinSomo.score + 1.5
            }]
        },
        {
            index: 4,
            link: "social-data-via-loan-proposal",
            name: "Social Data",
            score: scoresData[0]?.socialDataViaLoanProposal?.data.percent,
            icon: <FaHourglassStart size="2em" />,
            iconColor: "#141433",
            color: "#212153",
            desc: "Social data from the community.",
            metrics: [
                {
                    index: 0, name: "Cluster counter guarantee", chart: "halfdoughnut", segments: 3, desc: "Guarantee of the facility by at least one peer member within the same group under Somo grouping.",
                    legend: [{ color: '#00A5A2', title: "Guarantee of the facility by atleast 1 or more peer members" }, { color: '#FFC83B', title: "Guarantee of the facility by atleast 1 peer member" }, { color: '#C4233C', title: "No guaranter" }],
                    customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }],
                    segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                    segmentStops: [0, 1, 2, 3],
                    value: isLoadingScores ? 0 : scoresData[0]?.socialDataViaLoanProposal?.guarantors.score + 0.5
                },
                {
                    index: 1, name: "Leadership in Church/Groups/Chamas", chart: "halfdoughnut", segments: 2, desc: "The entrepreneur is a leader to the community outside the Somo community.",
                    legend: [{ color: '#00A5A2', title: "Is a leader  to the community outside the Somo community" }, { color: '#C4233C', title: "Is not a leader  to the community outside the Somo community" }],
                    customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }],
                    segmentColors: ['#C4233C', '#00A5A2'],
                    segmentStops: [0, 1, 2],
                    value: isLoadingScores ? 0 : scoresData[0]?.socialDataViaLoanProposal?.leadership.score + 0.5
                },
                {
                    index: 2, name: "Business coach score", chart: "halfdoughnut", segments: 3, desc: "The team leader's rating of the entrepreneur’s commitment.",
                    legend: [{ color: '#00A5A2', title: "Met with coach three times or more in the quarter " }, { color: '#FFC83B', title: "Met with coach less than three times in the quarter" }, { color: '#C4233C', title: "Did not meet with coach during the quarter" }],
                    customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }],
                    segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'],
                    segmentStops: [0, 1, 2, 3],
                    value: isLoadingScores ? 0 : scoresData[0]?.socialDataViaLoanProposal?.commitment.score + 0.5
                },
                {
                    index: 3,
                    name: "Playbook advisory score",
                    chart: "licenses",
                    type: "playbook",
                    segments: 2,
                    data: scoresData[0]?.businessRisk?.playbookAdvisory.report,
                    altData: [
                        { name: "Key Business Building Blocks", status: 0, val: "" },
                        { name: "Market Access Playbook", status: 0, val: "" },
                        { name: "Branding & Marketing Playbook", status: 0, val: "" },
                        { name: "Innovation", status: 0, val: "" },
                        { name: "Women's Playbook", status: 0, val: "" }
                    ],
                    desc: "How often the client has received coaching services.",
                }
            ]
        },
        {
            index: 5,
            link: "impact-data-via-digikua",
            name: "Impact Data",
            score: scoresData[0]?.impactDataViaDigiKua?.data.percent,
            icon: <FaHandshake size="2em" />,
            iconColor: "#851122",
            color: "#C4233C",
            desc: "Client is scored based on the impact they have had in the society.",
            metrics: [{
                index: 0, name: "Women employed", chart: "pie", segments: 4, desc: "The percentage of women currently employed by the entrepreneur.",
                legend: [{ color: '#00A5A2', title: "Women" }, { color: '#212153', title: "Men" }], tooltip: ["Women", "Men"], segmentColors: ['#00A5A2', '#212153'], value: scoresData[0]?.impactDataViaDigiKua?.womenEmployed.percent,
            },
            {
                index: 1, name: "Youth employed", chart: "pie", segments: 4, desc: "The percentage of youth currently employed by the entrepreneur.",
                legend: [{ color: '#00A5A2', title: "Youth" }, { color: '#212153', title: "Not Youth" }], tooltip: ["Youth", "Not Youth"], segmentColors: ['#00A5A2', '#212153'], value: scoresData[0]?.impactDataViaDigiKua?.youthsEmployed.percent
            },
            {
                index: 2, name: "Number of direct jobs created", chart: "halfdoughnut", segments: 4, desc: "Jobs related directly to the business (full time + part time + casual).",
                legend: [{ color: '#00A5A2', title: "More than 3 direct jobs created" }, { color: '#FFC83B', title: "3 direct jobs created" }, { color: '#CC6328', title: "1 or 2 direct jobs created" }, { color: '#C4233C', title: "No direct job created" }],
                customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }],
                segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'],
                segmentStops: [-1, 0, 1, 2, 3],
                value: isLoadingScores ? 0 : scoresData[0]?.impactDataViaDigiKua?.directJobsCreated.score + 1.5
            },
            {
                index: 3,
                name: "Timeliness of quarterly surveys",
                chart: "halfdoughnut",
                segments: 4,
                desc: "Number of quarterly surveys submitted in the last four quarters.",
                legend: [
                    { color: '#00A5A2', title: "Reported on 1st or 2nd" },
                    { color: '#FFC83B', title: "Reported on 3rd" },
                    { color: '#CC6328', title: "Reported on 4th" },
                    { color: '#C4233C', title: "Reported on 5th or did not report" }
                ]
                , segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'], segmentStops: [0, 25, 50, 75, 100], value: scoresData[0]?.impactDataViaDigiKua?.timelinessReportingQuarterlySurvey.percent, type: "percentage"
            }
            ]
        },
    ]

    return (
        <>
            <Dashboard sidebarRoutes={clientRoutes} location={location}>
                <div className={styles.header}>
                    <h2>Scorecard . {businessName ? businessName : ""}</h2>
                </div>
                <Switch>
                    <Route exact path={`${path}`}>
                        <div className={styles.scorecardText}>
                            <p>Somo Scorecard is a digital tool that uses alternative statistical records to gauge the eligibility of entrepreneurs for loans and further financial support.
                            </p>
                        </div>
                        <div className={styles.piechart}>
                            <div className={styles.pieChartContainer}>
                                <div className={styles.legend}>
                                    <Legend bgColor="#C4233C" color="#C4233C" name="Bad" />
                                    <Legend
                                        bgColor="#CC6328"
                                        color="#CC6328"
                                        name="Average"
                                    />
                                    <Legend bgColor="#FFC83B" color="#FFC83B" name="Good" />
                                    <Legend
                                        bgColor="#00A5A2"
                                        color="#00A5A2"
                                        name="Excellent"
                                    />
                                </div>
                                <div className={styles.scoresPieChart}>
                                    <ScorePie averageScore={isLoadingScores ? 0 : averageScore} legend={true} screenWidth={screenWidth} />
                                </div>
                            </div>
                            <div className={styles.scoreContainer}>
                                <h3>Overall average score is</h3>
                                <h2 className={styles.description}
                                    style={{
                                        color: !isLoadingScores && !scoresError
                                            ? averageScore >= 0 && averageScore < 26
                                                ? "#C4233C"
                                                : averageScore >= 26 && averageScore < 51
                                                    ? "#CC6328"
                                                    : averageScore >= 51 && averageScore < 76
                                                        ? "#FFC83B"
                                                        : averageScore >= 76 && averageScore <= 100
                                                            ? "#00A5A2"
                                                            : "#212135"
                                            : "#212135"
                                    }}
                                >
                                    {isLoadingScores && loading(60, 60, 9, "#C4233C")}
                                    {!isLoadingScores && scoresError && error()
                                    }
                                    {!isLoadingScores && !scoresError && averageScore}
                                    {!isLoadingScores && !scoresError && averageScore >= 0 && averageScore < 26 && " Bad"}
                                    {!isLoadingScores && !scoresError && averageScore >= 26 && averageScore < 51 && " Average"}
                                    {!isLoadingScores && !scoresError && averageScore >= 51 && averageScore < 76 && " Good"}
                                    {!isLoadingScores && !scoresError && averageScore >= 76 && averageScore < 100 && " Excellent"}
                                </h2>
                            </div>
                        </div>
                        <div className={styles.metricsContainer}>
                            <div className={styles.scorecardText}>
                                <p style={{}}>Somo Scorecard generates weighted scores that serve as filters for financing decisions. It incorporates financial data, business insights, social impact measures and program participation, and is designed to open credit channels to qualified entrepreneurs who were previously disenfranchised and marginalized.
                                </p>
                            </div>
                            {metrics.map((metric, index) => (
                                <div
                                    className={styles.metric}
                                    key={index}
                                    style={{
                                        backgroundColor: "#f9f9f9",
                                        transform: hovered === metric.index && "scale(1.05",
                                    }}
                                    onMouseEnter={() => setHovered(metric.index)}
                                    onMouseLeave={() => setHovered("")}
                                    onClick={() => {
                                        setHovered("")
                                        setLink(metric.link)
                                    }}>
                                    <div className={styles.top}>
                                        <p className={styles.score}>
                                            {isLoadingScores && loading(60, 60, 9, "#C4233C")}
                                            {!isLoadingScores && !scoresError && metric.score}
                                            {!isLoadingScores && scoresError && error()}
                                        </p>
                                        <FaRegArrowAltCircleUp
                                            className={styles.openMetric}
                                            size="1.2rem"
                                            color="#7d7d7d"
                                            style={{ transform: hovered === metric.index ? "scale(1.2) rotate(45deg)" : "rotate(45deg)" }}
                                        />
                                    </div>
                                    <p className={styles.name}>{metric.name}</p>
                                    <div className={styles.icon}><p style={{ color: "#7d7d7d" }}>{metric.icon}</p></div>
                                </div>
                            ))}
                        </div>
                    </Route>
                    <Route path={`${path}/:id`}>
                        <ScoreMetric baseUrl={baseUrl} error={error} loading={loading} isLoadingScores={isLoadingScores} scoresError={scoresError} getScores={getScores} selectedData={selectedData} setSelectedData={setSelectedData} metrics={metrics} link={link} setLink={setLink} businessId={businessId} businessName={businessName} businessStatus={businessStatus} screenWidth={screenWidth} scoresData={scoresData} />
                    </Route>
                </Switch>
            </Dashboard >
        </>
    )
}

export default Scorecard;