import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";
import context from 'react-bootstrap/esm/AccordionContext';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend);


const ScorePie = ({ averageScore, legend, screenWidth }) => {

    const getGradient = (chart, startColor, endColor) => {
        const { ctx, chartArea: { top, bottom, left, right } } = chart;
        const gradientSegment = ctx.createLinearGradient(left, 0, right, 0)
        gradientSegment.addColorStop(0, '#C4233C');
        gradientSegment.addColorStop(0.25, '#CC6328');
        gradientSegment.addColorStop(0.75, '#FFC83B');
        gradientSegment.addColorStop(1, '#00A5A2');
        return gradientSegment;
    }

    const data = {
        labels: [
            'Bad',
            'Average',
            'Good',
            'Excellent'
        ],
        datasets: [{
            label: 'My First Dataset',
            data: [25, 25, 25, 25],
            backgroundColor: (context) => {
                const chart = context.chart
                const { ctx, chartArea } = chart;
                if (!chartArea) {
                    return null
                }
                switch (context.dataIndex) {
                    case 0:
                        return getGradient(chart);
                    case 1:
                        return getGradient(chart);
                    case 2:
                        return getGradient(chart);
                    case 3:
                        return getGradient(chart);
                    default:
                        return '#000000';
                }
            },
            circumference: 180,
            rotation: 270,
            hoverBorderColor: "#212153",
            needleValue: averageScore,
            borderWidth: 0
        }]
    };

    const gaugeNeedle = {
        id: "gaugeNeedle",
        afterDatasetsDraw(chart, args, plugins) {
            const { ctx, data } = chart;
            ctx.save();

            const xCenter = chart.getDatasetMeta(0).data[0].x;
            const yCenter = chart.getDatasetMeta(0).data[0].y;
            const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
            const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
            const widthSlice = (outerRadius - innerRadius) / 10;
            const radius = 15;
            const angle = Math.PI / 180;

            const needleValue = data.datasets[0].needleValue;
            const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);
            const circumference = ((chart.getDatasetMeta(0).data[0].circumference / Math.PI) / data.datasets[0].data[0]) * needleValue;

            ctx.translate(xCenter, yCenter);
            ctx.rotate(Math.PI * (circumference + 1.5));

            ctx.beginPath();
            ctx.strokeStyle = '#212153';
            ctx.fillStyle = '#FFC83B';
            ctx.lineWidth = 3;
            ctx.moveTo(0 - radius, 0);
            ctx.lineTo(0, 0 - innerRadius - widthSlice);
            ctx.lineTo(0 + radius, 0);
            ctx.stroke();
            ctx.fill();

            ctx.beginPath();
            ctx.arc(0, 0, radius, 0, angle * 360, false);
            ctx.fillStyle = '#212153';
            ctx.fill();
            ctx.stroke();

            ctx.restore();

            ctx.save();
            ctx.font = "16px Arial";
            ctx.fillStyle = "#212153";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";

            const offset = 35;
            ctx.fillText(`Score: ${needleValue}%`, xCenter, yCenter + offset);
            ctx.restore();
        }
    };


    const segmentLabelsPlugin = {
        id: 'segmentLabels',
        afterDatasetsDraw(chart) {
            const { ctx } = chart;
            const xCenter = chart.getDatasetMeta(0).data[0].x;
            const yCenter = chart.getDatasetMeta(0).data[0].y;
            const radius = chart.getDatasetMeta(0).data[0].outerRadius + 10;
            const labels = ['0', '25', '50', '75', '100'];

            const positions = [
                { x: xCenter - radius + 10, y: yCenter + 10 },
                { x: xCenter - (radius * 0.7), y: yCenter - (radius * 0.7) - 10 },
                { x: xCenter, y: yCenter - radius - 10 + 8 },
                { x: xCenter + (radius * 0.7), y: yCenter - (radius * 0.7) - 10 },
                { x: xCenter + radius - 30, y: yCenter + 10 },
            ];

            ctx.save();
            ctx.font = "bold";
            ctx.fillStyle = "#212153";

            labels.forEach((label, i) => {
                const { x, y } = positions[i];
                ctx.fillText(label, x, y);
            });

            ctx.restore();
        }
    };

    const plugins = [gaugeNeedle, segmentLabelsPlugin]


    const options = {
        plugins: {
            legend: {
                display: false,
                // position: "top"
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                display: false,
            }
        },
    }


    return (
        <div style={{ width: "100%" }} >
            <Doughnut data={data} options={options} plugins={plugins} width={screenWidth < 767.98 ? "auto" : "400px"} height={screenWidth < 767.98 ? "auto" : "400px"}></Doughnut>
        </div >
    )
}

export default ScorePie








