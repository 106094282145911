import React from 'react';
import styles from './DownloadScores.module.scss';
import Button from '../Button/Button';

const DownloadScores = ({ averageScore, data }) => {
    const handleButtonClick = () => {
        window.print();
    }

    return (
        <div className={`${styles.buttonsContainer} ${styles.noPrint}`}>
            <div className={styles.buttoncontainer}>
                <Button
                    clicked={handleButtonClick}
                    size={window.innerWidth > 500 ? "lg" : "sm"}
                    bgColor="#00A5A2"
                    color="#fff"
                >
                    Download PDF
                </Button>
            </div>
        </div >
    )
}

export default DownloadScores;