import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DigikuaBarGraph = ({ analytics }) => {
  const data = {
    labels:
      analytics.mode === "quarterly"
        ? [
          `Q1-${analytics.year}`,
          `Q2-${analytics.year}`,
          `Q3-${analytics.year}`,
          `Q4-${analytics.year}`,
        ]
        : analytics.mode === "monthly"
          ? [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]
          : [],

    datasets: [
      {
        label: analytics.label,
        data: Object.values(analytics.data),
        backgroundColor: analytics.label === "Revenue" ? "#ffc83b" : "#cc6328",
        borderColor: analytics.label === "Revenue" ? "#ffc83b" : "#cc6328",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: analytics.title,
      },
    },
  };

  return (
    <div style={{ height: "400px", minHeight: "300px" }}>
      <Bar height="100%" data={data} options={options} />
    </div>
  );
};

export default DigikuaBarGraph;
