import React, { useState, useEffect } from "react";
import styles from "./Note.module.scss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Title } from "chart.js";

const Note = ({
  id,
  width,
  padding,
  margin,
  title,
  notes,
  clicked,
  marginLeft,
}) => {
  return (
    <div
      className={styles.note}
      onClick={() => {
        clicked(id);
      }}
      style={{
        marginLeft: marginLeft,
        width: width && width,
        padding: padding && padding,
        margin: margin && margin,
      }}
    >
      <h5>{title.length > 20 ? `${title.substring(0, 20)}...` : title}</h5>
      <div className={styles.noteCont}>
        <ul>
          {notes.map((note, index) => (
            <li key={index}>{note}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Note;
