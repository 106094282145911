import React, { useState, useEffect } from "react";
import styles from "./Assignments.module.scss";
import { FaBusinessTime, FaCloudUploadAlt } from "react-icons/fa";
import LoaderIntro from "react-spinners/SyncLoader";
import { TiCancelOutline } from "react-icons/ti";
import noLoan from "../../assets/no-loan.png";
import { ToastContainer, toast } from "react-toastify";

import { useLocation } from "react-router-dom";

const Assignments = ({
  id,
  setId,
  index,
  baseUrl,
  cohortId,
  traineeId,
  assignmentTitle,
}) => {
  const location = useLocation();
  const [assignment, setAssignment] = useState([]);
  const [assignmentError, setAssignmentError] = useState("");
  const [isLoadingAssignment, setIsLoadingAssignment] = useState();

  const [submitButton, setSubmitButton] = useState("Submit");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const pathAfterLms = location.pathname.split("/lms/")[1];
    const pathSegments = pathAfterLms.split("/");

    const lastSegment = pathSegments[pathSegments.length - 1];
    const lastNumber = parseInt(lastSegment, 10);
    setId(lastNumber);
  }, []);

  useEffect(() => {
    const fetchAssignment = async () => {
      try {
        setIsLoadingAssignment(true);
        setAssignmentError("");

        const res = await fetch(`${baseUrl}assignments/${id}`);

        if (!res.ok)
          throw new Error("Something went wrong with fetching assignments");

        const data = await res.json();
        if (data.Response === false) throw new Error("Assignments not found.");

        const taskObj = data.data[0].tasks;
        // const taskObj = JSON.parse(data.data[0].tasks);
        const taskArray = Object.keys(taskObj).map((key) => ({
          [key]: taskObj[key],
        }));

        setAssignment(taskArray);
        setTitle(data.data[0].title);
        setAssignmentError("");
      } catch (err) {
        setAssignmentError(err.message);
      } finally {
        setIsLoadingAssignment(false);
      }
    };

    fetchAssignment();
  }, [id]);

  const getFileLink = async (file, name) => {
    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("folderId", "1N8hrpHxPbhHn4E1giGqnfOOYDWjFIc2Q");
      formData.append("fileName", name);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const url = `https://invest.somoafrica.org/api/media/up/onboard/?toGrive=1`;

      const res = await fetch(url, requestOptions);

      if (!res.ok) throw new Error("Something went wrong with fetching link");

      const data = await res.json();
      return data.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const submitAssignment = async (event) => {
    event.preventDefault();

    setSubmitButton(<LoaderIntro color="#00A5A2" />);

    const form = event.currentTarget;
    const formData = new FormData(form);

    const data = {
      traineeId: traineeId,
      cohortId: cohortId,
      answers: {},
    };

    for (const [key, value] of formData.entries()) {
      const inputElement = form.querySelector(
        `input[name="${key}"], textarea[name="${key}"], select[name="${key}"]`
      );
      const inputType = inputElement?.type;

      if (inputType === "checkbox") {
        if (data.answers[key]) {
          if (Array.isArray(data.answers[key])) {
            data.answers[key].push(value);
          } else {
            data.answers[key] = [data.answers[key], value];
          }
        } else {
          data.answers[key] = [value];
        }
      } else if (inputType === "file") {
        const fileLink = await getFileLink(
          value,
          `${assignmentTitle}-${traineeId}`
        );
        if (Array.isArray(data.answers[key])) {
          data.answers[key].push(fileLink);
        } else {
          data.answers[key] = fileLink;
        }
      } else {
        data.answers[key] = value;
      }
    }

    const url = `${baseUrl}assignments/${id}/submits`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(`${url}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error === false) {
          toast.success(data.message);
          setSubmitButton("Submit");
        } else {
          toast.error(data.message);
          setSubmitButton("Submit");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setSubmitButton("Submit");
      });
  };

  return (
    <div
      className={styles.applicationComplete}
      style={{ marginBottom: "20px" }}
    >
      <ToastContainer position="top-center" />
      {!assignmentError && (
        <h2>
          <FaBusinessTime size="2em" color="#cc6328" />{" "}
          {assignmentTitle ? assignmentTitle : title}
        </h2>
      )}

      {!isLoadingAssignment && !assignmentError && assignment.length > 0 && (
        <p style={{ textAlign: "left" }}>
          Answer all questions
          <br />
        </p>
      )}

      {isLoadingAssignment && (
        <div>
          <p style={{ textAlign: "left" }}>
            Loading&nbsp;
            <LoaderIntro color="#00a5a2" />
          </p>
        </div>
      )}
      {!isLoadingAssignment && assignmentError && (
        <>
          <div className={styles.errorQuestions}>
            <TiCancelOutline
              className={styles.mobileNoScores}
              size="5em"
              color="rgba(116, 23, 99, 0.6)"
            />
            <p>{assignmentError}</p>
            {/* <p>An unknown error occurred.</p> */}
          </div>
        </>
      )}
      {!isLoadingAssignment && !assignmentError && assignment.length > 0 && (
        <form
          method="POST"
          name="q_survey_form"
          endpoint=""
          onSubmit={submitAssignment}
        >
          <div
            className={styles.quizListHold}
            style={{ textAlign: "left" }}
            id="quizList"
            survey_info="quarterly_survey"
          >
            <input type="text" name="cohortId" value={cohortId} hidden />
            <input type="text" name="traineeId" value={traineeId} hidden />
            {assignment.map((assign, index) =>
              Object.entries(assign).map(([key, option]) => (
                <div className={styles.quizList} key={index}>
                  <div className={styles.quizListInfo}>
                    <label>
                      <p>
                        <span style={{ color: "#CC6328", border: "none" }}>
                          {key}.
                        </span>

                        <span style={{ fontSize: "0.7rem", border: "none" }}>
                          {" "}
                          {option.text}
                        </span>
                      </p>

                      {option.type === "link" && (
                        <input type="file" accept="*/*" name={key} />
                      )}

                      {option.type === "text" && (
                        <textarea
                          rows={1}
                          id={option.text}
                          name={key}
                          required={true}
                        ></textarea>
                      )}

                      {option.options && (
                        <>
                          {Object.entries(option.options).map(
                            ([key, option]) => (
                              <div key={key} className={styles.check}>
                                <input
                                  type="checkbox"
                                  id={key}
                                  name={`Q${option.id}`}
                                  value={option.name}
                                />
                                <label htmlFor={key}>{option}</label>
                              </div>
                            )
                          )}
                        </>
                      )}
                    </label>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className={styles.quizBtnLdr}>
            <button type="submit" className={styles.surveySubmitBtn}>
              {submitButton}&nbsp;
              <FaCloudUploadAlt size="2em" color="#00a5a2" />
            </button>
          </div>
        </form>
      )}
      {!isLoadingAssignment && !assignmentError && assignment.length < 1 && (
        <>
          <div className={styles.noQuestions}>
            <p>No Questions Have Been Added Yet.</p>
            <img src={noLoan} alt="No questions" height="250" />
          </div>
        </>
      )}

      <br />

      {isLoadingAssignment && (
        <p style={{ textAlign: "left", fontStyle: "italic" }}>
          Empowering you, to change the world{" "}
        </p>
      )}
      {!isLoadingAssignment && !assignmentError && assignment.length > 0 && (
        <p style={{ textAlign: "left", fontStyle: "italic" }}>
          Empowering you, to change the world{" "}
        </p>
      )}
    </div>
  );
};

export default Assignments;
