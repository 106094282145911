import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import styles from "./SearchScores.module.scss";
import LoaderIntro from "react-spinners/SyncLoader";

import {
  useHistory,
  useLocation,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const SearchScores = ({ bizId, bizName, setDetailedScoreData }) => {
  const history = useHistory();
  const [button, setButton] = useState("View Scores");
  //const [businessId, setBusinessId] = useState("");
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [yearOptions, setYearOptions] = useState([]);
  const [quarterOptions, setQuarterOptions] = useState([]);
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  const currentQuarter = Math.ceil(currentMonth / 3);

  useEffect(() => {
    const populateYearSelect = () => {
      const startYear =
        currentQuarter === 1 ? currentYear - 6 : currentYear - 5;
      const endYear = currentQuarter === 1 ? currentYear - 1 : currentYear;
      const years = [];

      for (let i = endYear; i >= startYear; i--) {
        years.push(i);
      }

      setYearOptions(years);
    };

    const populateQuarterSelect = () => {
      const quarters = ["Q1", "Q2", "Q3", "Q4"];
      setQuarterOptions(
        currentYear === date.getFullYear()
          ? quarters.slice(0, currentQuarter - 1)
          : quarters
      );
    };

    populateYearSelect();
    populateQuarterSelect();
  }, [currentQuarter, currentYear]);

  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value, 10);
    const quarters = ["Q1", "Q2", "Q3", "Q4"];

    setQuarterOptions(
      selectedYear === currentYear
        ? quarters.slice(0, currentQuarter - 1)
        : quarters
    );
  };

  const handleButtonClick = async () => {
    if (bizId) {
      if (year) {
        if (quarter) {
          setButton(<LoaderIntro color="#fff" />);
          const data = {
            businessId: bizId,
            year: year,
            quarter: quarter,
          };

          const requestOptions = {
            method: "POST",
            body: JSON.stringify(data),
          };

          await fetch(
            "https://space.somoafrica.org/api.php?resource=scorecard&action=read",
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.count > 0) {
                history.push("/dashboard/detailedscores", { data });
                setButton("View Scores");
              } else {
                setButton("View Scores");
                toast.error("No data available for selected quarter");
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
          setYear("");
          setQuarter("");
        } else {
          toast.error("Please select a quarter");
        }
      } else {
        toast.error("Please select a year");
      }
    } else {
      toast.error("Please select a business");
    }
  };

  return (
    <div className={styles.searchContainer}>
      <ToastContainer position="top-center" />
      <div className={styles.selectContainer}>
        <div>
          <label for="">Select Business:</label>
        </div>
        <select className={styles.formControl}>
          <option selected value={bizId}>
            {bizName}
          </option>
        </select>
      </div>
      <div className={styles.selectContainer}>
        <div>
          <label for="year">Select Year:</label>
        </div>
        <select
          name="year"
          id="year"
          value={year}
          className={`${styles.formControl} ${styles.year}`}
          onChange={(e) => {
            handleYearChange(e);
            setYear(e.target.value);
          }}
        >
          <option selected disabled value="">
            --Select--
          </option>
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.selectContainer}>
        <div>
          <label for="quarter">Select Quarter:</label>
        </div>
        <select
          name="quarter"
          id="quarter"
          className={styles.formControl}
          value={quarter}
          onChange={(e) => {
            setQuarter(e.target.value);
          }}
        >
          <option selected disabled value="">
            --Select--
          </option>
          {quarterOptions.map((quarter) => (
            <option key={quarter} value={quarter}>
              {quarter}
            </option>
          ))}
        </select>
      </div>
      {/* <div>
                <button className={styles.button} onClick={handleButtonClick}>View Scores</button>
            </div> */}
      <div className={styles.buttonContainer}>
        <Button
          clicked={handleButtonClick}
          size="sm"
          bgColor="#FFC83B"
          color="#CC6328"
          styles={styles.Button}
        >
          {button}
        </Button>
      </div>
    </div>
  );
};

export default SearchScores;
