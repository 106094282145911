import React, { useContext, useEffect, useState } from "react";
import styles from "./ApplicantOverview.module.scss";
import { clientRoutes } from "../../routes/sidebarRoutes";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import gypsyNote from "../../assets/icons/gypsyNotes.svg";
import altInvestment from "../../assets/icons/alternative.svg";
import { Col, Row } from "react-bootstrap";
import noLoan from "../../assets/no-loan.png";
import moment from "moment";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as LoanContext } from "../../context/LoanContext";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import WaitListModal from "../../components/WaitListModal/WaitListModal";

const ApplicantOverview = () => {
  const history = useHistory();
  const location = useLocation();
  const [activeLoan, setActiveLoan] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { loans },
    retrieveClientLoans,
  } = useContext(LoanContext);

  useEffect(() => {
    retrieveClientLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const statusToDisplay = loans.filter(
      (loan) => loan.status.toLowerCase() === "pending" || "active"
    );
    console.log(statusToDisplay[0]);
    setActiveLoan(statusToDisplay[0]);
  }, [loans]);

  useEffect(() => {
    // console.log(user, localStorage);
    if(!localStorage.ApplicantSelfOnboard){
      if(user.useNewOnboarding===true && user.onboardingComplete===false){
        history.push('/dashboard/onboarding/welcome');
      }
    } else {
      try{
        var info = JSON.parse(localStorage.ApplicantSelfOnboard);
        if(info.status!='complete' && user.useNewOnboarding===true){
          history.push('/dashboard/onboarding/welcome');
        }
      } catch(er){
        if(user.useNewOnboarding===true && user.onboardingComplete===false){
          history.push('/dashboard/onboarding/welcome');
        }
      }
    }
  }, [user]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (!user && !loans) {
    return <Loader />;
  }

  return (
    <>
      <Dashboard sidebarRoutes={clientRoutes} location={location}>
        <div className={styles.welcomeGroup}>
          <h2>Hey, {user.firstName}</h2>
          <p className={styles.currentDate}>
            Today is {moment().format("dddd Do[,] MMMM")}.
          </p>
        </div>
        <div className={styles.loanStatus}>
          {!activeLoan && (
            <div className={styles.innerContainer}>
              <div className={styles.mainContent}>
                <h3>Active Loan</h3>
                <p>Sorry you currently have no active loan</p>
                <Button
                  bgColor="#cc6328"
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() =>
                    history.push("/dashboard/consumer-credit/apply/calculator")
                  }
                >
                  Apply for loan
                </Button>
              </div>
              <img src={noLoan} alt="No active loan" />
            </div>
          )}
          {activeLoan && (
            <div className={styles.activeStyle}>
              <div className={styles.mainContent}>
                <h3>
                  Active Loan <span>{activeLoan.status}</span>
                </h3>
                <div className={styles.statusBoard}>
                  <Row>
                    <Col sm={12} md={4} className={styles.borderClass}>
                      <div className={styles.loanData}>
                        <div>
                          <h6>Loan amount</h6>
                          <h1>{`Ksh${numberWithCommas((activeLoan.amount ? activeLoan.amount : '--'))}`}</h1>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={4} className={styles.borderClass}>
                      <div className={styles.loanData}>
                        <div>
                          <h6>Monthly Repayment</h6>
                          <h1>{`Ksh${numberWithCommas(
                            (activeLoan.monthlyRepayment ? activeLoan.monthlyRepayment : '--')
                          )}`}</h1>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={4}>
                      <div className={styles.loanData}>
                        <div>
                          <h6>Repayment Period(Months)</h6>
                          <h1>{activeLoan.repaymentPeriod * 12}</h1>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
      <WaitListModal open={modalOpen} toggleModal={handleModalToggle} />
    </>
  );
};

export default ApplicantOverview;
