import React, { useEffect, useState } from "react";
import LMSContent from "../LMSContent/LMSContent";
import {
  useParams,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import styles from "./TopicHeader.module.scss";
import {
  FaChevronRight,
  FaVideo,
  FaBookOpen,
  FaRegQuestionCircle,
  FaRegTimesCircle,
  FaAngleRight,
  FaAngleLeft,
  FaRegListAlt,
} from "react-icons/fa";

const TopicHeader = ({
  topicName,
  setClickedTopic,
  closePanel,
  setClosePanel,
}) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <div className={styles.header}>
      <div className={styles.description}>
        <div className={styles.contentNav}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {closePanel ? (
              <div
                className={styles.openMenu}
                onClick={() => setClosePanel(false)}
                title="Open Menu"
              >
                <FaChevronRight size="1rem" color="#cc6328" />
              </div>
            ) : (
              ""
            )}
            <h2 style={{ marginLeft: closePanel ? "" : "20px" }}>
              {topicName}
            </h2>
          </div>
          <div className={styles.nav}>
            <div
              className={styles.navigations}
              onClick={() => setClickedTopic(1)}
            >
              <FaAngleLeft />
              <h3 className={styles.route}>Previous</h3>
            </div>
            <div
              className={styles.navigations}
              onClick={() => setClickedTopic(2)}
            >
              <h3 className={styles.route}>Next</h3>
              <FaAngleRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicHeader;
