import React from 'react';
import Speedometer from 'react-d3-speedometer';
import ReactSpeedometer from "react-d3-speedometer"
import styles from "./ScoresGaugeChart.module.scss";

const GaugeChart = ({ value, months }) => {
    const segmentColors = ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2',];
    var segmentRanges = []

    const valueStyle = {
        color: 'red'
    };

    // Define the ranges for each segment
    if (months >= 1 && months <= 6) {
        segmentRanges = [0, 30, 40, 50, 100];
    } else if (months >= 7 && months <= 12) {
        segmentRanges = [0, 40, 50, 60, 100];
    } else if (months >= 13 && months <= 24) {
        segmentRanges = [0, 50, 60, 70, 100];
    } else if (months >= 25) {
        segmentRanges = [0, 60, 70, 80, 100];
    }

    const segmentStops = segmentRanges.map(range => (range / 100) * 100);

    return (
        <ReactSpeedometer
            value={value}
            minValue={0}
            maxValue={100}
            needleColor="#212153"
            needleTransition="easeElastic"
            startColor={segmentColors[0]}
            endColor={segmentColors[segmentColors.length - 1]}
            segments={segmentColors.length - 1}
            customSegmentStops={segmentStops}
            segmentColors={segmentColors}
            currentValueText={'Score: ${value}'}
            fluidWidth={true}
            textColor={'#212153'}
        //forceRender={true}
        />
    );
};

export default GaugeChart;
