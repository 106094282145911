import React, { useContext, useEffect } from "react";
import styles from "./Playbook.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import { Table } from "react-bootstrap";
import noLoan from "../../assets/no-loan.png";
import welcomeToAcc from "../../assets/welcomeBuruka.png";
import somoLogo from "../../assets/logo12.png";
import somoLogo2 from "../../assets/logo.png";
import { Row, Col, Modal } from "react-bootstrap";
import { Fa500Px, FaAccusoft, FaAmilia, FaArrowCircleLeft, FaArrowRight, FaBatteryEmpty, FaBell, FaBellSlash, FaBomb, FaBook, FaBookOpen, FaBookReader, FaCalendarCheck, FaCarBattery, FaChalkboardTeacher, FaCheck, FaCheckCircle, FaCheckDouble, FaCheckSquare, FaCircle, FaCircleNotch, FaClock, FaFlagCheckered, FaHome, FaMailBulk, FaMoneyCheckAlt, FaRegBell, FaRocket, FaSms, FaSquare, FaSquareFull, FaSquareRootAlt, FaStopwatch, FaUserShield, FaWalking, FaWineGlassAlt } from "react-icons/fa";
import { TiCancelOutline } from "react-icons/ti";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
// import Loader from "react-spinners/BounceLoader";
// import Loader1 from "react-spinners/ScaleLoader";
// import Loader2 from "react-spinners/ClimbingBoxLoader";
// import Loader3 from "react-spinners/ClipLoader";
import LoaderPre from "react-spinners/ClockLoader";
// import Loader5 from "react-spinners/RingLoader";
// import Loader6 from "react-spinners/PuffLoader";
// import Loader7 from "react-spinners/RotateLoader";
import LoaderIntroWait from "react-spinners/HashLoader";
import LoaderIntro from "react-spinners/SyncLoader";
// import Loader10 from "react-spinners/PacmanLoader";
// import Loader11 from "react-spinners/PropagateLoader";
// import Loader12 from "react-spinners/PuffLoader";
// import Loader13 from "react-spinners/PulseLoader";
import ApplicantUniUploadForm from "../../components/ApplicantUniUploadForm/ApplicantUniUploadForm";

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import { clientRoutes } from "../../routes/sidebarRoutes";
import _ from "lodash";
import { renderToStaticMarkup } from 'react-dom/server';
import { FadeLoader } from "react-spinners";

const Playbook = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const applyStageArray = {
    "/dashboard/applicant/apply/activate-done-at-login": 0,
    "/dashboard/applicant/apply/start": 1,
    "/dashboard/applicant/apply/id_front": 2,
    "/dashboard/applicant/apply/id_back": 3,
    "/dashboard/applicant/apply/kra_pin": 4,
    "/dashboard/applicant/apply/b_name": 5,
    "/dashboard/applicant/apply/b_bank": 6,
    "/dashboard/applicant/apply/invest_overview": 7,
    "/dashboard/applicant/apply/invest_video": 8,
    "/dashboard/applicant/apply/invest_image": 9,
    "/dashboard/applicant/apply/invest_logo": 10,
    "/dashboard/applicant/apply/e_map": 11,
    "/dashboard/applicant/apply/conduct": 12,
    "/dashboard/applicant/apply/photography": 13,
    "/dashboard/applicant/apply/final": 14,
    // "/dashboard/consumer-credit/apply/calculator": 0,
    // "/dashboard/consumer-credit/apply/fund": 1,
    // "/dashboard/consumer-credit/apply/amount": 2,
    // "/dashboard/consumer-credit/apply/bank-info": 3,
  };

  const {
    state: { loans, error, currentLoanId, incomplete, loanApplicationStage },
    retrieveClientLoans,
    clearError,
    loanApply,
    addAddressForLoan,
    addWorkInfoForLoan,
    addBankInfoForLoan,
    clearCompleteState,
  } = useContext(LoanContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    // console.log('psr>>', user);
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetOnboardingState();      
    // });
    var tpl = {
      advisorsList: `<div class="${styles.advisorList} advisor-list-each" pairId="{pairId}">
        ${renderToStaticMarkup(<FaUserShield size="1.2em" color="#cc6328" class={styles.icon} />)}
          <div class="${styles.advisorListInfo}">
            <span>{name}</span>
            <span>${renderToStaticMarkup(<FaBookReader size="21px" color="#cc6328" class={styles.icon} />)} - {playbook}</span>
            <span>{email}</span>
            <span>{phone}</span>
          </div>
          <input type="checkbox" />
          {checkIcon}
        </div>`,
      iconChecked: renderToStaticMarkup(<FaCheckCircle class={(styles.icon + ' ' + styles.iconChecked)} />),
      iconNonChecked: renderToStaticMarkup(<FaCircleNotch class={`${styles.icon} iconNonChecked`} />),
      iconCheckedHover: renderToStaticMarkup(<FaCheckCircle class={`${styles.icon} ${styles.iconChecked} ${styles.iconCheckedHover}`} />),
      loader: renderToStaticMarkup(<LoaderIntro color="#00a5a2" />),
      playbooksList: `<div class="${styles.advisorList} advisor-list-each" playbookId="{playbookId}">
        ${renderToStaticMarkup(<FaBookReader size="1.2em" color="#cc6328" class={styles.icon} />)}
          <div class="${styles.advisorListInfo}">
            <span>{playbook}</span>
            <span>{progress}</span>
            <span>{status}</span>
            <span>${renderToStaticMarkup(<FaUserShield size="21px" color="#cc6328" class={styles.icon} />)} - {email}</span>
          </div>
        </div>`,
      playbookChapterList: `<div class="${styles.advisorList} advisor-list-each" chapterId="{chapterId}">
        ${renderToStaticMarkup(<FaBookOpen size="1.2em" color="#cc6328" class={styles.icon} />)}
          <div class="${styles.advisorListInfo}">
            <span>{name}</span>
            <span>No. {pos}</span>
          </div>
        </div>`,
      playbookSessionList: `<div class="${styles.advisorList} advisor-list-each" sessionId="{sessionId}">
      ${renderToStaticMarkup(<FaBookOpen size="1.2em" color="#cc6328" class={styles.icon} />)}
        <div class="${styles.advisorListInfo}">
          <span>{name}</span>
          <span>No. {pos}</span>
        </div>
      </div>`,
      loaderMain: `<span style=${{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>Loading...&nbsp;${renderToStaticMarkup(<LoaderIntro color="#00a5a2" />)}</span>`,
      sesTtlTtl: `<h3>{pos}.</h3><h3>{ttl}</h3>`,
      sesToolList: `<div class="${styles.toolCheck}">
        <img src="https://invest.somoafrica.org/img/playbook/{img}" />
        <div class="${styles.toolTtl}">
          <p styles="padding-left: 10px;font-weight:bold;color:#CC6328;">{info}</p>
          <a class="subPlaybookListLinks" href="{value}" target="blank">{name}</a>
        </div>
        {statusIcon}
      </div>`,
      sesToolListDone: `<div class="${styles.checkStatus}">
          <p>Completed</p>
          ${renderToStaticMarkup(<FaCheckCircle size="4em" class={styles.icon} />)}
        </div>`,
      sesToolListWait: `<div class="${styles.checkStatus}">
        <p>Pending...</p>
        ${renderToStaticMarkup(<FadeLoader size="4em" class={styles.icon} />)}
      </div>`,
      sesTaskList: `<div class="${styles.task}">
        <div class="${styles.top}">
          <p><b>Topic : </b><span>{topic}</span></p>
          <p><b>Due Date : </b><span>{duedate}</span></p>
          <p><b>Response Date : </b><span>{resdate}</span></p>
        </div>
        <textarea readonly="">{task}</textarea>
        <div class="${styles.bottom}">
          <p class="${styles.file}">
            <b>Files : </b>
            {files}
          </p>
          <button id="{id}" class="start-task-reply-btn {hide}">Reply</button>
        </div>
      </div>`,
      sesListFiles: `<a href="{link}" target="_blank">File {count}</a>`,
      sesFeedList: `<div class="${styles.feed}">
      ${renderToStaticMarkup(<FaUserShield size="5em" color="#cc6328" className={styles.icon} />)}
        <div class="${styles.left}">
          <p>By : {advisor}</p>
          <div class="${styles.msg}">{feed}</div>
          <p class="${styles.file}">
            <b>Files : </b>
            {files}
          </p>
        </div>

      </div>`
    }

    if (document.getElementById('bubblesCanvas')) {
      // return 0;
      var canvas = document.getElementById('bubblesCanvas');
      var divHolder = document.getElementById('myEntrepreneursContainer');
      const ctx = canvas.getContext('2d');

      canvas.width = divHolder.clientWidth - 1;
      canvas.height = divHolder.clientHeight - 1;

      // Array to store the bubbles
      const bubbles = [];
      const bubbleColors = ['#efc959', '#f3f3f3', '#00a5a2', '#cc6328', '#1e2144'];
      var aa = 0;

      // Function to create a new bubble
      function createBubble() {
        const bubble = {
          x: Math.random() * canvas.width,   // Random X position
          y: canvas.height + 10,              // Start at the bottom of the canvas
          radius: Math.random() * 20 + 5,    // Random radius between 10 and 30
          speed: Math.random() * 2 + 1,        // Random vertical speed between 1 and 3
          color: bubbleColors[((Math.random() * 10) % (bubbleColors.length - 1)).toFixed(0)]
        };

        bubbles.push(bubble);
      }

      // Function to update the bubbles
      function updateBubbles() {
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Move and draw each bubble
        bubbles.forEach(bubble => {
          bubble.y -= bubble.speed;   // Move the bubble upwards

          // Draw the bubble
          ctx.beginPath();
          ctx.arc(bubble.x, bubble.y, bubble.radius, 0, Math.PI * 2);
          // ctx.fillStyle = '#fff';
          ctx.fillStyle = bubble.color;
          ctx.fill();
          // console.log(bubble.color);

          // Check if the bubble is off the screen
          if (bubble.y + bubble.radius < 0) {
            // Remove the bubble from the array
            const index = bubbles.indexOf(bubble);
            bubbles.splice(index, 1);
          }
        });

        // a new bubble every 1 second (0.01)
        if (Math.random() < 0.1) {
          createBubble();
        }

        // Call the updateBubbles function again
        requestAnimationFrame(updateBubbles);
      }

      window.addEventListener('resize', function (event) {
        if (canvas.width != divHolder.clientWidth || canvas.height != divHolder.clientHeight) {
          canvas.width = divHolder.clientWidth - 1;
          canvas.height = divHolder.clientHeight - 1;
          console.log('canvas size updated');
        }
      });

      // Start the animation
      updateBubbles();
      // setInterval(createBubble, 100);
    }

    if (document.getElementById('advisorsList')) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var advisorsList = document.getElementById('advisorsList');
      advisorsList.innerHTML = tpl.loaderMain;


      getPairedAdvisors(user.user_id);
      function getPairedAdvisors(usrId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
              toast.error("Error Occured " + a.error);
              advisorsList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            var playbookSelectedPair = JSON.parse(localStorage.getItem('playbookSelectedPair'));
            if (!playbookSelectedPair) playbookSelectedPair = { _id: false };
            a = a.data;
            var listTpl = '';
            for (var x in a) {
              var xx = x;
              for (var c in a[x].assignedPlaybooks) {
                xx = a[x].assignedPlaybooks[c];
                break;
              }
              // console.log(xx);
              listTpl += tpl.advisorsList.replace(/{pairId}/ig, a[x]._id).replace(/{name}/ig, `Advisor ${(parseInt(x) + 1)}`).replace(/{playbook}/ig, xx.name).replace(/{email}/ig, a[x].advisorMail).replace(/{phone}/ig, a[x].advisorWaap).replace(/{checkIcon}/ig, (playbookSelectedPair._id === a[x]._id ? tpl.iconChecked : tpl.iconNonChecked));
            }

            advisorsList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">Not Paired <br/>  You don't have an advisor yet, reach out to your business coach for pairing</div>`);
            var advisorsListChild = advisorsList.getElementsByClassName('advisor-list-each');
            for (var b of advisorsListChild) {
              b.addEventListener('click', function (e) {
                e.stopImmediatePropagation();
                // console.log('Clicked -> ', this.attributes.pairid.value, this);
                var backup = {
                  htm: this.innerHTML,
                  elm: this
                };
                this.innerHTML += tpl.iconCheckedHover + `<b class="${styles.iconLoader}">${tpl.loader}</b>`;

                // fetch('/').then((ers) => { return (ers.text()); }).then((e) => { console.log(e) })
                // fetch('/').then(x => x.text()).then((y) => { console.log(y) });
                // fetch('/').then(x => x.json()).then((y) => { console.log(y) });

                fetch(`${globalVars.baseUrl}${globalVars.getPairedAdvisors}?id=${this.attributes.pairid.value}`, {
                  method: 'GET',
                  headers: {
                    "Authorization": `Bearer ${resolveToken()}`
                  }
                }).then(x => x.json()).then((z) => {
                  // console.log(z);
                  if (z.error) {
                    toast.error("Error Occured " + z.error);
                    backup.elm.innerHTML = backup.htm;
                    return 0;
                  } else if (!z.data[0]) {
                    toast.error("Error Occured. Pair not found  ");
                    backup.elm.innerHTML = backup.htm;
                    return 0;
                  } else if (z.data.length > 1) {
                    toast.error("Fatal Error. Too many pairs found, only one expected  ");
                    backup.elm.innerHTML = backup.htm;
                    return 0;
                  }

                  delete (z.data[0].whoAdded);
                  localStorage.setItem('playbookSelectedPair', JSON.stringify(z.data[0]));
                  setTimeout(() => history.push("/dashboard/playbook/playbooks"), 900);
                  // history.push("/dashboard/playbook/playbooks");
                }).catch((err) => {
                  console.log('Load Error > :3', err);
                  toast.error("Error Occured !");
                  backup.elm.innerHTML = backup.htm;
                });
              })
            }

          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getPairedAdvisors);
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }

    }

    if (document.getElementById('playbooksList')) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var playbooksList = document.getElementById('playbooksList');
      playbooksList.innerHTML = tpl.loaderMain;

      var playbookSelectedPair = JSON.parse(localStorage.getItem('playbookSelectedPair'));
      if (!playbookSelectedPair) {
        console.log('Not Paired > :3', playbookSelectedPair);
        toast.error("Not Paired. Select an advisor to proceed ");
        playbooksList.innerHTML = `<div class="${styles.error}">Not Paired <br/>  Select an <a href="/dashboard/playbook/advisors">advisor</a> to proceed</div>`;
        return 0;
      }
      var pairedPlaybooks = [];
      for (var i in playbookSelectedPair.assignedPlaybooks) {
        pairedPlaybooks.push(i);
      }


      getPairedPlaybooks(user.user_id);
      function getPairedPlaybooks(usrId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
              toast.error("Error Occured " + a.error);
              playbooksList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            a = a.data;
            var listTpl = '';
            for (var x in a) {
              var xx = x;
              for (var c in a[x].assignedPlaybooks) {
                xx = a[x].assignedPlaybooks[c];
                break;
              }
              if (!pairedPlaybooks.includes(xx.id)) {
                continue;
              }
              if (!a[x].progress) {
                a[x].progress = {
                  playbookId: xx._id, "chapterId": null, "sessionId": null,
                  "state": "0%", "stateNumber": 0, "status": "pending"
                }
              }
              // console.log(xx);
              listTpl += tpl.playbooksList.replace(/{playbookId}/ig, xx.id).replace(/{playbook}/ig, xx.name).replace(/{email}/ig, a[x].advisorMail.charAt(0).toUpperCase() + a[x].advisorMail.slice(1)).replace(/{progress}/ig, a[x].progress.state).replace(/{status}/ig, a[x].progress.status.charAt(0).toUpperCase() + a[x].progress.status.slice(1));
            }

            if (listTpl) {
              listTpl += `<div style="margin-bottom:14px;">Only showing playbooks that you can be taken through by the selected advisor. If you'd like to go through a different playbook, select another advisor / pairing</div>`;
            }
            playbooksList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">No Access to Playbook yet <br/>  You cannot access the playbooks unless you are paired with an advisor, reach out to your business coach for pairing</div>`);
            var playbooksListChild = playbooksList.getElementsByClassName('advisor-list-each');
            for (var b of playbooksListChild) {
              b.addEventListener('click', function (e) {
                e.stopImmediatePropagation();
                this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                setTimeout(() => history.push(`/dashboard/playbook/playbook/${this.attributes.playbookId.value}`), 300);
              });
            }

          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getPairedAdvisors);
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }

    }

    if (document.getElementById('playbooksListChapter')) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var playbooksList = document.getElementById('playbooksListChapter');
      playbooksList.innerHTML = tpl.loaderMain;

      var pathArr = location.pathname.split("/");
      var play_id_path = pathArr.pop();
      if (play_id_path.length != 24) {
        console.log('Bad id found, will retry inner', play_id_path, pathArr);

        var play_id_path = pathArr.pop();
        if (play_id_path.length != 24) {
          console.log('Bad id found 2, will kif up', play_id_path, pathArr);

          toast.error("Selected Playbook detail not found. Select a playbook to proceed ");
          playbooksList.innerHTML = `<div class="${styles.error}">Selected Playbook detail not found. Select a playbook to proceed <br/>  Select a <a href="/dashboard/playbook/playbooks">Playbooks</a> to proceed</div>`;
          return 0;
        }
      }


      getPlaybookChapters(play_id_path);
      function getPlaybookChapters(playbookId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
              toast.error("Error Occured " + a.error);
              playbooksList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            a = a.data;
            var listTpl = '';
            for (var x in a) {
              // console.log(x);
              listTpl += tpl.playbookChapterList.replace(/{chapterId}/ig, a[x]._id).replace(/{name}/ig, a[x].name).replace(/{pos}/ig, a[x].position);//.replace(/{progress}/ig, a[x].progress).replace(/{status}/ig, a[x].progress);
            }

            playbooksList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">No Playbook chapters <br/>  try again later or reach out to your business coach for help</div>`);
            var playbooksListChild = playbooksList.getElementsByClassName('advisor-list-each');
            for (var b of playbooksListChild) {
              b.addEventListener('click', function (e) {
                e.stopImmediatePropagation();
                this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                setTimeout(() => history.push(`/dashboard/playbook/book/${playbookId}/chapter/${this.attributes.chapterId.value}`), 300);
              });
            }

          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getPlaybookChapters.replace(/{playbookId}/ig, playbookId));
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }

    }

    if (document.getElementById('bookChaptListSession')) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var playbooksList = document.getElementById('bookChaptListSession');
      playbooksList.innerHTML = tpl.loaderMain;

      var pathArr = location.pathname.split("/");
      var chapter_id_path = pathArr.pop();
      if (chapter_id_path.length != 24) {
        console.log('Bad id found, will retry inner', chapter_id_path, pathArr);

        var chapter_id_path = pathArr.pop();
        if (chapter_id_path.length != 24) {
          console.log('Bad id found 2, will kif up', chapter_id_path, pathArr);

          toast.error("Selected Chapter detail not found. Select a playbook to proceed ");
          playbooksList.innerHTML = `<div class="${styles.error}">Selected Chapter detail not found. Select a playbook to proceed <br/>  Select a <a href="/dashboard/playbook/playbooks">Playbooks</a> to proceed</div>`;
          return 0;
        }
      }
      pathArr.pop();
      var play_id_path = pathArr.pop();


      getPlaybookSessions(chapter_id_path, play_id_path);
      function getPlaybookSessions(chapterId, playbookId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
              toast.error("Error Occured " + a.error);
              playbooksList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            a = a.data;
            var listTpl = '';
            for (var x in a) {
              // console.log(x);
              listTpl += tpl.playbookSessionList.replace(/{sessionId}/ig, a[x]._id).replace(/{name}/ig, a[x].name).replace(/{pos}/ig, a[x].position);//.replace(/{progress}/ig, a[x].progress).replace(/{status}/ig, a[x].progress);
            }

            playbooksList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">No Playbook sessions <br/>  try again later or reach out to your business coach for help</div>`);
            var playbooksListChild = playbooksList.getElementsByClassName('advisor-list-each');
            for (var b of playbooksListChild) {
              b.addEventListener('click', function (e) {
                e.stopImmediatePropagation();
                this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                setTimeout(() => history.push(`/dashboard/playbook/b/${playbookId}/c/${chapterId}/s/${this.attributes.sessionId.value}`), 300);
              });
            }

          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getPlaybookSessions.replace(/{chapterId}/ig, chapterId));
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }

    }

    if (document.getElementById('playbookSessionDetails')) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var playbookElm = {
        main: document.getElementById('playbookSessionDetails'),
        sesAside: document.getElementById('sesAside'),
        sesSection: document.getElementById('sesSection'),
        fetchTools: document.getElementById('fetchTools'),
        fetchAssign: document.getElementById('fetchAssign'),
        fetchFeedback: document.getElementById('fetchFeedback'),
        sesTtlTtl: document.getElementById('sesTtlTtl'),
        sesTtlInfo: document.getElementById('sesTtlInfo'),
        toolsCheckListTab: document.getElementById('toolsCheckListTab'),
        assignmentsTab: document.getElementById('assignmentsTab'),
        feedbacksTab: document.getElementById('feedbacksTab'),
        assignmentList: document.getElementById('assignmentList'),
        assignmentForm: document.getElementById('assignmentForm'),
        closeXformAsign: document.getElementById('closeXformAsign'),
        closeXformFeed: document.getElementById('closeXformFeed'),
        addFeedbackContainer: document.getElementById('addFeedbackContainer'),
        addFeedBtn: document.getElementById('addFeedBtn'),
        feedbackList: document.getElementById('feedbackList'),
        rateContainer: document.getElementById('rateContainer')
      }

      playbookElm.sesTtlTtl.innerHTML = playbookElm.sesTtlInfo.innerHTML = tpl.loaderMain;

      var pathArr = location.pathname.split("/");
      if (pathArr.length != 9) {
        console.log('Bad url found, giving up...', location.pathname, pathArr);
        playbookElm.sesSection.innerHTML = `<div class="${styles.error}"><h1>Error!</h2><p>Link error occured. Refresh page or re-select <a href='/dashboard/playbook/playbooks'>playbook session</a> to continue</p></div>`;
        return 0;
      }

      var playbookSelectedPair = JSON.parse(localStorage.getItem('playbookSelectedPair'));
      if (!playbookSelectedPair) {
        console.log('No pairing info, giving up...', playbookSelectedPair);
        playbookElm.sesSection.innerHTML = `<div class="${styles.error}"><h1>Error!</h2><p>Pairing details error. Refresh page or re-select <a href='/dashboard/playbook/advisors'>playbook advisor</a> to continue</p></div>`;
        return 0;
      }

      var pathDetails = {
        bookId: pathArr[4],
        chapId: pathArr[6],
        sessId: pathArr[8]
      };
      // console.log(pathDetails);
      playbookElm.fetchTools.addEventListener('click', () => { switchToTab('toolsCheckListTab'); });
      playbookElm.fetchAssign.addEventListener('click', () => { switchToTab('assignmentsTab'); });
      playbookElm.fetchFeedback.addEventListener('click', () => { switchToTab('feedbacksTab'); });

      playbookElm.closeXformAsign.addEventListener('click', () => { playbookElm.assignmentForm.classList.add(styles.hide); });
      playbookElm.closeXformFeed.addEventListener('click', () => { playbookElm.addFeedbackContainer.classList.add(styles.hide); });
      playbookElm.addFeedBtn.addEventListener('click', () => { playbookElm.addFeedbackContainer.classList.remove(styles.hide); });

      document.forms.feedbackForm.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        var formPayload = new FormData(this);
        console.log(formPayload, formPayload.rated);

        if (!this.rated.value || this.rated.value == '0') {
          toast.error('Rate the adviser by clicking any of the stars to continue');
          playbookElm.rateContainer.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
          return 0;
        }
        formPayload.append('sessionId', pathDetails.sessId);
        formPayload.append('pairingId', playbookSelectedPair._id);
        formPayload.append('from', 'Entrepreneur');
        formPayload.append('addedById', user.user_id);
        formPayload.append('addedByEmail', user.email);
        addFeedback(this, formPayload);
      });

      document.forms.assignmentForm.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        var formPayload = new FormData(this);
        console.log(formPayload, formPayload.rated);

        if (!this.response.value) {
          toast.error('Response is needed to submit');
          this.response.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
          this.response.focus();
          return 0;
        }

        formPayload.append('addedById', user.user_id);
        formPayload.append('addedByEmail', user.email);
        addTaskResp(this, formPayload);
      });


      playbookElm.rateContainer.addEventListener('click', function (e) {
        if (e.target.tagName.toUpperCase() == 'I') {
          var rt = parseInt(e.target.attributes.val.value.replace(/rt/ig, ''));
          document.forms.feedbackForm.rated.value = rt;
          var stars = this.getElementsByClassName(styles.ratingStar);

          e.target.style.transform = 'scale(2)';
          var x = setTimeout(() => { e.target.style.transform = 'scale(1)'; }, 300);
          for (i = 0; i < stars.length; i++) {
            if (rt > i) { doStars(true, stars[i], i); }
            else { doStars(false, stars[i], (5 - i)); }
          }

          function doStars(a, b, c) {
            var d = { a: 'fa', o: 'fa-regular' };

            if (a) {
              setTimeout(() => { b.classList.remove(d.o); b.classList.add(d.a); }, (c * 100));
            } else {
              setTimeout(() => { b.classList.remove(d.a); b.classList.add(d.o); }, (c * 100));
            }
          }
        }
      });


      function switchToTab(tab) {
        playbookElm.toolsCheckListTab.classList.add(styles.hide);
        playbookElm.assignmentsTab.classList.add(styles.hide);
        playbookElm.feedbacksTab.classList.add(styles.hide);

        playbookElm.fetchTools.classList.remove(styles.activeLnk);
        playbookElm.fetchAssign.classList.remove(styles.activeLnk);
        playbookElm.fetchFeedback.classList.remove(styles.activeLnk);

        if (tab == 'toolsCheckListTab') {
          playbookElm.toolsCheckListTab.classList.remove(styles.hide);
          playbookElm.fetchTools.classList.add(styles.activeLnk);
        }
        else if (tab == 'assignmentsTab') {
          playbookElm.assignmentsTab.classList.remove(styles.hide);
          playbookElm.fetchAssign.classList.add(styles.activeLnk);
        }
        else if (tab == 'feedbacksTab') {
          playbookElm.feedbacksTab.classList.remove(styles.hide);
          playbookElm.fetchFeedback.classList.add(styles.activeLnk);
        }
      }

      getSessionDetails(pathDetails.sessId);
      function getSessionDetails(sessId) {
        try {
          fetch(globalVars.baseUrl + globalVars.getPBookSesInfo.replace(/{id}/ig, sessId), {
            headers: {
              "Authorization": `Bearer ${resolveToken()}`
            }
          }).then(res => res.json()).then(a => {
            // var a = res.json();
            // console.log('Prom resp', a);
            if (a.error || !a.data || a.data.length != 1) {
              console.log('load Error > :3', a);
              toast.error("Error Occured " + a.error);
              playbookElm.sesTtlTtl.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              playbookElm.sesTtlInfo.innerHTML = ``;
              return 0;
            }

            a = a.data[0];
            playbookElm.sesTtlTtl.innerHTML = tpl.sesTtlTtl.replace(/{pos}/i, a.position).replace(/{ttl}/i, a.name);
            playbookElm.sesTtlInfo.innerHTML = a.info;

            // var listTpl = '';
            // for (var x in a) {
            //   // console.log(x);
            //   listTpl += tpl.playbookSessionList.replace(/{sessionId}/ig, a[x]._id).replace(/{name}/ig, a[x].name).replace(/{pos}/ig, a[x].position);//.replace(/{progress}/ig, a[x].progress).replace(/{status}/ig, a[x].progress);
            // }

            // playbooksList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">No Playbook sessions <br/>  try again later or reach out to your business coach for help</div>`);
            // var playbooksListChild = playbooksList.getElementsByClassName('advisor-list-each');
            // for (var b of playbooksListChild) {
            //   b.addEventListener('click', function (e) {
            //     e.stopImmediatePropagation();
            //     this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
            //     setTimeout(() => history.push(`/dashboard/playbook/b/${playbookId}/c/${chapterId}/s/${this.attributes.sessionId.value}`), 300);
            //   });
            // }
          });


        } catch (er) {
          console.log('load Error > :2', er);
          toast.error("Error Occured !");
        }

        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      }

      getSessionTools(pathDetails.sessId, playbookSelectedPair._id);
      function getSessionTools(sessId, pairId) {
        try {
          fetch(globalVars.baseUrl + globalVars.getPBookToolNCheck.replace(/{sessId}/ig, sessId).replace(/{pairId}/ig, pairId), {
            headers: {
              "Authorization": `Bearer ${resolveToken()}`
            }
          }).then(res => res.json()).then(a => {
            // var a = res.json();
            // console.log('Prom resp', a);
            if (a.error || !a.data) {
              console.log('load Error > :3', a);
              toast.error("Error Occured " + a.error);
              playbookElm.toolsCheckListTab.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            } else if (!a.data.length) {
              console.log('load Error > :4', a);
              playbookElm.toolsCheckListTab.innerHTML = `<div class="${styles.error}">Exeception Occured <br/>  No data available. Try again later</div>`;
              return 0;
            }

            var a = a.data;
            var listTpl = '';
            for (var x in a) {
              listTpl += tpl.sesToolList.replace(/{info}/ig, a[x].info).replace(/{name}/ig, a[x].name).replace(/{img}/ig, a[x].icon).replace(/{value}/ig, a[x].value).replace(/{statusIcon}/ig, (a[x]._isDone ? tpl.sesToolListDone : tpl.sesToolListWait));
            }
            playbookElm.toolsCheckListTab.innerHTML = listTpl;

            // playbooksList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">No Playbook sessions <br/>  try again later or reach out to your business coach for help</div>`);
            // var playbooksListChild = playbooksList.getElementsByClassName('advisor-list-each');
            // for (var b of playbooksListChild) {
            //   b.addEventListener('click', function (e) {
            //     e.stopImmediatePropagation();
            //     this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
            //     setTimeout(() => history.push(`/dashboard/playbook/b/${playbookId}/c/${chapterId}/s/${this.attributes.sessionId.value}`), 300);
            //   });
            // }
          });


        } catch (er) {
          console.log('load Error > :2', er);
          toast.error("Error Occured !");
        }

        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      }

      getSessionTasks(pathDetails.sessId, playbookSelectedPair._id);
      function getSessionTasks(sessId, pairId) {
        try {
          fetch(globalVars.baseUrl + globalVars.getPBookTasks.replace(/{sessId}/ig, sessId).replace(/{pairId}/ig, pairId), {
            headers: {
              "Authorization": `Bearer ${resolveToken()}`
            }
          }).then(res => res.json()).then(a => {
            // var a = res.json();
            // console.log('Prom resp', a);
            if (a.error || !a.data) {
              console.log('load Error > :3', a);
              toast.error("Error Occured " + a.error);
              playbookElm.assignmentList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            } else if (!a.data.length) {
              console.log('load Error > :4', a);
              playbookElm.assignmentList.innerHTML = `<div class="${styles.error}">Nothing yet <br/>  No assignment available. Try again later</div>`;
              return 0;
            }

            var a = a.data;
            var listTpl = '';
            for (var x in a) {
              var tmpFiles = '';
              for (var b in a[x].files) {
                tmpFiles += tpl.sesListFiles.replace(/{link}/ig, 'https://drive.google.com/file/d/' + a[x].files[b]).replace(/{count}/ig, (b + 1))
              }

              listTpl += tpl.sesTaskList.replace(/{topic}/ig, a[x].topic).replace(/{duedate}/ig, new Date(parseFloat(a[x].dueDate)).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', dateStyle: 'long' })).replace(/{task}/ig, a[x].task).replace(/{files}/ig, tmpFiles).replace(/{id}/ig, btoa(JSON.stringify({ id: a[x]._id, topic: a[x].topic, due: a[x].dueDate }))).replace(/{hide}/ig, (a[x].taskResp ? styles.hide : '')).replace(/{resdate}/ig, (a[x].date.response ? new Date(parseFloat(a[x].date.response)).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', dateStyle: 'long' }) : '-'));
            }
            // console.log(listTpl);
            playbookElm.assignmentList.innerHTML = listTpl;

            var listChildren = playbookElm.assignmentList.getElementsByClassName('start-task-reply-btn');
            for (var b of listChildren) {
              b.addEventListener('click', function (e) {
                e.stopImmediatePropagation();
                console.log('id', this.id); //`<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                var payload = JSON.parse(atob(this.id));
                var frm = document.forms.assignmentForm;
                frm.id.value = payload.id;
                frm.dueDate.value = new Date(parseFloat(payload.due)).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', dateStyle: 'long' });
                frm.topic.value = payload.topic;
                playbookElm.assignmentForm.classList.remove(styles.hide);
              });
            }
          });


        } catch (er) {
          console.log('load Error > :2', er);
          toast.error("Error Occured !");
        }
      }

      getSessionFeeds(pathDetails.sessId, playbookSelectedPair._id, user.user_id);
      function getSessionFeeds(sessId, pairId, usrId) {
        try {
          fetch(globalVars.baseUrl + globalVars.getPBookFeeds.replace(/{sessId}/ig, sessId).replace(/{usrId}/ig, usrId), {
            headers: {
              "Authorization": `Bearer ${resolveToken()}`
            }
          }).then(res => res.json()).then(a => {
            // var a = res.json();
            // console.log('Prom resp', a);
            if (a.error || !a.data) {
              console.log('load Error > :3', a);
              toast.error("Error Occured " + a.error);
              playbookElm.feedbackList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            } else if (!a.data.length) {
              console.log('load Error > :4', a);
              playbookElm.feedbackList.innerHTML = `<div class="${styles.error}">Nothing yet <hr/>  No feedback for this session is given yet. <br/>When an advisor gives you feedback, you'll see it here</div>`;
              return 0;
            }

            var a = a.data;
            var listTpl = '';
            for (var x in a) {
              var tmpFiles = '';
              for (var b in a[x].files) {
                tmpFiles += tpl.sesListFiles.replace(/{link}/ig, 'https://drive.google.com/file/d/' + a[x].files[b]).replace(/{count}/ig, (b + 1))
              }

              listTpl += tpl.sesFeedList.replace(/{feed}/ig, a[x].feedback).replace(/{advisor}/ig, `${a[x].from} (${a[x].whoAdded.email})`).replace(/{files}/ig, tmpFiles);
            }
            // console.log(listTpl);
            playbookElm.feedbackList.innerHTML = listTpl;

            // var listChildren = playbookElm.assignmentList.getElementsByClassName('start-task-reply-btn');
            // for (var b of listChildren) {
            //   b.addEventListener('click', function (e) {
            //     e.stopImmediatePropagation();
            //     console.log('id', this.id); //`<b class="${styles.iconLoader}">${tpl.loader}</b>`;
            //     var payload = JSON.parse(atob(this.id));
            //     var frm = document.forms.assignmentForm;
            //     frm.id.value = payload.id;
            //     frm.dueDate.value = new Date(parseFloat(payload.due)).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', dateStyle: 'long' });
            //     frm.topic.value = payload.topic;
            //     playbookElm.assignmentForm.classList.remove(styles.hide);
            //   });
            // }
          });


        } catch (er) {
          console.log('load Error > :2', er);
          toast.error("Error Occured !");
        }
      }

      function addFeedback(frm, payload) {
        try {
          frm.submit.innerHTML = `${renderToStaticMarkup(<LoaderIntro size="1em" color="#fff" class={styles.icon} />)}`;
          fetch(globalVars.baseUrl2 + globalVars.addPBookFeed, {
            headers: {
              // "Authorization": `Bearer ${resolveToken()}`
            },
            body: payload,
            method: 'POST'
          }).then(res => res.json()).then(a => {
            console.log('Prom resp -> ', a);
            frm.submit.innerHTML = `Submit`;
            if (a.error) {
              console.log('load Error > :3', a);
              toast.error("Error Occured " + (a.msg ? a.msg : a.error));
              return 0;
            } else {
              toast.success(a.msg);
              frm.reset();
              document.getElementById('resetRatingStar').click();
              return 0;
            }
          });

        } catch (er) {
          console.log('load Error > :2', er);
          toast.error("Error Occured !");
        }
      }

      function addTaskResp(frm, payload) {
        try {
          frm.submit.innerHTML = `${renderToStaticMarkup(<LoaderIntro size="0.9em" color="#fff" class={styles.icon} />)}`;
          fetch(globalVars.baseUrl2 + globalVars.addTaskResp, {
            headers: {
              // "Authorization": `Bearer ${resolveToken()}`
            },
            body: payload,
            method: 'POST'
          }).then(res => res.json()).then(a => {
            console.log('Prom resp -> ', a);
            frm.submit.innerHTML = `Submit`;
            if (a.error) {
              console.log('load Error > :3', a);
              toast.error("Error Occured " + (a.msg ? a.msg : a.error));
              return 0;
            } else {
              toast.success(a.msg);
              frm.reset();
              return 0;
            }
          });

        } catch (er) {
          console.log('load Error > :2', er);
          toast.error("Error Occured !");
        }
      }


      // getPlaybookSessions(chapter_id_path, play_id_path);
      function getPlaybookSessions(chapterId, playbookId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
              toast.error("Error Occured " + a.error);
              playbooksList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            a = a.data;
            var listTpl = '';
            for (var x in a) {
              // console.log(x);
              listTpl += tpl.playbookSessionList.replace(/{sessionId}/ig, a[x]._id).replace(/{name}/ig, a[x].name).replace(/{pos}/ig, a[x].position);//.replace(/{progress}/ig, a[x].progress).replace(/{status}/ig, a[x].progress);
            }

            playbooksList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">No Playbook sessions <br/>  try again later or reach out to your business coach for help</div>`);
            var playbooksListChild = playbooksList.getElementsByClassName('advisor-list-each');
            for (var b of playbooksListChild) {
              b.addEventListener('click', function (e) {
                e.stopImmediatePropagation();
                this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                setTimeout(() => history.push(`/dashboard/playbook/b/${playbookId}/c/${chapterId}/s/${this.attributes.sessionId.value}`), 300);
              });
            }

          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getPlaybookSessions.replace(/{chapterId}/ig, chapterId));
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }

    }
  });

  // useEffect(() => {
  //   retrieveClientLoans();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      clearError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const placeholderFun = (data) => {
    // loanApply(data, user.user_id);
  };

  const calculateLoan = (data) => {
    loanApply(data, user.user_id);
  };

  const updateAddress = (data) => {
    addAddressForLoan(data, currentLoanId);
  };

  const updateEmployerInfo = (data) => {
    addWorkInfoForLoan(data, currentLoanId);
  };

  const updateBankInfo = (data) => {
    addBankInfoForLoan(data, currentLoanId);
  };

  const resumeApplication = () => {
    clearCompleteState();
    if (loanApplicationStage === "calculated") {
      history.push("/dashboard/consumer-credit/apply/contact-info");
    }
    if (loanApplicationStage === "address_added") {
      history.push("/dashboard/consumer-credit/apply/employer-info");
    }
    if (loanApplicationStage === "employer_added") {
      history.push("/dashboard/consumer-credit/apply/bank-info");
    }
  };

  function handlePreSetsForm() {
    var usrLoc = document.getElementById('locationValue').value;
    if (!usrLoc) {
      document.getElementById('preSetsInfo').innerText = 'Select the location of the hub closest to you';
      toast.error('Select the location of the hub closest to you');
      return 0;
    }
    document.getElementById('preSetsLoader').style.display = 'inline';
    document.getElementById('preSetsLoaderWait').style.display = 'flex';
    document.getElementById('preSetsInfo').innerText = '';
    var tmt = setTimeout(function () { window.location.reload(); }, 1000 * 60 * 3);
    var x = new XMLHttpRequest();
    x.onload = function () {
      document.getElementById('preSetsLoader').style.display = 'none';
      document.getElementById('preSetsLoaderWait').style.display = 'none';
      try { clearTimeout(tmt); } catch (er) { console.log(er); }
      try {
        var a = JSON.parse(x.responseText);
        if (!a.status) {
          console.log('load Error > :3', x.responseText);
          toast.error("Error!! " + a.message);
          document.getElementById('preSetsInfo').innerText = (a.error ? a.error : "Error Occured");
        } else {
          document.getElementById('preSetsParent').style.display = 'none';
          window.location.reload();
        }
        console.log('res', a);
      } catch (er) {
        console.log('load Error > :2', x.responseText, er);
        toast.error("Error!! " + er);
        document.getElementById('preSetsInfo').innerText = ("Error Occured" + er);
      }
    };
    x.onerror = function () {
      try { clearTimeout(tmt); } catch (er) { console.log(er); }
      console.log('load Error > :1', x.responseText);
      toast.error('Error! Try again');
      document.getElementById('preSetsInfo').innerText = 'Error! Try again';
      document.getElementById('preSetsLoader').style.display = 'none';
      document.getElementById('preSetsLoaderWait').style.display = 'none';
    };
    x.open('GET', globalVars.baseUrl + globalVars.getApplicantState.replace(':user_id', user.user_id) + '?location=' + usrLoc);
    x.setRequestHeader("Authorization", `Bearer ` + resolveToken());
    x.send();
  }

  return (
    <>
      <Dashboard sidebarRoutes={clientRoutes} location={location}>
        <ToastContainer position="top-center" />
        <div className={styles.heading}>
          <nav>
            {/* <a clicked={() => history.push('/dashboard/playbook/home')}>Hey</a> */}
            <Button bgColor={window.location.href.split("/").pop() == 'home' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/playbook/home")}>
              <FaHome /> <span className={styles.btnTxt}>Home</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'advisors' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/playbook/advisors")}>
              <FaUserShield /> <span className={styles.btnTxt}>Advisors</span>
            </Button>
            <Button bgColor={(window.location.href.split("/").pop() == 'playbooks' || window.location.pathname.indexOf('/dashboard/playbook/playbook/') === 0 || window.location.pathname.indexOf('/dashboard/playbook/book/') === 0 || window.location.pathname.indexOf('/dashboard/playbook/b/') === 0) ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/playbook/playbooks")}>
              <FaBookReader /> <span className={styles.btnTxt}>Playbooks</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'alerts' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/playbook/alerts")}>
              <FaRegBell /> <span className={styles.btnTxt}>Alerts</span>
            </Button>
          </nav>
        </div>
        <Switch>
          <Route path={`${path}/home`}>
            <div className={styles.preSets} id="preSetsParentB" style={{ display: "none" }}>
              <div>
                <span id="preSetsLoaderA" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '28px' }}>Loading...&nbsp;<LoaderPre /></span>
                <form id="preSetsForm" style={{ display: 'none' }} onSubmit={(e) => { e.preventDefault(); handlePreSetsForm(); }}>
                  <img src={somoLogo} alt="Somo" />
                  <span id="preSetsInfo"></span>
                  <p>Select your Hub location</p>
                  <label for="proposalUpload">
                    <select id="locationValue" name="location" onChange={(e) => { e.preventDefault(); }}>
                      <option value="" selected={true} disabled={true}>Select Location</option>
                      <option value="Nairobi">Nairobi</option>
                      <option value="Kisumu">Kisumu</option>
                      <option value="Mombasa">Mombasa</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Nakuru">Nakuru</option>
                    </select>
                  </label>
                  <button type="submit" onClick={(e) => { e.preventDefault(); handlePreSetsForm(); }}>
                    Save & Continue
                    <span id="preSetsLoader" style={{ display: 'none' }}><LoaderIntro /></span>
                  </button>
                  <div id="preSetsLoaderWait" className={styles.preSetsWait} style={{ display: 'nones' }}>
                    <LoaderIntroWait />
                    <p>
                      <b>Setting Up Your Somo Space...</b><br />
                      This may take a few minutes (2 to 5 mins)<br />
                      Be patient and do not refresh the page.<br />
                      Leave everything unto us.
                    </p>
                    <img src={somoLogo2} alt="Somo" />
                  </div>
                </form>
              </div>
            </div>
            <div className={styles.creditTable} ids="mainWelcomePageHolder" id="myEntrepreneursContainer">
              <canvas id="bubblesCanvas" className={styles.bubblesCanvas}></canvas>
              <div className={styles.welcomeAcc}>
                <h2>Welcome to Somo Advisory Playbook</h2>
                <hr /><p>In this program you'll be paired with an advisor.<br />
                  The advisor will advice in their specialized areas.<br />
                  This playbook will act as a guideline.<br /><br />
                  <strong><i>Note*:</i></strong><br />
                  You cannot access the playbooks unless you are paired with an advisor.<br />
                  If you don't have an advisor yet, reach out to your business coach.<br />
                  Click "View Advisors" below to check advisors paired to you.
                </p>
                <Button
                  bgColor="#cc6328"
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() =>
                    history.push("/dashboard/playbook/advisors")
                  }
                >
                  View Advisors <FaUserShield />
                </Button>
              </div>
            </div>
          </Route>
          <Route path={`${path}/advisors`}>
            <div className={styles.applicationComplete}>
              <h2><FaUserShield size="1.2em" color="#cc6328" /> My Advisors</h2>
              <p style={{ textAlign: 'left' }}> Select an advisor<br /></p>
              <div className={styles.advisorsListHold} style={{ textAlign: 'left' }} id="advisorsList">
                {/* <div className={styles.advisorList}>
                  <FaUserShield size="1.2em" color="#cc6328" className={styles.icon} />
                  <div className={styles.advisorListInfo}>
                    <span>Advisor 1</span>
                    <span>Key Building Blocks</span>
                    <span>Advisor@somoafrica.org</span>
                    <span>+2547XXXXXXXX</span>
                  </div>
                  <input type="checkbox" />
                  <FaCheckCircle className={`${styles.icon} ${styles.iconChecked}`} />
                  <FaCircleNotch className={styles.icon} />
                </div> */}
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                  Loading...&nbsp;<LoaderIntro color="#00a5a2" />
                </span>

              </div>
              {/* <iframe style={{ width: "100%", minHeight: "240px" }} src="https://www.youtube.com/embed/_VfCIEgXePI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              <p style={{ textAlign: 'left', fontStyle: 'italic' }}>Empowering you, to change the world </p>
            </div>
          </Route>
          <Route path={`${path}/playbooks`}>
            <div className={styles.applicationComplete}>
              <h2><FaBookReader size="1.2em" color="#cc6328" /> My Playbooks</h2>
              <p style={{ textAlign: 'left' }}> Select a playbook<br /></p>
              <div className={styles.advisorsListHold} style={{ textAlign: 'left' }} id="playbooksList">
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                  Loading...&nbsp;<LoaderIntro color="#00a5a2" />
                </span>
              </div>
              <p style={{ textAlign: 'left', fontStyle: 'italic' }}>Empowering you, to change the world </p>
            </div>
          </Route>
          <Route path={`${path}/playbook/:play_id`}>
            <div className={styles.applicationComplete}>
              <h2><FaBookReader size="1.2em" color="#cc6328" /> My Playbooks</h2>
              <nav className={styles.playbookNav}>
                <Button bgColor={window.location.href.split("/").pop() == 'playbooks' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/playbook/playbooks")}>
                  <FaArrowCircleLeft />
                </Button>
                <div>Playbook {">"} Chapters</div>
              </nav>
              <p style={{ textAlign: 'left' }}> Select chapter<br /></p>
              <div className={styles.advisorsListHold} style={{ textAlign: 'left' }} id="playbooksListChapter">
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                  Loading...&nbsp;<LoaderIntro color="#00a5a2" />
                </span>
              </div>
              <p style={{ textAlign: 'left', fontStyle: 'italic' }}>Empowering you, to change the world </p>
            </div>
          </Route>
          <Route path={`${path}/book/:play_id/chapter/:chap_id`}>
            <div className={styles.applicationComplete}>
              <h2><FaBookReader size="1.2em" color="#cc6328" /> My Playbooks</h2>
              <nav className={styles.playbookNav}>
                <Button bgColor={window.location.href.split("/").pop() == 'playbooks' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/playbook/playbooks")}>
                  <FaArrowCircleLeft />
                </Button>
                <div>Playbook {">"} Chapter {">"} Sessions</div>
              </nav>
              <p style={{ textAlign: 'left' }}> Select a session<br /></p>
              <div className={styles.advisorsListHold} style={{ textAlign: 'left' }} id="bookChaptListSession">
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                  Loading...&nbsp;<LoaderIntro color="#00a5a2" />
                </span>
              </div>
              <p style={{ textAlign: 'left', fontStyle: 'italic' }}>Empowering you, to change the world </p>
            </div>
          </Route>
          <Route path={`${path}/b/:play_id/c/:chap_id/s/:ses_id`}>
            <div className={styles.sesInfo} id="playbookSessionDetails">
              <h2><FaBookReader size="1.2em" color="#cc6328" /> My Playbooks</h2>
              <nav className={styles.playbookNav}>
                <Button bgColor={window.location.href.split("/").pop() == 'playbooks' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => history.push("/dashboard/playbook/playbooks")}>
                  <FaArrowCircleLeft />
                </Button>
                <div>Playbook {">"} Chapter {">"} Sessions {">"} Session Details</div>
              </nav>
              <main className={styles.sesMain}>
                <aside className={styles.sesAside} id="sesAside">
                  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                    Loading Session list ...&nbsp;<LoaderIntro color="#00a5a2" />
                  </span>
                </aside>
                <section className={styles.sesSection} id="sesSection">

                  <div className={styles.generalPlaybookContainer}>
                    <div className={styles.navLinks}>
                      <a className={`${styles.reportNavLink} ${styles.activeLnk}`} id="fetchTools">Tools</a>
                      <a className={styles.reportNavLink} id="fetchAssign">Assignment</a>
                      <a className={styles.reportNavLink} id="fetchFeedback">Feedback</a>
                    </div>

                    <div className={styles.titleInfo}>
                      <div className={`${styles.ttl}`} id="sesTtlTtl">
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                          Loading Content ...&nbsp;<LoaderIntro color="#00a5a2" />
                        </span>
                        {/* <h3>1.</h3><h3>Effective &amp; timely reporting</h3> */}
                      </div>

                      <div className={`${styles.info}`}>
                        <p id="sesTtlInfo">
                          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                            Loading Content ...&nbsp;<LoaderIntro color="#00a5a2" />
                          </span>
                          {/* During this module, you will work to understand the importance of effective and timely reporting, the tools available through Somo to make record-keeping easier, and how reports can be used for growth. */}
                        </p>
                        <FaChalkboardTeacher size="4em" class={styles.icon} />
                      </div>
                    </div>

                    <div className={`${styles.toolsCheckList}`} id="toolsCheckListTab">
                      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                        Loading Content ...&nbsp;<LoaderIntro color="#00a5a2" />
                      </span>

                      {/* <div id="649a9124a2e8dd9af85c79c6" className={styles.toolCheck}>
                        <img src="https://invest.somoafrica.org/img/playbook/yt.png" />
                        <div className={styles.toolTtl}>
                          <p styles="padding-left: 10px;font-weight:bold;color:#CC6328;">Tutorials on DigiKua</p>
                          <a class="subPlaybookListLinks" href="https://www.youtube.com/watch?v=anTfnD1dOVc&amp;list=PLfJScRTC4Bo0N4-AxnOYmX2PvnuSXq2j9" target="blank">Review DigiKua </a>
                        </div>
                        <div className={styles.checkStatus}>
                          <p>Pending...</p>
                          <FadeLoader size="4em" class={styles.icon} />
                        </div>
                      </div>


                      <div id="649a9125a2e8dd9af85c79c9" className={styles.toolCheck}>
                        <img src="https://invest.somoafrica.org/img/playbook/docs.png" />
                        <div className={styles.toolTtl}>
                          <p styles="padding-left: 10px;font-weight:bold;color:#CC6328;">Importance of timely reporting </p>
                          <a class="subPlaybookListLinks" href="https://docs.google.com/document/u/0/d/1KDXJsVWCKGvlfuB-hzzbjrSQV5NoLjPVIvsTk4-AAec/edit" target="blank">Review DigiKua </a>
                        </div>
                        <div className={styles.checkStatus}>
                          <p>Completed</p>
                          <FaCheckCircle size="4em" class={styles.icon} />
                        </div>
                      </div> */}
                    </div>

                    <div className={`${styles.assignments} ${styles.hide}`} id="assignmentsTab">
                      <div className={styles.taskList} id="assignmentList">
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                          Loading Content ...&nbsp;<LoaderIntro color="#00a5a2" />
                        </span>

                        {/* <div className={styles.task}>
                          <div className={styles.top}>
                            <p><b>Topic : </b><span>Market Research</span></p>
                            <p><b>Due Date : </b><span>Aug 11 2023</span></p>
                          </div>
                          <textarea readonly="">Do During this module, you will work to understand the importance of effective and timely reporting, the tools available through Somo to make record-keeping easier, and how reports can be used for growth.+</textarea>
                          <div className={styles.bottom}>
                            <p className={styles.file}>
                              <b>Files : </b>
                              <a href="hey" target="_blank">File 1</a>
                              <a href="hey" target="_blank">File 2</a>
                              <a href="hey" target="_blank">File 3</a>
                            </p>
                            <button>Reply</button>
                          </div>
                        </div> */}
                      </div>

                      <div className={`${styles.replyTask} ${styles.hide}`} id="assignmentForm">
                        <form action="" method="POST" className={styles.newAssignmentForm} name="assignmentForm">
                          <span className={styles.close} id="closeXformAsign"> Close X</span>
                          <p className={styles.errMsg}></p>

                          <div className={styles.top}>
                            <div className={styles.inputGrp}>
                              <label className={styles.requiredDataAssignments} for="topic">Topic:</label>
                              <input type="text" name="topic" className={styles.topic} id="topic" readonly="" />
                              <input type="hidden" name="id" />
                            </div>
                            <div className={styles.inputGrp}>
                              <label className={styles.requiredDataAssignments} for="dueDate">Due Date:</label>
                              <input type="text" name="dueDate" id="dueDate" readonly="" />
                            </div>
                          </div>

                          <div className={styles.inputGrp}>
                            <label className={styles.requiredDataAssignments} for="task">Answer: </label>
                            <textarea name="response" id="task" cols="20" rows="10" placeholder='Write the answer Here. If you need to submit a file, select it below'></textarea>
                          </div>

                          <div className={styles.top}>
                            <div className={styles.inputGrp}>
                              <label for="file" >Additional File (Optional):</label>
                              <input type="file" name="file[]" id="file" multiple="multiple" />
                            </div>

                            <button type="submit" name="submit" className={styles.assignBtn}>Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className={`${styles.feedbacks} ${styles.hide}`} id="feedbacksTab">
                      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" />
                      <div className={styles.add}>
                        <button id="addFeedBtn"><FaMailBulk size="3em" className={styles.icon} /> <span>Add Feedback</span></button>
                      </div>


                      <div className={styles.feedList} id="feedbackList">
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px' }}>
                          Loading Content ...&nbsp;<LoaderIntro color="#00a5a2" />
                        </span>
                        {/* <div className={styles.feed}>
                          <FaUserShield size="5em" color="#cc6328" className={styles.icon} />
                          <div className={styles.left}>
                            <p>Playbook@somoafrica.org</p>
                            <div className={styles.msg}>Do During this module, you will work to understand the importance of effective and timely reporting, the tools available through Somo to make record-keeping easier, and how reports can be used for growth.+</div>
                            <p className={styles.file}>
                              <b>Files : </b>
                              <a href="hey" target="_blank">File 1</a>
                              <a href="hey" target="_blank">File 2</a>
                              <a href="hey" target="_blank">File 3</a>
                            </p>
                          </div>

                        </div> */}
                      </div>

                      <div className={`${styles.addFeedback} ${styles.hide}`} id="addFeedbackContainer">
                        <form action="" method="POST" className={styles.feedbackForm} id="feedbackForm" name="feedbackForm">
                          <span className={styles.close} id="closeXformFeed">Close X</span>
                          <p className={styles.errMsg}></p>
                          <div className={styles.inputGrp}>
                            <label> Rate Advisor for this Session:</label>
                            <input type="hidden" name="rated" value="0" />
                            <div className={styles.rateContainer} id="rateContainer" required>
                              <i className={`${styles.hide}`} id="resetRatingStar" val='0'></i>
                              <i className={`${styles.ratingStar} fa-regular fa-star`} val='1'></i>
                              <i className={`${styles.ratingStar} fa-regular fa-star`} val='2'></i>
                              <i className={`${styles.ratingStar} fa-regular fa-star`} val='3'></i>
                              <i className={`${styles.ratingStar} fa-regular fa-star`} val='4'></i>
                              <i className={`${styles.ratingStar} fa-regular fa-star`} val='5'></i>
                            </div>
                          </div>

                          <div className={styles.inputGrp}>
                            <label for="feedback">Feedback:</label>
                            <textarea name="feedback" id="feedback" cols="20" rows="10" placeholder='Write your Feedback Here'></textarea>
                          </div>

                          <div className={styles.top}>
                            <div className={styles.inputGrp}>
                              <label for="file2" >Additional File (Optional):</label>
                              <input type="file" name="file[]" id="file2" multiple="multiple" accept="*" />
                            </div>

                            <button type="submit" name="submit">Submit</button>
                          </div>
                        </form >
                      </div >
                    </div >
                  </div >
                </section >
              </main >
              <p style={{ textAlign: 'left', fontStyle: 'italic' }}>Empowering you, to change the world </p>
            </div >
          </Route >
          <Route path={`${path} /welcome`}>
            <div className={styles.preSets} id="preSetsParent">
              <div>
                <span id="preSetsLoaderA" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '28px' }}>Loading...&nbsp;<LoaderPre /></span>
                <form id="preSetsForm" style={{ display: 'none' }} onSubmit={(e) => { e.preventDefault(); handlePreSetsForm(); }}>
                  <img src={somoLogo} alt="Somo" />
                  <span id="preSetsInfo"></span>
                  <p>Select your Hub location</p>
                  <label for="proposalUpload">
                    <select id="locationValue" name="location" onChange={(e) => { e.preventDefault(); }}>
                      <option value="" selected={true} disabled={true}>Select Location</option>
                      <option value="Nairobi">Nairobi</option>
                      <option value="Kisumu">Kisumu</option>
                      <option value="Mombasa">Mombasa</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Nakuru">Nakuru</option>
                    </select>
                  </label>
                  <button type="submit" onClick={(e) => { e.preventDefault(); handlePreSetsForm(); }}>
                    Save & Continue
                    <span id="preSetsLoader" style={{ display: 'none' }}><LoaderIntro /></span>
                  </button>
                  <div id="preSetsLoaderWait" className={styles.preSetsWait} style={{ display: 'nones' }}>
                    <LoaderIntroWait />
                    <p>
                      <b>Setting Up Your Somo Space...</b><br />
                      This may take a few minutes (2 to 5 mins)<br />
                      Be patient and do not refresh the page.<br />
                      Leave everything unto us.
                    </p>
                    <img src={somoLogo2} alt="Somo" />
                  </div>
                </form>
              </div>
            </div>
            <div className={styles.creditTable} id="mainWelcomePageHolder">
              <div className={styles.welcomeAcc}>
                <h2>Welcome to Somo Buruka Program</h2>
                <img src={welcomeToAcc} alt="Welcome to Somo Buruka Program" style={{ maxHeight: "250px" }} />
                <hr /><p>Congratulations for making it this far. It's time to submit your application.<br />
                  Here's what you need to submit to complete your application process.
                </p>
                <ol className={styles.steps} id="questionsOverview">
                  <LoaderIntro />
                  {/* <li>Question 1</li> */}
                </ol>
                <i>
                  *
                </i>
                <Button
                  bgColor="#cc6328"
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() =>
                    history.push("/dashboard/applicant/apply/start")
                  }
                >
                  Start Now <FaRocket />
                </Button>
              </div>
            </div>
          </Route>
          <Route path={`${path}/---future---`}>
            <div className={styles.creditTable}>
              {/* <Table striped hover className={styles.tableStyles}>
                <thead>
                  <tr>
                    <th>Loan ID</th>
                    <th>Monthly Repayment</th>
                    <th>Loan Fund</th>
                    <th>Status</th>
                    <th>Requested Amount</th>
                    <th>Balance</th>
                    <th>Approved Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.map((loanInstance, idx) => {
                    let loanBalance = loanInstance?.repayment
                      .filter((repaid) => {
                        return repaid.status === true;
                      })
                      .reduce((acc, curr) => {
                        return curr.scheduledAmount + acc;
                      }, 0);

                    loanBalance = loanInstance?.approvedAmount - loanBalance;

                    let overpayment = loanInstance?.repayment
                      .map((repayment) => repayment.penaltyFee)
                      .reduce((acc, curr) => {
                        return acc + curr;
                      }, 0);

                    return (
                      <tr key={idx}>
                        <td>{loanInstance._id.substring(0, 5)}</td>
                        <td>{`Ksh ${numberWithCommas(
                          (loanInstance.monthlyRepayment ? loanInstance.monthlyRepayment : '--')
                        )}`}</td>
                        <td>
                            {loanInstance.loanFundName}
                        </td>
                        <td>{_.startCase(loanInstance.status)}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.amount)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanBalance)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.approvedAmount)}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {(!loans || loans.length === 0) && (
                <div className={styles.noLoanMessage}>
                  <p>Sorry you currently have no loan</p>
                  <img src={noLoan} alt="No loan history" height="250" />
                  <TiCancelOutline
                    className={styles.mobileNoLoan}
                    size="6em"
                    color="rgba(116, 23, 99, 0.6)"
                  />
                </div>
              )} */}

              <div className={styles.noLoanMessage}>
                <p>Sorry you currently have no loan</p>
                <img src={noLoan} alt="No loan history" height="250" />
                <TiCancelOutline
                  className={styles.mobileNoLoan}
                  size="6em"
                  color="rgba(116, 23, 99, 0.6)"
                />
              </div>
            </div>
          </Route>
          <Route path={`${path}/apply`}>
            <main className={styles.applyContainer} id="mainApplyPageHolder">
              <Row>
                <Col md={4}>
                  <ul className={styles.joinedBullets} id="questionsListSteps">
                    <LoaderIntro />
                  </ul>
                </Col>
                <Col md={8}>
                  <div className={styles.applyForm}>
                    <Switch>
                      <Route path={`${path}/apply/:question`}>
                        <ApplicantUniUploadForm delegateApply={calculateLoan} />
                      </Route>
                    </Switch>
                  </div>
                </Col>
              </Row>
            </main>
          </Route>
          <Route path={`${path}/success`}>
            <div className={styles.applicationComplete}>
              <FaCheckCircle size="4em" color="#cc6328" />
              <h2>Onboarding Complete!</h2>
              <p>
                Your onboarding is complete.
              </p>
              <p><a href="/dashboard/overview" style={{ padding: '7px', display: 'inline-block', border: '2px solid #007bff', margin: '14px auto', borderRadius: '5px' }}>Go to my dashboard</a></p>
            </div>
          </Route>
          <Route path={`${path}/too_late`}>
            <div className={styles.applicationComplete}>
              <FaBomb size="4em" color="#cc6328" />
              <h2>Too Late!</h2>
              <p style={{ textAlign: 'left' }}>
                We're sorry to notify you that your Buruka Submission cannot proceed since the deadline has passed.
              </p>
              <p style={{ textAlign: 'left' }}>For more information: </p>
              <ul style={{ textAlign: 'left' }}>
                <li>reach out to your mentor or trainer </li>
                <li>or send an email to <a href="mailto:trainers@somoafrica.org">Trainers@somoafrica.org</a> </li>
                <li>or visit your nearest <a href="https://www.somoafrica.org/contacts" target="_">Somo Hub.</a></li>
              </ul>
            </div>
          </Route>
          <Route path={`${path}/approvals`}>
            <div className={styles.applicationComplete}>
              <div style={{ position: "relative", width: "64px", margin: "auto" }}>
                <FaStopwatch size="4em" color="#cc6328" />
                <span style={{ position: "absolute", left: "0", right: "0", top: 0, bottom: "1px", transform: "scale(1.4)", display: "flex", justifyContent: "center", alignItems: "flex-end", color: "#000000", border: "2px dotted #cc6328", borderRadius: "50%" }}><LoaderPre color="#ffffff" /></span>
              </div>
              {/* <h2>Awaiting Judging!</h2> */}
              <h2>Submission Successful</h2>
              <p style={{ textAlign: 'left' }}>
                Your submission was successful and advanced to the judging round.<br />
                For now, be calm and just wait.<br />
              </p>
              <p style={{ textAlign: 'left' }}>Empowering you, to change the world </p>
              <ol style={{ textAlign: 'left' }} id="stepsAwaitingApproval">
                {/* <li></li> */}
              </ol>
            </div>
          </Route>
          <Route path={`${path}`}>
            <div className={styles.applicationComplete}>
              <FaUserShield size="4em" color="#cc6328" />&nbsp;
              <FaBookReader size="4em" color="#cc6328" />
              <h2>Somo Advisory Playbook</h2>
              <p style={{ textAlign: 'left' }}> No page Selected.<br /> Click either link below for a quick start. </p>
              <ul style={{ textAlign: 'left' }} ids="stepsAwaitingApproval">
                <li><a href="/dashboard/playbook/home">Playbook Home</a></li>
                <li><a href="/dashboard/playbook/advisors">Advisors</a></li>
                <li><a href="/dashboard/playbook/playbooks">Playbooks</a></li>
                <li><a href="/dashboard/playbook/alerts">Alerts</a></li>
              </ul>
              {/* <iframe style={{ width: "100%", minHeight: "240px" }} src="https://www.youtube.com/embed/_VfCIEgXePI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              <p style={{ textAlign: 'left', fontStyle: 'italic' }}>Empowering you, to change the world </p>
            </div>
          </Route>
        </Switch >
      </Dashboard >
      <Modal
        className={styles.continueModal}
        size="sm"
        show={incomplete}
        onHide={() => {
          clearCompleteState();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Continue Application</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have an incomplete loan request, please continue where you left
            off
          </p>
          <Button
            clicked={resumeApplication}
            fullWidth
            className="mt-4"
            bgColor="#cc6328"
            size="sm"
            color="#EBEBEB"
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Playbook;
