import { getDefaultNormalizer } from "@testing-library/react";
import React from "react";
import styles from "./DigikuaButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const DigikuaButton = ({
    children,
    className,
    fullWidth,
    clicked,
    bgColor,
    size,
    color,
    disabled,
    loading,
    fontSize,
    margin,
}) => {
    const buttonStyle = {
        width: fullWidth ? "100%" : getDefaultNormalizer,
        backgroundColor: bgColor,
        //padding: size === "lg" ? "6px 12px" : "8px 18px",
        color: color,
        fontSize: fontSize,
        margin: margin,
    };

    return (
        <button
            className={styles.button}
            style={buttonStyle}
            onClick={clicked}
            disabled={disabled}
        >
            {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin mr-3" />}
            {loading ? "Loading..." : children}
        </button>
    );
};

export default DigikuaButton;
