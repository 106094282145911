import React from "react";
import styles from "./Alerts.module.scss";
import { FaBell } from "react-icons/fa";
import { ReactComponent as Bell } from "../../assets/icons/bell.svg";

const Alerts = () => {
  const alerts = [
    {
      id: 0,
      type: "success",
      message: "Assignment submitted successfully",
    },
    {
      id: 2,
      type: "warning",
      message: "There was a problem with your submission",
    },
    {
      id: 3,
      type: "informative",
      message: "A new assignment was added to your learning.",
    },
    { id: 4, type: "success", message: "Assignment submitted successfully" },
    {
      id: 5,
      type: "warning",
      message: "There was a problem with your submission",
    },
    {
      id: 6,
      type: "informative",
      message: "A new assignment was added to your learning.",
    },
  ];

  const getBgColor = (type) => {
    if (type === "informative") {
      return "#FFC83B";
    } else if (type === "success") {
      return "#00A5A2";
    } else if (type === "warning") {
      return "#C4233C";
    } else {
      return "#ffffff";
    }
  };
  return (
    <div className={styles.alertsBody}>
      <div className={styles.topBar}>
        <div className={styles.header}>
          <Bell className={styles.icon} />
          <h2>Alerts</h2>
        </div>
      </div>
      <div className={styles.alertsBody}>
        <div className={styles.alertBody}>
          {alerts.map((alert) => (
            <div
              className={styles.alert}
              style={{
                backgroundColor: `${getBgColor(alert.type)}1A`,
                color:
                  alert.type === "informative"
                    ? "#CC6328"
                    : getBgColor(alert.type),
                borderLeft: `3px solid ${getBgColor(alert.type)}`,
              }}
            >
              <p>{alert.message}</p>
            </div>
          ))}
          {alerts.map((alert) => (
            <div
              className={styles.alert}
              style={{
                backgroundColor: `${getBgColor(alert.type)}1A`,
                color:
                  alert.type === "informative"
                    ? "#CC6328"
                    : getBgColor(alert.type),
                borderLeft: `3px solid ${getBgColor(alert.type)}`,
              }}
            >
              <p>{alert.message}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Alerts;
