import React, { useRef, useEffect } from "react";
import styles from "./LoanProposalForm.module.scss";
import Button from "../Button/Button";
import FileUploadButton from "../FileUploadButton/FileUploadButton";
import { FaCloudUploadAlt } from "react-icons/fa";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
import { toast, ToastContainer } from 'react-toastify';
import { Link, useHistory, useLocation } from "react-router-dom";

const LoanProposalForm = ({ submit }) => {
  const passportFileRef = useRef();
  var loading = false;
  const history = useHistory();
  useEffect(()=>{
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetLoanState();      
    // });
    if(document.getElementById('proposalUpload')){
      document.getElementById('proposalUpload').addEventListener('change', function(e){
        e.stopImmediatePropagation();
        // console.log('chg>>', this, this.files, user);
        // try{
        //   document.getElementById('previewImg').src = URL.createObjectURL(this.files[0]);
        // } catch(er){
        //   console.log('img prevw>', er);
        // }
      });
      document.getElementById('proposalUpload').setAttribute('accept', 'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats', 'officedocument.wordprocessingml.document');
    }
    checkAndGetLoanState();  

    function checkAndGetLoanState(){
      var y = new XMLHttpRequest();
      y.onload = function(){
        try{
          var a = JSON.parse(y.responseText);
          if(a.error){
            // console.log('load Error > :3', y.responseText);
            toast.error(a.message ? a.message : "Error");
          }
          console.log('usr', a.user.user_id, a);
          if(a.user.user_id){
            getCurrentLoanState(a.user.user_id);
          } else {
            // toast.error(a.message ? a.message : "User read error");
          }
        } catch(er){
          console.log('load Error > :2', y.responseText, er);
        }
      };
      y.onerror = function(){
        console.log('load Error > :1', y.responseText);
      };
      y.open('GET', globalVars.baseUrl + globalVars.usrUnbox);
      y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      y.send();

      function getCurrentLoanState(usrId){
        var y = new XMLHttpRequest();
        y.onload = function(){
          try{
            var a = JSON.parse(y.responseText);
            if(a.error){
              console.log('load Error > :3', y.responseText);
            }
            console.log('usr', a);
            if(a.data.path){
              if(window.location.pathname != a.data.path){
                toast.info("Continue Loan application");
                // history.push(a.data.path);
                window.location.href = a.data.path;
              }
            } else {
            }
          } catch(er){
            console.log('load Error > :2', y.responseText, er);
          }
        };
        y.onerror = function(){
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getLoanState.replace(':user_id', usrId) + '?start=1');
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    };
  });

  const handleSubmit = () => {
    const passportFile = passportFileRef.current.files[0];
    console.log('>>proposal', loading, passportFile);
    var loadingOff = document.getElementById('loading-off');
    var loadingOn = document.getElementById('loading-on');
    var frm = new FormData(document.getElementById('proposalUploadForm'));
    frm.append('file', passportFile);
    if(!passportFile){
      toast.error("Select your loan proposal to continue");
      return 0;
    }
    loadingOff.style.display = 'none';
    loadingOn.style.display = 'initial';

    
    uploadProposal(frm);
    function uploadProposal(formData){
      var z = new XMLHttpRequest();
      z.onload = function(){
        try{
          var a = JSON.parse(z.responseText);
          if(a.error){
            console.log('load Error > :3', z.responseText);
            handelFormResp(false, z.responseText);
          } else handelFormResp(true, a.data);
        } catch(er){
          console.log('load Error > :2', z.responseText, er);
          handelFormResp(false, z.responseText);
        }
      };
      z.onerror = function(){
        console.log('load Error > :1', z.responseText);
        handelFormResp(false, z.responseText);
      };
      z.open('POST', globalVars.uploadUrl);
      // z.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      z.send(formData);
    }
    function handelFormResp(status, data){
      if(!status){
        console.log('Error>>');
        var err = (typeof data == 'string' ? JSON.parse(data) : data);
        loadingOff.style.display = 'initial';
        loadingOn.style.display = 'none';
        toast.error("Upload Failed, Try again");
        return 0;
      } else if(data){
        console.log('proceed>', data);
        var y = new XMLHttpRequest();
        y.onload = function(){
          try{
            var a = JSON.parse(y.responseText);
            if(a.error){
              console.log('load Error > :3', y.responseText);
              toast.error(a.message ? a.message : "Error");
            }
            console.log('usr', a.user.user_id, a);
            if(a.user.user_id){
              submitProposalLink(a.user.user_id);
            } else {
              toast.error(a.message ? a.message : "User read error");
            }
          } catch(er){
            console.log('load Error > :2', y.responseText, er);
            toast.error(a.message ? a.message : "Response error");
          }
        };
        y.onerror = function(){
          console.log('load Error > :1', y.responseText);
          var a = JSON.parse(y.responseText);
          toast.error(a.message ? a.message : "Failed to get user details");
        };
        y.open('GET', globalVars.baseUrl + globalVars.usrUnbox);
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();

        function submitProposalLink(usrId){
          var y = new XMLHttpRequest();
          y.onload = function(){
            try{
              var a = JSON.parse(y.responseText);
              if(a.error){
                console.log('load Error > :3', y.responseText);
                handelPropResp(false, y.responseText);
              } else {
                handelPropResp(true, a.data);
              }
            } catch(er){
              console.log('load Error > :2', y.responseText, er);
              handelPropResp(false, y.responseText);
            }
          };
          y.onerror = function(){
            console.log('load Error > :1', y.responseText);
            handelPropResp(false, y.responseText);
          };
          y.open('POST', globalVars.baseUrl + globalVars.addProposalUrl.replace(':user_id', usrId));
          y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
          y.setRequestHeader("Content-Type", "application/json");
          y.send(JSON.stringify({proposalLink:data}));

          function handelPropResp(status, resp){
            if(!status){
              var err = (typeof resp == 'string' ? JSON.parse(resp) : false);
              resp = err ? err : resp;
              console.log('Error>>');
              loadingOff.style.display = 'initial';
              loadingOn.style.display = 'none';
              toast.error(resp.error ? resp.error : "Failed to save proposal link, Try again");
              return 0;
            } else {
              console.log(resp);
              history.push("/dashboard/consumer-credit/apply/fund");
            }
            
          }
        }
      } else {
        toast.error("Upload may have Failed, Unknown response");
      }      
    }
    // submit(idFile, passportFile, idType);
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.loanProposalForm}>
      <div className={styles.passportVerify}>
        <h3>Upload Loan Proposal</h3>
        <p className={styles.info}>
          <a href="/Loan Guidelines and Proposal Outline.pdf" target="_blank" className={styles.downloadLink} download>Download our Loan Guidelines and Proposal Outline here</a><br/>
          Go through it and If you accept the terms, create your loan proposal<br/>
          Then Upload your proposal to continue with your loan application.
        </p>
        <form id="proposalUploadForm">
          <FileUploadButton
            label="Choose File"
            icon={<FaCloudUploadAlt className="ml-3" size="1.1em" />}
            id="proposalUpload"
            fileRef={passportFileRef}
          />
        </form>
      </div>
      <Button
        className="mt-4"
        fullWidth
        bgColor="#cc6328"
        size="lg"
        color="#EBEBEB"
        clicked={handleSubmit}
        disabled={loading}
      loading={loading}
      >
        <span id="loading-off">Save & Continue</span>
        <span id="loading-on" style={{display:"none"}}><Loader/></span>
      </Button>
    </div>
  );
};

export default LoanProposalForm;
