import React from 'react';
import styles from './Card.module.scss'

const Card = ({ name, score, fill, borderColor, icon }) => {
    const cardStyle = {
        backgroundColor: fill,
        borderColor: borderColor,
    };
    return (
        <div className={styles.card} style={cardStyle}>
            <div>
                <p className={styles.name}>{name}</p>
                <p>{icon}</p>
                <p className={styles.score}>{score ? score : "Loading..."}</p>
            </div>
        </div>
    );
};

export default Card;
