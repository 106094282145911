import React, { useState } from "react";
import styles from "./NavBar.module.scss";
import Logo from "../../assets/logo.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import pageUrl from "../../routes/pageUrl";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiCloseFill } from "react-icons/ri";

const NavBar = () => {
  const location = useLocation();
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className={styles.navContainer}>
      <Link to={pageUrl.HOMEPAGE}>
        <img src={Logo} alt="Somo Logo" />
      </Link>
      <div className={styles.navGroup}>
        <ul className={styles.authNav}>
          {/* <li>
            <Link to={pageUrl.SIGNIN_PAGE}>Log In</Link>
          </li>
          <button
            className={styles.menuBtn}
            onClick={() => history.push(pageUrl.SIGNUP_PAGE)}
          >
            Sign Up
          </button> */}
          <button
            className={styles.menuBtn}
            onClick={() => history.push(pageUrl.SIGNIN_PAGE)}
          >
            Login
          </button>
        </ul>
        <ul className={styles.mainNav}>
          <li>
            {/* <Link
              // className={
              //   pageUrl.PRODUCTS_PAGE.includes(location.pathname?.split("/")[1])
              //     ? styles.activeMenu
              //     : null
              // }
              to="/path"
            >
              Internal Link
            </Link> */}
            <a href="https://www.somoafrica.org/">SomoAfrica.org</a>
          </li>
          <li>
            <a href="https://www.somoafrica.org/shop">Shop</a>
          </li>
          <li>
            <a href="https://invest.somoafrica.org/">Invest</a>
          </li>
          {/* <li>
            <a href="https://www.somoafrica.org/blog">Blog</a>
          </li> */}
          <li>
            <a href="https://www.somoafrica.org/somo-team">Team</a>
          </li>
          <li>
            <a href="https://www.somoafrica.org/apply">Apply</a>
          </li>
          <li>
            <a href="https://www.somoafrica.org/contacts">Contact Us</a>
          </li>
          <li>
            <a href="https://www.somoafrica.org/donate" className={styles.donate}>Donate</a>
          </li>
        </ul>
      </div>
      <GiHamburgerMenu
        size="1.8em"
        className={styles.menuIcon}
        onClick={() => setMenuOpen(true)}
        color="#cc6328"
      />
      <div
        className={styles.mobileNav}
        style={{ width: menuOpen ? "100%" : 0 }}
      >
        <RiCloseFill
          size="2em"
          className={styles.closeIcon}
          onClick={() => setMenuOpen(false)}
        />
        {/* <Link
          style={{ opacity: menuOpen ? 1 : 0 }}
          to={`/products/consumer-credit`}
        >
          Our Products
        </Link> */}
        <Link style={{ opacity: menuOpen ? 1 : 0 }} to={pageUrl.SIGNIN_PAGE}>
          Log In
        </Link>
        {/* <Link style={{ opacity: menuOpen ? 1 : 0 }} to={pageUrl.SIGNUP_PAGE}>
          Sign Up
        </Link> */}
        <ul className={styles.mainNav}>
            {/* <Link
              // className={
              //   pageUrl.PRODUCTS_PAGE.includes(location.pathname?.split("/")[1])
              //     ? styles.activeMenu
              //     : null
              // }
              to="/path"
            >
              Internal Link
            </Link> */}
            <a href="https://www.somoafrica.org/">SomoAfrica.org</a>
            <a href="https://www.somoafrica.org/shop">Shop</a>
            <a href="https://invest.somoafrica.org/">Invest</a>
            {/* <a href="https://www.somoafrica.org/blog">Blog</a> */}
            <a href="https://www.somoafrica.org/somo-team">Team</a>
            <a href="https://www.somoafrica.org/apply">Apply</a>
            <a href="https://www.somoafrica.org/contacts">Contact Us</a>
            <a href="https://www.somoafrica.org/donate" className={styles.donate}>Donate</a>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
