import React, { useContext, useEffect, PureComponent, useState } from "react";
import styles from "./Scorecardv1.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import SearchScores from "../../components/SeachScores/SearchScores";
import Card from "../../components/SearchScoresCard/Card";
import { Context as AuthContext } from "../../context/AuthContext";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import {
  FaHandshake,
  FaHourglassStart,
  FaSitemap,
  FaCogs,
  FaIdCard,
} from "react-icons/fa";
import { Context as UserContext } from "../../context/UserContext";
import noLoan from "../../assets/no-loan.png";
import Legend from "../../components/ScorecardLegend/ScoreCardLegend";
import GaugeChart from "../../components/GaugeChart/GaugeChart";

// import { useLocation } from "react-router-dom";
import { clientRoutes } from "../../routes/sidebarRoutes";
import { months } from "moment";
import { TiCancelOutline } from "react-icons/ti";
import LoaderIntro from "react-spinners/SyncLoader";

const Scorecardv1 = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [businessId, setBusinessId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [months, setMonths] = useState("");
  const [businessInfo, setBusinessInfo] = useState("");
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [clickedText, setClickedText] = useState("");
  const [yearOptions, setYearOptions] = useState([]);
  const [quarterOptions, setQuarterOptions] = useState([]);
  const [allScores, setAllScores] = useState([]);
  const [allScoresAverage, setAllScoresAverage] = useState("");
  const [impactData, setImpactData] = useState("");
  const [financialResults, setFinancialResults] = useState("");
  const [participation, setParticipation] = useState("");
  const [loanRepayment, setLoanRepayment] = useState("");
  const [dataFound, setDataFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  const currentQuarter = Math.ceil(currentMonth / 3);

  useEffect(() => {
    const populateYearSelect = () => {
      const startYear =
        currentQuarter === 1 ? currentYear - 6 : currentYear - 5;
      const endYear = currentQuarter === 1 ? currentYear - 1 : currentYear;
      const years = [];

      for (let i = endYear; i >= startYear; i--) {
        years.push(i);
      }

      setYearOptions(years);
    };

    const populateQuarterSelect = () => {
      const quarters = ["Q1", "Q2", "Q3", "Q4"];
      setQuarterOptions(
        currentYear === date.getFullYear()
          ? quarters.slice(0, currentQuarter - 1)
          : quarters
      );
    };

    populateYearSelect();
    populateQuarterSelect();
  }, [currentQuarter, currentYear]);

  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value, 10);
    const quarters = ["Q1", "Q2", "Q3", "Q4"];

    setQuarterOptions(
      selectedYear === currentYear
        ? quarters.slice(0, currentQuarter - 1)
        : quarters
    );
  };

  const [userEmail, setUserEmail] = useState("");

  const {
    state: { user },
  } = useContext(AuthContext);

  const {
    state: { userDetails },
    getClientDetails,
  } = useContext(UserContext);

  useEffect(() => {
    getClientDetails(user.user_id);
  }, []);

  useEffect(() => {
    if (userDetails) {
      const { bioData } = userDetails;
      setUserEmail(bioData.email);
    }
  }, [userDetails]);

  useEffect(() => {
    if (userEmail) {
      const formData = new FormData();

      formData.append("readByEmailOrId", userEmail);

      const requestOptions = {
        method: "POST",
        headers: {},
        body: formData,
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=business&action=readByEmailOrId",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setBusinessId(data.business[0].id);
          setBusinessName(data.business[0].displayName);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("I am not boarding");
    }
  }, [userEmail]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  useEffect(() => {
    const filterData = {
      businessId: businessId,
    };

    const requestOptions = {
      method: "POST",
      headers: {},
      body: JSON.stringify(filterData),
    };

    fetch(
      "https://space.somoafrica.org/api.php?resource=scorecard&action=read",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        data.count === 0 ? setDataFound(false) : setDataFound(true);
        const lastData = data.data[[data.count] - 1];
        const months = lastData.business.months;
        setMonths(months);
        setAllScores(data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, [businessId]);

  useEffect(() => {
    if (!allScores?.data) {
      console.error("Error: Data not available yet.");
      return null;
    }
    const sumOfScores = allScores.data.reduce((accumulator, entry) => {
      const averageScore = parseFloat(entry.averageScore);
      return accumulator + averageScore;
    }, 0);

    const numberOfDataPoints = allScores.data.length;
    const averageScore = sumOfScores / numberOfDataPoints;
    const avgScore = parseFloat(averageScore.toFixed(2));
    setAllScoresAverage(avgScore);
  }, [allScores]);

  useEffect(() => {
    if (!allScores?.data) {
      console.error("Error: Data not available yet.");
      return null;
    }
    const financialResultsScores = allScores.data.map(
      (entry) => entry.financialResults
    );

    function calculateAverage(scores) {
      if (scores.length === 0) return 0;
      const totalScore = scores.reduce(
        (sum, score) => sum + parseFloat(score),
        0
      );
      return totalScore / scores.length;
    }

    const allFinancialResultsScores = Object.values(
      financialResultsScores
    ).reduce((acc, curr) => {
      const scores = Object.values(curr).map((data) => parseFloat(data.score));
      return acc.concat(scores);
    }, []);

    const averageFinancialResultsScore = calculateAverage(
      allFinancialResultsScores
    );
    setFinancialResults(averageFinancialResultsScore);
  }, [allScores]);

  useEffect(() => {
    if (!allScores?.data) {
      console.error("Error: Data not available yet.");
      return null;
    }
    const impactDataScores = allScores.data.map((entry) => entry.impactData);

    function calculateAverage(scores) {
      if (scores.length === 0) return 0;
      const totalScore = scores.reduce(
        (sum, score) => sum + parseFloat(score),
        0
      );
      return totalScore / scores.length;
    }

    const allImpactDataScores = Object.values(impactDataScores).reduce(
      (acc, curr) => {
        const scores = Object.values(curr).map((data) =>
          parseFloat(data.score)
        );
        return acc.concat(scores);
      },
      []
    );

    const averageImpactDataScores = calculateAverage(allImpactDataScores);
    setImpactData(averageImpactDataScores);
  }, [allScores]);

  useEffect(() => {
    if (!allScores?.data) {
      console.error("Error: Data not available yet.");
      return null;
    }
    const participationInAccelerationScores = allScores.data.map(
      (entry) => entry.participationInAcceleration
    );

    function calculateAverage(scores) {
      if (scores.length === 0) return 0;
      const totalScore = scores.reduce(
        (sum, score) => sum + parseFloat(score),
        0
      );
      return totalScore / scores.length;
    }

    const allParticipationInAccelerationScores = Object.values(
      participationInAccelerationScores
    ).reduce((acc, curr) => {
      const scores = Object.values(curr).map((data) => parseFloat(data.score));
      return acc.concat(scores);
    }, []);

    const averageParticipationInAccelerationScores = calculateAverage(
      allParticipationInAccelerationScores
    );
    setParticipation(averageParticipationInAccelerationScores);
  }, [allScores]);

  useEffect(() => {
    if (!allScores?.data) {
      console.error("Error: Data not available yet.");
      return null;
    }
    const loanRepaymentScores = allScores.data.map(
      (entry) => entry.loanRepayment
    );

    function calculateAverage(scores) {
      if (scores.length === 0) return 0;
      const totalScore = scores.reduce(
        (sum, score) => sum + parseFloat(score),
        0
      );
      return totalScore / scores.length;
    }

    const allLoanRepaymentScores = Object.values(loanRepaymentScores).reduce(
      (acc, curr) => {
        const scores = Object.values(curr).map((data) =>
          parseFloat(data.score)
        );
        return acc.concat(scores);
      },
      []
    );

    const averageLoanRepaymentScores = calculateAverage(allLoanRepaymentScores);
    setLoanRepayment(averageLoanRepaymentScores);
  }, [allScores]);

  return (
    <>
      <Dashboard sidebarRoutes={clientRoutes} location={location}>
        <div className={styles.componentContainer}>
          {isLoading ? (
            <div className={styles.scoresLoading}>
              <h2>
                <FaIdCard /> Scorecard
              </h2>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "100px",
                }}
              >
                Loading&nbsp;
                <LoaderIntro color="#00a5a2" />
              </span>
              <p style={{ fontStyle: "italic" }}>
                Empowering you, to change the world
              </p>
            </div>
          ) : dataFound ? (
            financialResults && loanRepayment && impactData && participation ? (
              <>
                <SearchScores bizId={businessId} bizName={businessName} />
                <div className={styles.piechart}>
                  <div className={styles.pieChartContainer}>
                    <div className={styles.scoresPieChart}>
                      <GaugeChart
                        value={parseFloat(allScoresAverage).toFixed(2)}
                        months={months}
                      />
                    </div>
                    <div className={styles.legend}>
                      <Legend bgColor="#C4233C" color="#C4233C" name="Bad" />
                      <Legend
                        bgColor="#CC6328"
                        color="#CC6328"
                        name="Average"
                      />
                      <Legend bgColor="#FFC83B" color="#FFC83B" name="Good" />
                      <Legend
                        bgColor="#00A5A2"
                        color="#00A5A2"
                        name="Excellent"
                      />
                    </div>
                  </div>
                  <div className={styles.buttoncontainer}>
                    <div>
                      <label for="score">Average Score:</label>
                    </div>
                    <div>
                      <button className={styles.button}>
                        {parseFloat(allScoresAverage).toFixed(2)}
                      </button>
                    </div>
                  </div>
                </div>

                <div className={styles.cardContainer}>
                  <div className={styles.top}>
                    <Card
                      name="FINANCIAL SCORE"
                      borderColor="#FFC83B"
                      fill="#fff"
                      icon={<FaHandshake size="4em" color="#FFC83B" />}
                      score={parseFloat(financialResults).toFixed(2)}
                    />
                    <Card
                      name="BORROWING SCORE"
                      borderColor="#C4233C"
                      fill="#fff"
                      icon={<FaSitemap size="4em" color="#C4233C" />}
                      score={parseFloat(loanRepayment).toFixed(2)}
                    />
                  </div>
                  <div className={styles.bottom}>
                    <Card
                      name="IMPACT SCORE"
                      borderColor="#CC6328"
                      fill="#fff"
                      icon={<FaCogs size="4em" color="#CC6328" />}
                      score={parseFloat(impactData).toFixed(2)}
                    />
                    <Card
                      name="ADVISORY SCORE"
                      borderColor="#00A5A2"
                      fill="#fff"
                      icon={<FaHourglassStart size="4em" color="#00A5A2" />}
                      score={parseFloat(participation).toFixed(2)}
                    />
                  </div>
                </div>
              </>
            ) : (
              // Handle case when some data is missing
              <p>Some data is missing</p>
            )
          ) : (
            // Handle case when dataFound is false
            <>
              <div className={styles.noScoresMessage}>
                <p>Sorry you currently have no scores</p>
                <img src={noLoan} alt="No score history" height="250" />
                <TiCancelOutline
                  className={styles.mobileNoScores}
                  size="6em"
                  color="rgba(116, 23, 99, 0.6)"
                />
              </div>
            </>
          )}
        </div>
      </Dashboard>
    </>
  );
};

export default Scorecardv1;
