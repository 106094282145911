import React from 'react'
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend);

const CreditPie = ({ metric, screenWidth }) => {
    const data = {
        labels: metric.tooltip,
        datasets: [
            {
                data: [metric.value, (100 - metric.value)],
                backgroundColor: metric.segmentColors,
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top",
            },
            datalabels: {
                display: false,
            },
        },
    };

    return (
        <div style={{ width: '100%', margin: '0 auto' }}>
            <Pie data={data} options={options} width={screenWidth < 768 ? "auto" : "400px"} height={screenWidth < 768 ? "auto" : "400px"} />
        </div>
    );
}

export default CreditPie