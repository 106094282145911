import React, { useEffect, useState, useContext } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import ProgramDetails from "../../components/ProgramDetails/ProgramDetails";
import Alerts from "../../components/Alerts/Alerts";
import styles from "./LMS.module.scss";
import { useParams } from "react-router-dom";
import LoaderIntroWait from "react-spinners/HashLoader";
import LoaderIntro from "react-spinners/SyncLoader";
import LMSDashboard from "../../components/LMSDashboard/LMSDashboard";
import LMSSurvey from "../../components/LMSSurvey/LMSSurvey";
import { ToastContainer, toast } from "react-toastify";

import { Context as AuthContext } from "../../context/AuthContext";

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { applicRoutes } from "../../routes/sidebarRoutes";

import { FaBookReader, FaRegBell, FaUserShield, FaHome } from "react-icons/fa";

const LMS = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const [currentPath, setCurrentPath] = useState();

  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [traineeId, setTraineeId] = useState();
  const [mentorId, setMentorId] = useState();
  const [mentorName, setMentorName] = useState();

  const [businessId, setBusinessId] = useState("");

  const [doneSurvey, setDoneSurvey] = useState();
  const [doneSurveyData, setDoneSurveyData] = useState([]);
  const [doneSurveyError, setDoneSurveyError] = useState("");
  const [isLoadingDoneSurvey, setIsLoadingDoneSurvey] = useState(true);

  const [lastChapter, setLastChapter] = useState();
  const [lastTopic, setLastTopic] = useState();
  const [lastTopicName, setLastTopicName] = useState("");
  const [cohortId, setCohortId] = useState();
  const [classId, setClassId] = useState();

  const [mentors, setMentors] = useState([]);
  const [mentorsError, setMentorsError] = useState("");
  const [isLoadingMentors, setIsLoadingMentors] = useState();

  const [clickedAssignment, setClickedAssignment] = useState(1);
  const [assignmentIndex, setAssignmentIndex] = useState(1);
  const [fromMenu, setFromMenu] = useState();
  const [assignmentTitle, setAssignmentTitle] = useState("");

  const [currentChapterId, setCurrentChapterId] = useState(null);
  const [upcomingAssignments, setUpcomingAssignments] = useState([]);
  const [insightsError, setInsightsError] = useState("");
  const [loadingInsights, setLoadingInsights] = useState(false);

  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    if (location.host === "localhost:3000") {
      setBaseUrl("http://space.somoafrica-org/lms/api/v1/");
    } else {
      setBaseUrl("https://digikua.co.ke/lms/api/v1/");
    }
  }, []);

  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    if (!user) return;

    setEmail(user.email);
  }, [user]);

  useEffect(() => {
    if (email) {
      const formData = new FormData();

      formData.append("readByEmailOrId", email);

      const requestOptions = {
        method: "POST",
        headers: {},
        body: formData,
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=business&action=readByEmailOrId",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setBusinessId(data.business[0].id);
          setUserId(data.data[0].userId);
          setName(data.data[0].user_name.split(" ")[0]);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("I am not boarding");
    }
  }, [email]);

  useEffect(() => {
    if (!userId) return;

    const getTraineeData = async () => {
      try {
        const res = await fetch(`${baseUrl}trainees?user_id=${userId}`);

        if (!res.ok)
          throw new Error("Something went wrong with fetching trainee");

        const data = await res.json();
        if (data.Response === "False") throw new Error("Trainee not found");
        setTraineeId(data.data[0].id);
        setCohortId(data.data[0].cohortId);
      } catch (error) {
        console.log(Error);
      }
    };

    getTraineeData(userId);
  }, [userId]);

  const getDoneSurvey = async (cohortId, traineeId) => {
    try {
      setDoneSurvey();
      setDoneSurveyData([]);
      setIsLoadingDoneSurvey(true);
      setDoneSurveyError("");

      const url = `${baseUrl}surveys/verify-done/1?cohort_id=${cohortId}&trainee_id=${traineeId}`;
      const res = await fetch(url);

      if (!res.ok) {
        throw new Error("Something went wrong with fetching survey status");
      }

      const data = await res.json();
      if (data.Response === "False") {
        throw new Error("Survey status not found");
      }
      if (data.data) {
        setDoneSurvey(data.completed);
        setDoneSurveyData(data.data);
        setDoneSurveyError("");
        setIsLoadingDoneSurvey(false);
      }
    } catch (error) {
      setDoneSurveyError(error.message);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!cohortId && !traineeId) return;
    getDoneSurvey(cohortId, traineeId);
  }, [traineeId, cohortId]);

  useEffect(() => {
    if (!cohortId) return;

    const getClassData = async () => {
      try {
        const res = await fetch(`${baseUrl}classes?cohort_id=${cohortId}`);

        if (!res.ok)
          throw new Error("Something went wrong with fetching classes");

        const data = await res.json();
        if (data.Response === "False") throw new Error("Classes not found");
        setClassId(data.data[0].id);
      } catch (error) {
        console.log(error);
      }
    };

    getClassData();
  }, [cohortId]);

  useEffect(() => {
    if (cohortId) {
      async function mentorsData() {
        setIsLoadingMentors(true);
        setMentorsError("");
        try {
          const res = await fetch(`${baseUrl}mentors/${cohortId}`);

          if (!res.ok)
            throw new Error("Something went wrong with fetching mentors");

          const data = await res.json();
          if (data.Response === "False") throw new Error("Mentors not found");
          setMentors(data.data);
          setMentorId(data.data[0].id);
          setMentorName(data.data[0].name);
        } catch (error) {
          setMentorsError(error.message);
        } finally {
          setIsLoadingMentors(false);
        }
      }
      mentorsData();
    }
  }, [cohortId]);

  useEffect(() => {
    if (!traineeId || !cohortId || !classId) return;

    const getChapterInsights = async () => {
      try {
        setLoadingInsights(true);
        setInsightsError("");

        const res = await fetch(
          `${baseUrl}insights/trainees/${traineeId}/cohort-info?organize_by=trainee`
        );

        if (!res.ok)
          throw new Error("Something went wrong with fetching chapters");

        const data = await res.json();
        if (data.Response === "False") throw new Error("Chapters not found");

        const chapters = data.data[cohortId][classId].chapters;

        for (const [key, chapter] of Object.entries(chapters)) {
          const assignments = chapter.assignments;
          const totalAssignments = Object.keys(assignments).length;
          let completedAssignments = 0;

          for (const assignment of Object.values(assignments)) {
            if (assignment.submissions > 0) {
              completedAssignments++;
            }
          }

          if (completedAssignments < totalAssignments) {
            setLastChapter(chapter.id);
            setUpcomingAssignments(Object.values(assignments));

            break;
          }
        }

        setLoadingInsights(false);
      } catch (error) {
        setInsightsError(error.message);
        setLoadingInsights(false);
      }
    };
    getChapterInsights();
  }, [traineeId, cohortId, classId]);

  useEffect(() => {
    if (!lastChapter) return;

    const getLastTopic = async () => {
      try {
        setLastTopic();
        setLastTopicName("");
        setLoadingInsights(true);
        setInsightsError("");
        const res = await fetch(`${baseUrl}topics?chapter_id=${lastChapter}`);

        if (!res.ok)
          throw new Error("Something went wrong with fetching topics");

        const data = await res.json();
        if (data.Response === "False") throw new Error("Topics not found");
        setLastTopic(data.data[0].id);
        setLastTopicName(data.data[0].name);
        setInsightsError("");
        setLoadingInsights(false);
      } catch (error) {
        setInsightsError(error.message);
      } finally {
        setLoadingInsights(false);
      }
    };
    getLastTopic();
  }, [lastChapter]);

  useEffect(() => {
    if (!currentPath) return;
    !doneSurvey && currentPath !== "home"
      ? history.push(`${path}/home`)
      : history.push(`${path}/${currentPath}`);
  }, [currentPath, doneSurvey]);
  return (
    <>
      <Dashboard sidebarRoutes={applicRoutes} location={location}>
        <div
          className={styles.heading}
          style={{ display: !doneSurvey && "none" }}
        >
          <ToastContainer position="top-center" />
          <nav>
            <Button
              bgColor={
                window.location.href.split("/").pop() === "home"
                  ? "#00a5a2"
                  : "#cc6328"
              }
              size="sm"
              color="#fff"
              clicked={() => setCurrentPath("home")}
            >
              <FaHome /> <span className={styles.btnTxt}>Home</span>
            </Button>
            <Button
              bgColor={
                window.location.href.split("/").pop() === "mentors"
                  ? "#00a5a2"
                  : "#cc6328"
              }
              size="sm"
              color="#fff"
              clicked={() => setCurrentPath("mentors")}
            >
              <FaUserShield /> <span className={styles.btnTxt}>Mentors</span>
            </Button>
            <Button
              bgColor={
                window.location.href.split("/").pop() === "learning" ||
                  window.location.href.split("/")[
                  window.location.href.split("/").length - 3
                  ] === "learning" ||
                  window.location.href.split("/")[
                  window.location.href.split("/").length - 2
                  ] === "learning" ||
                  window.location.href.split("/")[
                  window.location.href.split("/").length - 4
                  ] === "learning"
                  ? "#00a5a2"
                  : "#cc6328"
              }
              size="sm"
              color="#fff"
              clicked={() => setCurrentPath(`learning`)}
            >
              <FaBookReader /> <span className={styles.btnTxt}>Learning</span>
            </Button>
            <Button
              bgColor={
                window.location.href.split("/").pop() === "alerts"
                  ? "#00a5a2"
                  : "#cc6328"
              }
              size="sm"
              color="#fff"
              clicked={() => setCurrentPath("alerts")}
            >
              <FaRegBell /> <span className={styles.btnTxt}>Alerts</span>
            </Button>
          </nav>
        </div>

        {/* Display loading indicator if still loading */}
        {isLoadingDoneSurvey ? (
          <div className={styles.scoresLoading}>
            <h2>
              <FaBookReader /> Somo LMS
            </h2>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100px",
              }}
            >
              Loading&nbsp;
              <LoaderIntro color="#00a5a2" />
            </span>
            <p style={{ fontStyle: "italic" }}>
              Empowering you, to change the world
            </p>
          </div>
        ) : (
          // Render the Switch and Routes only when loading is complete
          <Switch>
            <Route path={`${path}/survey`}>
              <h2>Survey</h2>
            </Route>
            <Route path={`${path}/home`}>
              {doneSurvey ? (
                <LMSDashboard
                  traineeId={traineeId}
                  cohortId={cohortId}
                  classId={classId}
                  lastChapter={lastChapter}
                  lastTopic={lastTopic}
                  lastTopicName={lastTopicName}
                  loadingInsights={loadingInsights}
                  insightsError={insightsError}
                  upcomingAssignments={upcomingAssignments}
                  baseUrl={baseUrl}
                  setCurrentPath={setCurrentPath}
                  clickedAssignment={clickedAssignment}
                  setClickedAssignment={setClickedAssignment}
                  assignmentIndex={assignmentIndex}
                  setAssignmentIndex={setAssignmentIndex}
                  fromMenu={fromMenu}
                  setFromMenu={setFromMenu}
                  assignmentTitle={assignmentTitle}
                  setAssignmentTitle={setAssignmentTitle}
                />
              ) : (
                <LMSSurvey
                  traineeId={traineeId}
                  cohortId={cohortId}
                  baseUrl={baseUrl}
                  name={name}
                  doneSurvey={doneSurvey}
                  setDoneSurvey={setDoneSurvey}
                  doneSurveyData={doneSurveyData}
                />
              )}
              {doneSurveyError && <p>Error</p>}
            </Route>
            <Route path={`${path}/mentors`}>
              <div className={styles.applicationComplete}>
                <h2>
                  <FaUserShield size="1.2em" color="#cc6328" /> My Mentors
                </h2>
                {!isLoadingMentors && mentorsError && <p>{mentorsError}</p>}
                {isLoadingMentors ? (
                  <div>
                    <p style={{ textAlign: "left" }}>
                      Loading&nbsp;
                      <LoaderIntro color="#00a5a2" />
                    </p>
                  </div>
                ) : (
                  !mentorsError &&
                  mentors.length >= 0 &&
                  mentors.map((mentor, index) => (
                    <div
                      className={styles.advisorsListHold}
                      style={{ textAlign: "left" }}
                      id="advisorsList"
                      key={index}
                      title="Message mentor"
                    >
                      <div
                        className={styles.advisorList}
                        onClick={() => {
                          setCurrentPath("learning/messages");
                        }}
                      >
                        <FaUserShield
                          size="1.2em"
                          color="#cc6328"
                          className={styles.icon}
                        />
                        <div className={styles.advisorListInfo}>
                          <span>{mentor.name}</span>
                          <span>{mentor.email}</span>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                <p style={{ textAlign: "left", fontStyle: "italic" }}>
                  Empowering you, to change the world{" "}
                </p>
              </div>
            </Route>
            <Route path={`${path}/learning`}>
              <ProgramDetails
                traineeId={traineeId}
                userId={userId}
                mentorId={mentorId}
                mentorName={mentorName}
                cohortId={cohortId}
                classId={classId}
                lastChapter={lastChapter}
                lastTopic={lastTopic}
                baseUrl={baseUrl}
                currentPath={currentPath}
                setCurrentPath={setCurrentPath}
                clickedAssignment={clickedAssignment}
                setClickedAssignment={setClickedAssignment}
                assignmentIndex={assignmentIndex}
                setAssignmentIndex={setAssignmentIndex}
                fromMenu={fromMenu}
                setFromMenu={setFromMenu}
                assignmentTitle={assignmentTitle}
                setAssignmentTitle={setAssignmentTitle}
              />
            </Route>
            <Route path={`${path}/alerts`}>
              <Alerts />
            </Route>
          </Switch>
        )}
      </Dashboard>
    </>
  );
};

export default LMS;
