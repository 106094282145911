import React from 'react';
import styles from './BusinessInfoCard.module.scss';

const BusinessInfoCard = ({ businessName, status, year, quarter, dateScored }) => {


    return (
        <div className={`${styles.orgInfo}`}>
            <div className={`${styles.somoInfo} ${styles.noPrint}`}>
                <h1>SOMO AFRICA</h1>
                <p>P.O BOX 76403-00508, Olympic Stage Kibera, Nairobi Kenya</p>
                <p>Phone: +254 768 825 370</p>
            </div>
            <div className={styles.headingBox}>
                <h4>Entrepreneur Information</h4>
            </div>

            <div className={styles.entrepreneurInfo}>
                <div>
                    <div className={styles.info}>
                        <div className={styles.infoLabel}>Business Name:</div>
                        <div className={styles.infoData}>{businessName}</div>
                    </div>

                    <div className={styles.info}>
                        <div className={styles.infoLabel}>Business Status:</div>
                        <div className={styles.infoData}>{status}</div>
                    </div>

                    <div className={styles.info}>
                        <div className={styles.infoLabel}>Year:</div>
                        <div className={styles.infoData}>{year}</div>
                    </div>

                    <div className={styles.info}>
                        <div className={styles.infoLabel}>Quarter:</div>
                        <div className={styles.infoData}>Q{quarter}</div>
                    </div>

                    <div className={styles.info}>
                        <div className={styles.infoLabel}>Date:</div>
                        <div className={styles.infoData}>{dateScored}</div>
                    </div>
                </div>
                {/* <table className={`${styles.infoTable} "table table-borderless"`}>
                    <tbody>
                        <tr>
                            <td>BUSINESS NAME:</td>
                            <td>{businessName}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS STATUS:</td>
                            <td>{status}</td>
                        </tr>
                        <tr>
                            <td>YEAR:</td>
                            <td>{year}</td>
                        </tr>
                        <tr>
                            <td>QUARTER:</td>
                            <td>Q{quarter}</td>
                        </tr>
                        <tr>
                            <td>DATE:</td>
                            <td>{dateScored}</td>
                        </tr>
                    </tbody>
                </table> */}
            </div>
        </div>
    )
}

export default BusinessInfoCard;