import React from "react";

import styles from "./SummaryCards.module.scss";

const SummaryCards = ({ icon, title, value, color, error, loading }) => {
  return (
    <>
      <div className={styles.card}>
        <div className={styles.circle} style={{ backgroundColor: color }}>
          <div className={styles.icon}>{icon}</div>
        </div>
        <p className={styles.title}>{title}</p>
        <p className={styles.value}>{value}</p>
      </div>
    </>
  );
};

export default SummaryCards;
