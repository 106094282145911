import React, { useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import styles from "./HomePage.module.scss";
import { Row, Col } from "react-bootstrap";
import ExcitedCustomer from "../../assets/excitement.jpeg";
import ExcitedCustomerMinor from "../../assets/excitement-2.jpg";
import Clients from "../../assets/clients.jpg";
import Circle from "../../assets/circle.svg";
import Pattern from "../../assets/pattern.svg";
import AboutImg from "../../assets/about.png";
import { FaMoneyBillWaveAlt, FaStopwatch, FaFileAlt } from "react-icons/fa";
import { HiLocationMarker, HiMail, HiPhone } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
import Credit from "../../assets/icons/credit.svg";
import Notes from "../../assets/icons/gypsyNotes.svg";
import Advisory from "../../assets/icons/advisory.svg";
import Alternative from "../../assets/icons/alternative.svg";
import CirclePattern from "../../assets/patternCircle.png";
import InputField from "../../components/InputField/InputField";
// import FaqSection from "../../components/FaqSection/FaqSection";
import Footer from "../../components/Footer/Footer";
import { useRouteMatch, useHistory } from "react-router-dom";
import { convertInput } from "../../utils/convertInputType";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import LazyLoad from "react-lazyload";

const HomePage = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [loanAmt, setLoanAmt] = useState("");
  const [loanRequest, setLoanRequest] = useState("");

  const [contactInfo, setContactInfo] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  return (
    <>
      <ScrollToTopOnMount />
      <NavBar history={history} location={url} />
      <div className={styles.heroSection}>
        <Row className={styles.container}>
          <Col md={6} sm={12} className={styles.mainCopy}>
            <div className={styles.copyGroup}>
              <h1>Empowering you, to change the world</h1>
              <h3>Welcome to your entrepreneur profile</h3>
              <input
                type="text"
                name="loanAmt"
                value={loanAmt}
                placeholder="How much do you need?"
                onChange={(e) => {
                  const { includesAlphabet, convertedToNumber } = convertInput(
                    e.currentTarget.value
                  );
                  if (!includesAlphabet) {
                    setLoanAmt(convertedToNumber.toLocaleString());
                  }
                }}
                style={{ "display": "none" }}
              />
              <button
                onClick={() =>
                  history.push({
                    pathname: "/signin",
                    state: { loanAmount: loanAmt },
                  })
                }
              >
                Login
              </button>
              <img src={Pattern} className={styles.pattern} alt="pattern" />
            </div>
          </Col>
          <Col md={6} sm={12} className={styles.imageGroup}>
            <div className={styles.imgContainer}>
              <LazyLoad height={200} once>
                <img
                  className={styles.mainImg}
                  src={ExcitedCustomer}
                  alt="An excited customer"
                />
              </LazyLoad>
              <LazyLoad height={200} once>
                <img
                  className={styles.minorImg}
                  src={ExcitedCustomerMinor}
                  alt="An excited customer"
                />
              </LazyLoad>
              <LazyLoad height={200} once>
                <img
                  className={styles.minorImg2}
                  src={Clients}
                  alt="Excited customers"
                />
              </LazyLoad>
              <LazyLoad once>
                <img
                  src={Circle}
                  className={styles.firstCircle}
                  alt="presentation"
                />
              </LazyLoad>
              <LazyLoad once>
                <img
                  src={Circle}
                  className={styles.secondCircle}
                  alt="presentation"
                />
              </LazyLoad>
            </div>
          </Col>
        </Row>
      </div>
      {/* <FaqSection returnNumber={4} /> */}
      <div className={styles.contactSection}>
        <div className={styles.container}>
          {/* <h2>Contact</h2> */}
          <Row className={styles.contactContainer}>
            <Col className={styles.addressCol}>
              <div className={styles.addressDetails}>
                <h3>Contact Us</h3>
                <div className={styles.contactGroup}>
                  <HiLocationMarker className={styles.icon} size="4em" />
                  <p>
                    P.O BOX 76403-00508, Olympic Stage Kibera, Nairobi Kenya
                  </p>
                </div>
                <div className={styles.contactGroup}>
                  <HiMail className={styles.icon} size="1.8em" />
                  <p>{process.env.REACT_APP_SYSTEM_SUPPORT_EMAIL}</p>
                </div>
                <div className={styles.contactGroup}>
                  <HiMail className={styles.icon} size="1.8em" />
                  <p>{process.env.REACT_APP_SYSTEM_SUPPORT_EMAIL_B}</p>
                </div>
                <div className={styles.contactGroup}>
                  <HiMail className={styles.icon} size="1.8em" />
                  <p>{process.env.REACT_APP_SYSTEM_SUPPORT_EMAIL_C}</p>
                </div>
                <div className={styles.contactGroup}>
                  <HiPhone className={styles.icon} size="1.8em" /> or
                  <IoLogoWhatsapp className={styles.icon} style={{ marginLeft: "15px" }} size="1.8em" />
                  <p>{process.env.REACT_APP_SYSTEM_SUPPORT_PHONE}</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className={styles.contactForm}>
                <h3 style={{ display: "none" }}>Send Us An Email</h3>
                <div className={styles.inputWrapper} style={{ display: "none" }}>
                  <InputField
                    placeholder="Name"
                    name="name"
                    type="text"
                    value={contactInfo.name}
                    changed={(val) =>
                      setContactInfo({ ...contactInfo, name: val })
                    }
                  />
                </div>
                <div className={styles.inputWrapper} style={{ display: "none" }}>
                  <InputField
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={contactInfo.email}
                    changed={(val) =>
                      setContactInfo({ ...contactInfo, email: val })
                    }
                  />
                </div>
                <div className={styles.inputWrapper} style={{ display: "none" }}>
                  <InputField
                    placeholder="Subject"
                    name="subject"
                    type="text"
                    value={contactInfo.subject}
                    changed={(val) =>
                      setContactInfo({ ...contactInfo, subject: val })
                    }
                  />
                </div>
                <div className={styles.inputWrapper} style={{ display: "none" }}>
                  <InputField
                    type="textarea"
                    nameAttr="message"
                    placeholder="Message"
                    value={contactInfo.message}
                    changed={(val) =>
                      setContactInfo({ ...contactInfo, message: val })
                    }
                  />
                </div>
                <button style={{ display: "none" }}>Submit</button>
                <a href="https://www.somoafrica.org/contacts" style={{ textDecoration: "none", margin: "98px auto 21px !important" }}><button>Send Us A Message</button></a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.subFooter}></div>
      <Footer />
    </>
  );
};

export default HomePage;
