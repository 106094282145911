import React, { useState, useEffect } from "react";
import styles from "./ContentQA.module.scss";
import { FaSearch, FaAngleDown, FaAngleUp } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { TiCancelOutline } from "react-icons/ti";
import noLoan from "../../assets/no-loan.png";

const ContentQA = ({ baseUrl }) => {
  const [questions, setQuestions] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [questionError, setQuestionError] = useState("");
  const [isLoadingQuestions, setIsLoadingQuestions] = useState();

  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setIsLoadingQuestions(true);
        setQuestionError("");

        const res = await fetch(`${baseUrl}faqs`);

        if (!res.ok)
          throw new Error("Something went wrong with fetching questions");

        const data = await res.json();
        if (data.Response === "False") throw new Error("Questions not found");

        setQuestions(data.data);
        setQuiz(data.data);
        setQuestionError("");
      } catch (err) {
        setQuestionError(err.message);
      } finally {
        setIsLoadingQuestions(false);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    setQuiz(
      questions.filter(
        (question) =>
          question.question
            .toLowerCase()
            .includes(filterValue.toLocaleLowerCase()) ||
          question.solution.toLowerCase().includes(filterValue.toLowerCase())
      )
    );
  }, [filterValue]);

  const [questionId, setQuestionId] = useState();
  return (
    <div className={styles.ContentQA}>
      <div className={styles.title}>
        {!isLoadingQuestions && !questionError && questions.length >= 1 && (
          <h3>Featured Questions</h3>
        )}
      </div>
      {!isLoadingQuestions && !questionError && questions.length >= 1 && (
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search all questions"
            className={styles.filter}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          />
          <FaSearch color="#878787" />
        </div>
      )}

      {isLoadingQuestions && (
        <div>
          <p>
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#00A5A2"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </p>
        </div>
      )}
      {!isLoadingQuestions && questionError && (
        <div className={styles.errorQuestions}>
          <TiCancelOutline
            className={styles.mobileNoQuestions}
            size="5em"
            color="rgba(116, 23, 99, 0.6)"
          />
          <p>An unknown error occurred. Please try again later.</p>
        </div>
      )}
      {!isLoadingQuestions && !questionError && quiz.length < 1 && (
        <>
          <div className={styles.noQuestions}>
            <p style={{ color: "#C4233C" }}>No questions found</p>
            <img src={noLoan} alt="No questions" height="250" />
            <TiCancelOutline
              className={styles.mobileNoQuestions}
              size="6em"
              color="rgba(116, 23, 99, 0.6)"
            />
          </div>
        </>
      )}
      {!isLoadingQuestions && !questionError && quiz.length >= 1 && (
        <div className={styles.questions}>
          {quiz.map((question) => (
            <div className={styles.questionBox}>
              <div
                key={question.id}
                className={styles.question}
                onClick={() => {
                  questionId === question.id
                    ? setQuestionId("")
                    : setQuestionId(question.id);
                }}
              >
                <p
                  style={{
                    fontWeight: questionId === question.id ? "bold" : "normal",
                    color: questionId === question.id ? "#00a5a2" : "",
                  }}
                >
                  {question.question}
                </p>
                {questionId === question.id ? (
                  <FaAngleUp color="#00A5A2" style={{ marginTop: "3px" }} />
                ) : (
                  <FaAngleDown color="#00A5A2" style={{ marginTop: "3px" }} />
                )}
              </div>
              <div
                className={
                  questionId === question.id ? styles.visible : styles.hidden
                }
              >
                <p className={styles.answer}>{question.solution}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContentQA;
