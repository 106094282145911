import { getDefaultNormalizer } from "@testing-library/react";
import React from "react";
import styles from "./ScoreCardLegend.module.scss";

const Legend = ({ bgColor, color, name }) => {
    const legendStyle = {
        backgroundColor: bgColor,
    };

    return (
        <div className={styles.legend} >
            <div className={styles.rectangle} style={legendStyle}></div>
            <div style={{ color: "#212121", fontSize: "0.9rem" }}>{name}</div>
        </div>
    );
};

export default Legend;
