import React from "react";
import styles from "./LMSResources.module.scss";
import { ReactComponent as Icon } from "../../assets/icons/resources.svg";
import { FaChevronRight } from "react-icons/fa";

const LMSResources = ({ closePanel, setClosePanel, screenWidth }) => {
  const getOccurrence = (closePanel, screenWidth) => {
    if (closePanel && screenWidth < 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth < 1200) {
      return ["0%", "none"];
    } else if (closePanel && screenWidth > 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth > 1200) {
      return ["70%", ""];
    }
  };

  return (
    <div
      className={styles.resourcesCont}
      style={{
        width: getOccurrence(closePanel, screenWidth)[0],
        display: getOccurrence(closePanel, screenWidth)[1],
      }}
    >
      <div className={styles.topBar}>
        <div className={styles.header}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {closePanel ? (
              <div
                className={styles.openMenu}
                onClick={() => setClosePanel(false)}
              >
                <FaChevronRight size="1rem" color="#cc6328" />
              </div>
            ) : (
              ""
            )}
          </div>
          <Icon
            className={styles.icon}
            style={{ marginLeft: closePanel ? "" : "20px" }}
          />
          <h2>Resources</h2>
        </div>
      </div>
      <div className={styles.contentBar}>
        <div className={styles.reading}>
          <h2>Market Research</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere
            varius diam in congue. Proin mollis volutpat tortor at tincidunt.
            Suspendisse vestibulum sed leo vel laoreet. Pellentesque habitant
            morbi tristique senectus et netus et malesuada fames ac turpis
            egestas. Ut lectus odio, maximus eget cursus vitae, porta ut nunc. 
          </p>
          <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Lorem ipsum dolor sit amet</li>
          </ul>
          <h3>Sub heading</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere
            varius diam in congue. Proin mollis volutpat tortor at tincidunt.
            Suspendisse.
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere
            varius diam in congue. Proin mollis volutpat tortor at tincidunt.
            Suspendisse vestibulum sed leo vel laoreet. Pellentesque habitant
            morbi tristique senectus et netus et malesuada fames ac turpis
            egestas. Ut lectus odio, maximus eget cursus vitae, porta ut nunc. 
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere
            varius diam in congue. Proin mollis volutpat tortor at tincidunt.
            Suspendisse vestibulum sed leo vel laoreet. Pellentesque habitant
            morbi tristique senectus et netus et malesuada fames ac turpis
            egestas. Ut lectus odio, maximus eget cursus vitae, porta ut nunc. 
          </p>
          <ul>
            <li>
              <strong>Lorem ipsum</strong> - Lorem ipsum dolor sit amet.
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas. Ut lectus odio, maximus eget
              cursus vitae, porta ut nunc.  Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </li>
            <li>
              <strong>Lorem ipsum</strong> - Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
              Ut lectus odio, maximus eget cursus vitae, porta ut nunc.  Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </li>
            <li>
              <strong>Lorem ipsum</strong> - Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
              Ut lectus odio, maximus eget cursus vitae, porta ut nunc.  Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </li>
            <li>
              <strong>Lorem ipsum</strong> - Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
              Ut lectus odio, maximus eget cursus vitae, porta ut nunc.  Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </li>
            <li>
              <strong>Lorem ipsum</strong> - Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
              Ut lectus odio, maximus eget cursus vitae, porta ut nunc.  Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </li>
            <li>
              <strong>Lorem ipsum</strong> - Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
              Ut lectus odio, maximus eget cursus vitae, porta ut nunc.  Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LMSResources;
