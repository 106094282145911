import axios from "axios";

const instance = axios.create({
  // baseURL: `https://www.gypsy.developer.bdigismat.com`,
  // baseURL: `https://api.gypsycapital.com`
  // baseURL: `https://api.loan.somoafrica.com`
  // baseURL: `http://d.somo.loan.api`
  baseURL: (window.location.hostname=='d.somo.loan.pre' ? `http://d.somo.loan.api` : `https://api.loan.somoafrica.com`)
  // baseURL: `https://api.loan.somoafrica.com`
  // timeout: 2
});

export default instance;
