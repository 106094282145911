import React, { useContext, useEffect } from "react";
import styles from "./ConsumerCredit.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import { Table } from "react-bootstrap";
import noLoan from "../../assets/no-loan.png";
import { Row, Col, Modal } from "react-bootstrap";
import { FaCheckCircle, FaMobile, FaMoneyBill, FaMoneyBillAlt, FaMoneyBillWave, FaMoneyBillWaveAlt, FaMoneyCheck, FaMoneyCheckAlt, FaRegMoneyBillAlt } from "react-icons/fa";
import { TiCancelOutline } from "react-icons/ti";
import LoanCalculatorForm from "../../components/LoanCalculatorForm/LoanCalculatorForm";
import LoanContactForm from "../../components/LoanContactForm/LoanContactForm";
import EmployerInfoForm from "../../components/EmployerInfoForm/EmployerInfoForm";
import BankInfoForm from "../../components/BankInfoForm/BankInfoForm";
import LoanFundForm from "../../components/LoanFundForm/LoanFundForm";
import LoanProposalForm from "../../components/LoanProposalForm/LoanProposalForm";
import LoanAmountForm from "../../components/LoanAmountForm/LoanAmountForm";
import LoanPayForm from "../../components/LoanPayForm/LoanPayForm";

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { clientRoutes } from "../../routes/sidebarRoutes";
import _ from "lodash";

const ConsumerCredit = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const applyStageArray = {
    "/dashboard/consumer-credit/apply/calculator": 0,
    "/dashboard/consumer-credit/apply/fund": 1,
    "/dashboard/consumer-credit/apply/amount": 2,
    // "/dashboard/consumer-credit/apply/bank-info": 3,
  };

  const {
    state: { loans, error, currentLoanId, incomplete, loanApplicationStage },
    retrieveClientLoans,
    clearError,
    loanApply,
    addAddressForLoan,
    addWorkInfoForLoan,
    addBankInfoForLoan,
    clearCompleteState,
  } = useContext(LoanContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    retrieveClientLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      clearError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  function waitAndlisten(th) {
    console.log('th->', th);
    var btn = document.getElementById('click_me');
    if (!btn) return 0;
    btn.addEventListener('click', (e) => {
      console.log('---e-->', e);
      alert('I love shortcuts');
    })
  }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculateLoan = (data) => {
    loanApply(data, user.user_id);
  };

  const updateAddress = (data) => {
    addAddressForLoan(data, currentLoanId);
  };

  const updateEmployerInfo = (data) => {
    addWorkInfoForLoan(data, currentLoanId);
  };

  const updateBankInfo = (data) => {
    addBankInfoForLoan(data, currentLoanId);
  };

  const resumeApplication = () => {
    clearCompleteState();
    if (loanApplicationStage === "calculated") {
      history.push("/dashboard/consumer-credit/apply/contact-info");
    }
    if (loanApplicationStage === "address_added") {
      history.push("/dashboard/consumer-credit/apply/employer-info");
    }
    if (loanApplicationStage === "employer_added") {
      history.push("/dashboard/consumer-credit/apply/bank-info");
    }
  };

  // console.log(error);

  return (
    <>
      <Dashboard sidebarRoutes={clientRoutes} location={location}>
        <ToastContainer position="top-center" />
        <div className={styles.heading}>
          <div>
            <h2>My Loans</h2>
            <p>Get a loan with ease.</p>
          </div>
          {(location.pathname === "/dashboard/consumer-credit/history" || location.pathname === "/dashboard/consumer-credit/pay") && (
            <div>
              <Button
                bgColor="#cc6328"
                size="sm"
                color="#fff"
                className="mt-4"
                clicked={() => history.push("/dashboard/consumer-credit/pay")}
              >
                Pay Loan
              </Button>
              <b>&nbsp;&nbsp;&nbsp;</b>
              <Button
                bgColor="#cc6328"
                size="sm"
                color="#fff"
                className="mt-4"
                clicked={() => history.push("/dashboard/consumer-credit/apply/calculator")}
              >
                New Loan
              </Button>
            </div>
          )}
        </div>
        <Switch>
          <Route path={`${path}/history`}>
            <div className={styles.creditTable}>
              <Table striped hover className={styles.tableStyles}>
                <thead>
                  <tr>
                    <th>Loan ID</th>
                    <th>Monthly Repayment</th>
                    <th>Loan Fund</th>
                    <th>Status</th>
                    <th>Requested Amount</th>
                    <th>Balance</th>
                    <th>Approved Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.map((loanInstance, idx) => {
                    let loanBalance = loanInstance?.repayment
                      .filter((repaid) => {
                        return repaid.status === true;
                      })
                      .reduce((acc, curr) => {
                        return curr.scheduledAmount + acc;
                      }, 0);

                    loanBalance = loanInstance?.approvedAmount - loanBalance;

                    let overpayment = loanInstance?.repayment
                      .map((repayment) => repayment.penaltyFee)
                      .reduce((acc, curr) => {
                        return acc + curr;
                      }, 0);

                    return (
                      <tr key={idx}>
                        <td>{loanInstance._id.substring(0, 5)}</td>
                        <td>{`Ksh ${numberWithCommas(
                          (loanInstance.monthlyRepayment ? loanInstance.monthlyRepayment : '--')
                        )}`}</td>
                        <td>
                          {loanInstance.loanFundName}
                        </td>
                        <td>{_.startCase(loanInstance.status)}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.amount)}`}</td>
                        {/* <td>{`Ksh ${numberWithCommas(loanBalance)}`}</td> */}
                        <td>{`Ksh ${numberWithCommas(loanInstance.balance ? loanInstance.balance : 0)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.approvedAmount)}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {(!loans || loans.length === 0) && (
                <div className={styles.noLoanMessage}>
                  <p>Sorry you currently have no loan</p>
                  <img src={noLoan} alt="No loan history" height="250" />
                  <TiCancelOutline
                    className={styles.mobileNoLoan}
                    size="6em"
                    color="rgba(116, 23, 99, 0.6)"
                  />
                </div>
              )}
            </div>
          </Route>
          <Route path={`${path}/apply`}>
            <div className={styles.applyContainer}>
              <Row>
                <Col md={4}>
                  <ul className={styles.joinedBullets}>
                    <li className={styles.active}>Proposal</li>
                    <li className={applyStageArray[location.pathname] > 0 && styles.active}>Fund</li>
                    <li
                      className={
                        applyStageArray[location.pathname] > 1 && styles.active
                      }
                    >
                      Amount
                    </li>
                    {/* <li
                      className={
                        applyStageArray[location.pathname] > 2 && styles.active
                      }
                    >
                      Business Information
                    </li> */}
                    <li
                      className={
                        applyStageArray[location.pathname] > 2 && styles.active
                      }
                    >
                      Complete
                    </li>
                  </ul>
                </Col>
                <Col md={8}>
                  <div className={styles.applyForm}>
                    <Switch>
                      <Route path={`${path}/apply/calculator`}>
                        <LoanProposalForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/fund`}>
                        <LoanFundForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/amount`}>
                        <LoanAmountForm delegateApply={calculateLoan} />
                      </Route>
                      {/* <Route path={`${path}/apply/contact-info`}>
                        <LoanContactForm submitContact={updateAddress} />
                      </Route>
                      <Route path={`${path}/apply/employer-info`}>
                        <EmployerInfoForm
                          submitEmployerInfo={updateEmployerInfo}
                        />
                      </Route>
                      <Route path={`${path}/apply/bank-info`}>
                        <BankInfoForm submitBankInfo={updateBankInfo} />
                      </Route> */}
                    </Switch>
                  </div>
                </Col>
              </Row>
            </div>
          </Route>
          <Route path={`${path}/success`}>
            <div className={styles.applicationComplete}>
              <FaCheckCircle size="4em" color="#cc6328" />
              <h2>Application Received!</h2>
              <p>
                Your loan application has been submitted and is being processed.
              </p>
              <p>Look out for an email from us within a week.</p>
              <Button
                bgColor="#cc6328"
                size="sm"
                color="#fff"
                className="mt-4"
                clicked={() => history.push("/dashboard/overview")}
              >
                Dashboard
              </Button>
              {/* <a href="/dashboard/overview">Dashboard</a> */}
            </div>
          </Route>
          <Route path={`${path}/pay`}>
            <div className={styles.applicationComplete}>
              <LoanPayForm delegateApply={calculateLoan} />
              {/* <LoanProposalForm delegateApply={calculateLoan} /> */}
            </div>
          </Route>
        </Switch>
      </Dashboard>
      <Modal
        className={styles.continueModal}
        size="sm"
        show={incomplete}
        onHide={() => {
          clearCompleteState();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Continue Application</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have an incomplete loan request, please continue where you left
            off
          </p>
          <Button
            clicked={resumeApplication}
            fullWidth
            className="mt-4"
            bgColor="#cc6328"
            size="sm"
            color="#EBEBEB"
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConsumerCredit;
